import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../../../includes/spinner/Spinner";
import { useTranslation } from "react-i18next"
import { SITE_ID_LIST } from '../../../utils/constant';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  maxWidth: 438,
  minWidth: 300,
  p: 0,
  bgcolor: "#fff",
  textAlign: "left"
};

const EnquireModal = ({
  openEnqiry,
  setOpenEnquiry,
  hotelData,
  roomDetails,
  siteConfig
}) => {
  const { t } = useTranslation()
  const handleCloseEnquiry = () => {
    setOpenEnquiry(false);
  };

  useEffect(() => {
    if (openEnqiry) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [openEnqiry])

  const siteURLMAP = {
    [SITE_ID_LIST[0]]: 'https://www.claridges.co.uk/',
    [SITE_ID_LIST[1]]: 'https://www.the-berkeley.co.uk/',
    [SITE_ID_LIST[2]]: 'https://www.the-connaught.co.uk/',
    [SITE_ID_LIST[3]]: 'https://www.maybournebeverlyhills.com/',
    [SITE_ID_LIST[4]]: 'https://www.maybourneriviera.com/',
    [SITE_ID_LIST[5]]: 'https://www.the-emory.co.uk/',
    [SITE_ID_LIST[6]]: 'https://www.maybourneriviera.com/',
    [SITE_ID_LIST[7]]: 'https://www.maybournebeverlyhills.com/',
    [SITE_ID_LIST[8]]: 'https://www.the-emory.co.uk/',
  };

  let externalURL = (roomDetails && roomDetails.room_enq_link) ? roomDetails.room_enq_link : siteURLMAP[siteConfig.site_id];

  let roomPhone = (roomDetails && roomDetails.room_enq_contact) ? roomDetails.room_enq_contact : hotelData?.phone;
  let roomEmail = (roomDetails && roomDetails.room_enq_mail) ? roomDetails.room_enq_mail : hotelData?.email;

  return (
    <Modal
      open={openEnqiry}
      onClose={handleCloseEnquiry}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="enquireModalPopup"
      role="dialog"
			aria-modal="true"
    >
      <Box sx={style}>
        <div id="enquireModal" className="white-popup-block textCenter">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('enquire')}
            <button aria-label='Close Button' type="button" className="mfp-close" onClick={() => handleCloseEnquiry()}>Close</button>
          </Typography>
          <p>{roomDetails.sale_type === "0" ? t("check_availability_text_with_suite") : t("check_availability_text")}:</p>
          <p className="enquiryPopupContact">
            <span className="dBlock">T. <a href={`tel: ${roomPhone}`}>{roomPhone}</a></span>
            <span className="dBlock">E. <a href={`mailto: ${roomEmail}`}>{roomEmail}</a></span>
          </p>
          <div className="modalBottomBtn paddingT40">
            <a className="customBtn customBtnTransparent w100"
              href={externalURL}
              target="_blank"
            >{t("discover_suites")}</a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EnquireModal;
