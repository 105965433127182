const LocalStorage = {
  get: (key) => {
    if (typeof localStorage !== "undefined" && localStorage.getItem(key)) {
      return localStorage.getItem(key)
    }
    return false
  },

  getJSON: (key) => {
    if (typeof localStorage !== "undefined") {
      const data = LocalStorage.get(key)
      return data && data !== "undefined" ? JSON.parse(data) : ""
    }
    return false
  },
  set: (...rest) => {
    if (typeof localStorage !== "undefined") {
      return localStorage.setItem(...rest)
    }
    return false
  },
  setJSON: (key, value) => {
    if (typeof localStorage !== "undefined") {
      const data = JSON.stringify(value)
      return LocalStorage.set(key, data)
    }
    return false
  },
  remove: (key) => {
    if (typeof localStorage !== "undefined") {
      return localStorage.removeItem(key)
    }
    return false
  },
  clean: (key) => {
    if (typeof localStorage !== "undefined") {
      return localStorage.clear(key)
    }
    return false
  },
}

const SessionStorage = {
    get: (key) => {
      if (typeof sessionStorage !== "undefined" && sessionStorage.getItem(key)) {
        return sessionStorage.getItem(key)
      }
      return false
    },
  
    getJSON: (key) => {
      if (typeof sessionStorage !== "undefined") {
        const data = SessionStorage.get(key)
        return data && data !== "undefined" ? JSON.parse(data) : ""
      }
      return false
    },
    set: (...rest) => {
      if (typeof sessionStorage !== "undefined") {
        return sessionStorage.setItem(...rest)
      }
      return false
    },
    setJSON: (key, value) => {
      if (typeof sessionStorage !== "undefined") {
        const data = JSON.stringify(value)
        return sessionStorage.setItem(key, data)
      }
      return false
    },
    remove: (key) => {
      if (typeof sessionStorage !== "undefined") {
        return sessionStorage.removeItem(key)
      }
      return false
    },
    clean: (key) => {
      if (typeof sessionStorage !== "undefined") {
        return sessionStorage.clear(key)
      }
      return false
    },
}

export { LocalStorage, SessionStorage }
