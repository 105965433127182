import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchCheckout = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.checkout.method, apiList.checkout.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}

export const fetchAddon = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.advanceAddon.method, apiList.advanceAddon.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}
