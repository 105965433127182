import React from 'react'
import RoomListComponent from './../components/room-list'

const RoomListIndex = ({
    hotelList,
    currencyList,
    authToken,
    searchPanelData,
    siteConfig,
    ip
}) => {
    return (
        <>
     <RoomListComponent
        hotelList={hotelList}
        currencyList={currencyList}
        authToken={authToken}
        searchPanelData={searchPanelData}
        siteConfig={siteConfig}
        ip={ip}
     />
     </>
    )
}

export default React.memo(RoomListIndex)