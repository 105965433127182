import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import AppContext from '../../../utils/appContext';
import { LocalStorage } from '../../../utils/storage';
import { KEYS } from '../../../utils/constant';
import { getMaxCount, getSiteContactDetails } from '../../../utils/utils';

const MaxOccupancyError = ({
    diplayMaxOccError
}) => {
  const { t } = useTranslation();
  const context = useContext(AppContext)

  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  const site_id = configData.site_id ? configData.site_id : false;

  const hotelEmail = LocalStorage.get(KEYS.HOTEL_EMAIL)
      ? LocalStorage.get(KEYS.HOTEL_EMAIL)
      : getSiteContactDetails(site_id).email;
  const hotelPhone = LocalStorage.get(KEYS.HOTEL_PHONE)
      ? LocalStorage.get(KEYS.HOTEL_PHONE)
      : getSiteContactDetails(site_id).contactNo;

      const sanitizeText = (text) => {
        return text.replace(/\u2002/g, ' ');
      };

  return (
    <>
    {diplayMaxOccError ? 
      <div className="maxOccError">
       {sanitizeText(t("max_occ_error_phone").replace("[count]", configData.max_occupancy+1))}
       {" "}
       <a href={`tel:${context?.hotelData?.phone || hotelPhone}`}>{context?.hotelData?.phone || hotelPhone}</a>
    </div> : ""}
    </>
  )
}

export default MaxOccupancyError