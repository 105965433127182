import { unstable_setRef } from "@mui/utils";
import { t } from "i18next";
import Moment from "moment";


//This file will only have functions which are commonly used.

// Function For Room Details Request
export function getRoomDetailRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    start_date: "",
    end_date: "",
    party: [
      {
        adults: 1,
        child: 0
      }
    ],
    source_type: "ibe",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    currency: "",
    site_id: configData.site_id,
    customer_id: null,
    customer_type: "Customer",
    agent_id: "",
    code_used: "",
    code_type: null,
    seibu_login: null,
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: configData.user_share_client_id,
    user_country_id: configData.user_country_id
      ? configData.user_country_id
      : 0,
    device_type: "desktop",
    device_os: null,
    allow_average_price: configData.allow_average_price,
    previous_day_threshold_time: configData.previous_day_threshold_time,
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    allow_tripadvisor: configData.allow_trip_adviser,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    is_corporate_enabled: 0,
    special_offer: false,
    flexi_check: false,
    ignore_same_day: 1,
    allow_same_day: 1,
    customer_data: [],
    show_public_rates: null,
    allow_hold_reservation: configData.allow_hold_reservation,
    club_enabled: false,
    loyalty_club: {},
    hotel_id: "",
    room_id: "",
    http_user_agent: ""
  };
  return data;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export function getCurrentDate() {
  var currentDate = new Date(new Date().getTime());
  var day = "" + currentDate.getDate();
  var month = "" + (currentDate.getMonth() + 1);
  var year = currentDate.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}
export function getNextDate() {
  var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var day = "" + currentDate.getDate();
  var month = "" + (currentDate.getMonth() + 1);
  var year = currentDate.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}
export function addRooms(
  rate_id,
  rate_name,
  min_price,
  room_id,
  room_name,
  room_images,
  adult,
  child,
  childage,
  uid
) {
  let roomData = JSON.parse(sessionStorage.getItem("roomData"));
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];

  if (cartItems.length > 0) {
    let isItemExist = cartItems.findIndex(
      filterItem =>
        filterItem.rate_id === rate_id &&
        filterItem.room_id === room_id &&
        filterItem.uid === uid
    );
    if (isItemExist >= 0) {
      cartItems[isItemExist].quantity =
        parseInt(cartItems[isItemExist].quantity) + 1;
    } else {
      let data = {
        room_id: room_id,
        room_name: room_name,
        rate_id: rate_id,
        rate_name: rate_name,
        price: min_price,
        room_image: room_images["full_img_name"],
        adults: adult,
        child: child,
        childage: childage,
        quantity: 1,
        uid: uid,
        isSelected: false
      };
      cartItems.push(data);
    }
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
  } else {
    let quantity = 0;
    let cartData = [
      {
        room_id: room_id,
        room_name: room_name,
        rate_id: rate_id,
        rate_name: rate_name,
        price: min_price,
        room_image: room_images["full_img_name"],
        adults: adult,
        child: child,
        childage: childage,
        quantity: parseInt(quantity) + 1,
        uid: uid,
        isSelected: false
      }
    ];
    sessionStorage.setItem("cartItems", JSON.stringify(cartData));
    cartItems.push(cartData);
  }
  return cartItems;
}

export function removeRooms(
  rate_id,
  rate_name,
  min_price,
  room_id,
  room_name,
  room_images,
  uid
) {
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];

  if (cartItems.length > 0) {
    let isItemExist = cartItems.findIndex(
      filterItem =>
        filterItem.rate_id === rate_id &&
        filterItem.room_id === room_id &&
        filterItem.uid === uid
    );
    if (isItemExist >= 0 && cartItems[isItemExist].quantity > 0) {
      cartItems[isItemExist].quantity =
        parseInt(cartItems[isItemExist].quantity) - 1;
      //cartItems[isItemExist].isSelected = false;
    }
    if (isItemExist >= 0 && cartItems[isItemExist].quantity === 0) {
      cartItems[isItemExist].isSelected = false;
    }
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
  }
  return cartItems;
}

export function checkincreaseroomCount(roomCount, rateId, uid) {
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];
  let isRoomValid = true;
  let existingroomDetail =
    cartItems.length > 0 &&
    cartItems.filter(item => item.rate_id === rateId && item.uid === uid);
  if (
    existingroomDetail.length > 0 &&
    existingroomDetail[0].quantity >= roomCount
  ) {
    isRoomValid = false;
  }
  return isRoomValid;
}
/* Amenties Item */
export function checkdecreaseroomCount(roomCount, rateId) {
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];
  let isRoomValid = true;
  let existingroomDetail =
    cartItems.length > 0 && cartItems.filter(item => item.rate_id === rateId);
  if (
    existingroomDetail.length > 0 &&
    existingroomDetail[0].quantity <= roomCount
  ) {
    isRoomValid = true;
  } else {
    isRoomValid = false;
  }
  return isRoomValid;
}

export function checkmaxroomCount(maxRoom, rate_id, room_id, uid) {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];
  let maxroomCountError = false;
  let quantity = 0;
  if (cartItems.length > 0) {
    let isItemExist = cartItems.findIndex(
      filterItem =>
        filterItem.rate_id === rate_id &&
        filterItem.room_id === room_id &&
        filterItem.uid === uid
    );
    if (isItemExist >= 0) {
      quantity = quantity + cartItems[isItemExist].quantity;
    }
  }

  if (configData.allow_no_rooms !== "") {
    if (quantity >= maxRoom || quantity >= configData.allow_no_rooms) {
      maxroomCountError = true;
    }
  } else if (quantity >= maxRoom) {
    maxroomCountError = true;
  } else {
    maxroomCountError = false;
  }

  return maxroomCountError;
}

export function checkModifyRoomLimit(rate_id, room_id, uid) {
  let modifyFlag = sessionStorage.getItem("modifyFlag");
  let maxroomCountError = false;
  if (modifyFlag !== null) {
    let cartItems = sessionStorage.getItem("cartItems")
      ? JSON.parse(sessionStorage.getItem("cartItems"))
      : [];

    let quantity = 0;
    let roomquantity = 0;
    if (cartItems.length > 0) {
      let isItemExist = cartItems.findIndex(
        filterItem =>
          /*filterItem.isSelected === true &&*/ filterItem.quantity > 0
      );
      if (isItemExist >= 0) {
        quantity = quantity + cartItems[isItemExist].quantity;
      }

      let isItemExistroom = cartItems.findIndex(
        filterItem =>
          filterItem.rate_id === rate_id &&
          filterItem.room_id === room_id &&
          filterItem.uid === uid
      );
      if (isItemExistroom >= 0) {
        roomquantity = roomquantity + cartItems[isItemExistroom].quantity;
      }
    }

    if (quantity >= 1 || roomquantity >= 1) {
      maxroomCountError = true;
    }
  }

  return maxroomCountError;
}

export function prepareCheckoutRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let checkoutRequest = {
    site_id: configData.site_id,
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    hotel_id: "",
    customer_id: 0,
    check_in: "",
    check_out: "",
    currency: "",
    user_currency: "",
    samedays: configData.allow_same_day_booking,
    sameday_override_policy: configData.allow_same_day_override_policy,
    site_name: configData.site_title,
    substitute_room: configData.allow_room_substitute,
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    customer_type: 1,
    company_id: 0,
    addon_ids: [],
    code: null,
    allow_hold_reservation: configData.allow_hold_reservation,
    loyalty_club_id: "",
    rooms: [
      {
        room_id: "",
        rate_id: "",
        room_count: "1",
        room_rate: "",
        adult: "1",
        child: "0",
        childages: [],
        addon_ids: []
      }
    ]
  };
  return checkoutRequest;
}
export function getRoomListRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    start_date: "",
    end_date: "",
    party: [
      {
        adults: 1,
        child: 0
      }
    ],
    source_type: "ibe",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    currency: "",
    site_id: configData.site_id,
    customer_id: null,
    customer_type: "Customer",
    agent_id: "",
    code_used: "",
    code_type: null,
    seibu_login: null,
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: "",
    user_country_id: configData.user_country_id
      ? configData.user_country_id
      : 0,
    device_type: "desktop",
    device_os: null,
    allow_average_price: configData.allow_average_price,
    previous_day_threshold_time: configData.previous_day_threshold_time,
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    allow_tripadvisor: configData.allow_trip_adviser,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    is_corporate_enabled: 0,
    special_offer: false,
    flexi_check: false,
    ignore_same_day: 1,
    allow_same_day: configData.allow_same_day_booking,
    customer_data: [],
    show_public_rates: null,
    allow_hold_reservation: configData.allow_hold_reservation,
    club_enabled: configData.allow_club_tier,
    loyalty_club: {},
    club_enabled: false,
    hotel_id: "",
    source_page: "room_suites",
    http_user_agent: ""
  };
  return data;
}

export function getOfferRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    start_date: "",
    end_date: "",
    party: [
      {
        adults: 1,
        child: 0
      }
    ],
    source_type: "ibe",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    currency: "INR",
    site_id: configData.site_id,
    customer_id: null,
    customer_type: "Customer",
    agent_id: "",
    code_used: "",
    code_type: null,
    seibu_login: null,
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: "",
    user_country_id: configData.user_country_id
      ? configData.user_country_id
      : 0,
    device_type: "desktop",
    device_os: null,
    allow_average_price: configData.allow_average_price,
    previous_day_threshold_time: configData.previous_day_threshold_time,
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    allow_tripadvisor: configData.allow_trip_adviser,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    is_corporate_enabled: 0,
    special_offer: false,
    flexi_check: false,
    ignore_same_day: 1,
    allow_same_day: configData.allow_same_day_booking,
    customer_data: [],
    show_public_rates: null,
    allow_hold_reservation: configData.allow_hold_reservation,
    club_enabled: false,
    loyalty_club: {},
    hotel_id: "",
    source_page: "offer_page",
    http_user_agent: ""
  };
  return data;
}

export function getRoomListForRate(rateId, roomData) {
  let defaultRateData =
    "rates" in roomData &&
    roomData.rates.length > 0 &&
    roomData.rates.filter(item => item.rate_id == rateId);

  return defaultRateData;
}

export function compareRoomAdd(
  room_id,
  room_name,
  min_price,
  room_images,
  room_desc,
  keyhighlight
) {
  let compareItems = sessionStorage.getItem("compareRoomItems")
    ? JSON.parse(sessionStorage.getItem("compareRoomItems"))
    : [];

  if (compareItems.length > 0) {
    let isItemExist = compareItems.findIndex(
      filterItem => filterItem.room_id === room_id
    );
    if (isItemExist >= 0) {
      //Do Nothing
    } else {
      let data = {
        room_id: room_id,
        room_name: room_name,
        price: min_price,
        room_image:
          room_images && room_images["full_img_name"]
            ? room_images["full_img_name"]
            : "",
        room_desc: room_desc,
        keyhighlight: keyhighlight
      };
      compareItems.push(data);
    }
    sessionStorage.setItem("compareRoomItems", JSON.stringify(compareItems));
  } else {
    compareItems = [];
    let cartData = {
      room_id: room_id,
      room_name: room_name,
      price: min_price,
      room_image:
        room_images && room_images["full_img_name"]
          ? room_images["full_img_name"]
          : "",
      room_desc: room_desc,
      keyhighlight: keyhighlight
    };
    compareItems.push(cartData);
    sessionStorage.setItem("compareRoomItems", JSON.stringify(compareItems));
  }
  return compareItems;
}

export function checkCompareRoomExists(room_id) {
  let compareItems = sessionStorage.getItem("compareRoomItems")
    ? JSON.parse(sessionStorage.getItem("compareRoomItems"))
    : [];
  let isRoomValid = true;
  let existingroomDetail =
    compareItems.length > 0 &&
    compareItems.filter(item => item.room_id === room_id);
  if (existingroomDetail.length > 0) {
    isRoomValid = true;
  } else {
    isRoomValid = false;
  }
  return isRoomValid;
}

export function removeCompareRoom(room_id) {
  let compareItems = sessionStorage.getItem("compareRoomItems")
    ? JSON.parse(sessionStorage.getItem("compareRoomItems"))
    : [];

  if (compareItems.length > 0) {
    let isItemExist = compareItems.findIndex(
      filterItem => filterItem.room_id === room_id
    );
    if (isItemExist >= 0) {
      compareItems.splice(isItemExist, 1);
    }

    if (compareItems.length <= 0) {
      sessionStorage.removeItem("compareRoomItems");
    }
    sessionStorage.setItem("compareRoomItems", JSON.stringify(compareItems));
  }
  return compareItems;
}

export function selectRooms(rate_id, room_id, uid) {
  let roomData = JSON.parse(sessionStorage.getItem("roomData"));
  let cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];

  if (cartItems.length > 0) {
    let isItemExist = cartItems.findIndex(
      filterItem =>
        filterItem.rate_id === rate_id &&
        filterItem.room_id === room_id &&
        filterItem.uid === uid
    );
    if (isItemExist >= 0) {
      cartItems[isItemExist].isSelected = true;
    }
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
  }
  return cartItems;
}

export function prepareRatePlan(data, roomId, roomName, roomImg) {
  let roomData = JSON.parse(sessionStorage.getItem("roomData"));

  var cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];

  if (cartItems.length > 0) {
    const cartItemData = data.map(item => {
      let isItemExist = cartItems.findIndex(
        filterItem =>
          filterItem.rate_id === item.rate_id && filterItem.room_id === roomId
      );

      if (isItemExist < 0) {
        return {
          room_id: roomId,
          rate_id: item.rate_id,
          adults: roomData[0].adults,
          child: roomData[0].childrens,
          price: item.min_price,
          quantity: 0,
          rate_name: item.rate_name,
          room_image: roomImg ? roomImg.thumb_img_name : "",
          room_name: roomName,
          roomcount: item.roomcount,
          isSelected: false
        };
      } else {
        return null;
      }
    });

    //cartItems.push(cartItemData);
    const cartItemDatanew = cartItemData.filter(n => n);
    const newCart = cartItems.concat(cartItemDatanew);
    sessionStorage.setItem("cartItems", JSON.stringify(newCart));

    return newCart;
  } else if (!sessionStorage.getItem("cartItems")) {
    const cartItemData = data.map(item => {
      return {
        room_id: roomId,
        rate_id: item.rate_id,
        adults: roomData[0].adults,
        child: roomData[0].childrens,
        price: item.min_price,
        quantity: 0,
        rate_name: item.rate_name,
        room_image: roomImg ? roomImg.thumb_img_name : "",
        room_name: roomName,
        roomcount: item.roomcount,
        isSelected: false
      };
    });
    sessionStorage.setItem("cartItems", JSON.stringify(cartItemData));
    return cartItemData;
  }
}

export function getCardType(number) {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null && number.length === 16) return "Visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    ) &&
    number.length === 16
  )
    return "Mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null && number.length === 15) {
    return "American Express"; //AMEX
  }

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null && number.length === 16) {
    return "Discover";
  }

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null && number.length === 14) {
    return "Diners";
  }

  // Diners
  re = new RegExp("^38" && number.length === 14);
  if (number.match(re) != null) return "Diners Club";

  re = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");
  if (number.match(re) != null && number.length === 14) {
    return "Diners Club";
  }

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]" && number.length === 14);
  if (number.match(re) != null) return "Diners Club";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null && number.length === 16) return "JCB";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";
  return "Invalid Card";
}

export function getPolicyRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    site_id: configData.site_id,
    hotel_id: "",
    customer_id: null,
    room_id: "",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    rate_id: "",
    check_in: "",
    check_out: "",
    samedays: configData.allow_same_day_booking,
    sameday_rate_all_time: configData.allow_same_day_rate_all_time,
    sameday_override_policy: configData.allow_same_day_override_policy,
    site_name: configData.site_title,
    substitute_room: configData.allow_room_substitute,
    hotel_currency: "",
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    channel_manager: "RVNG",
    customer_type: 1,
    company_id: 0,
    hotel_timezone: "Asia/Kolkata"
  };
  return data;
}

export function getPolicyMessage(policyData) {
  let policyMsg = "";

  if (policyData.samemodifycancel === true) {
    if (policyData.payment.summary) {
      policyMsg += "<p>Payment Policy: " + policyData.payment.summary + "</p>";
    }
    policyMsg +=
      "<p>Amendment / Cancellation Policy: " +
      policyData.cancellation.summary +
      "</p>";
  } else {
    if (policyData.payment.summary) {
      policyMsg += "<p>Payment Policy: " + policyData.payment.summary + "</p>";
    }
    policyMsg +=
      "<p>Modification Policy: " + policyData.amendment.summary + "</p>";
    policyMsg +=
      "<p>Cancellation Policy: " + policyData.cancellation.summary + "</p>";
  }

  return policyMsg;
}
export function prepareAmentiesData(roomList) {
  let amentiesArray = [];
  let amentiesData =
    roomList &&
    roomList.rooms.length > 0 &&
    roomList.rooms.map(item => {
      item.facilities.map(amenItem => {
        if (!amentiesArray.includes(amenItem)) {
          amentiesArray.push(amenItem);
        }
      });
      return amentiesArray;
    });
  return amentiesData[0];
}

export function prepareAmentiesDataById(responseData) {
  let roomList = responseData.rooms;
  let result = [];
  let data = [];
  let categoryData = [];
  roomList.map(item => {
    data[item.room_id] = item.facilities;
    return data;
  });
  roomList.map(item => {
    if (item.room_categories.length > 0) {
      categoryData[item.room_id] = item.room_categories;
    }
    return categoryData;
  });
  result["amenties"] = data;
  result["category"] = categoryData;
  return result;
}

export function prepareAmentiesDataByIdForOffers(responseData) {
  let roomList = responseData[0].rooms;
  let result = [];
  let data1 = [];
  let categoryData1 = [];

  roomList.map(item => {
    if (item.facilities.length > 0) {
      data1[item.room_id] = item.facilities;
    }
    return data1;
  });
  roomList.map(item => {
    if (item.room_categories.length > 0) {
      categoryData1[item.room_id] = item.room_categories[0];
    }
    return categoryData1;
  });

  result["amenties"] = data1;
  result["category"] = categoryData1;
  return result;
}
export function prepareReservationData(checkout_submission, bedType, cardtype) {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let checkInDate = sessionStorage.getItem("checkInDate")
    ? sessionStorage.getItem("checkInDate")
    : null;

  let checkoutDate = sessionStorage.getItem("checkoutDate")
    ? sessionStorage.getItem("checkoutDate")
    : null;

  let hotel_id = sessionStorage.getItem("hotelId")
    ? sessionStorage.getItem("hotelId")
    : null;

  let hotel_currency = sessionStorage.getItem("hotelCurrency")
    ? sessionStorage.getItem("hotelCurrency")
    : null;

  if (checkInDate === null || checkoutDate === null || hotel_id === null) {
    return false;
  }
  let numberofAdult = JSON.parse(sessionStorage.getItem("roomData"));
  let adults = numberofAdult[0].adults;
  let children = numberofAdult[0].childrens;
  let childages = children.map(item => {
    return item.age;
  });

  let roomData = JSON.parse(sessionStorage.getItem("cartItems"));
  let filteredRoomData =
    roomData.length > 0 &&
    roomData.filter(item => item.isSelected === true && item.quantity > 0);
  let roomCount = -1;

  let roomDetails = [];

  let roomInfo = filteredRoomData.map(item => {
    let numroom = [];
    for (let i = 0; i < item.quantity; i++) {
      numroom.push(0);
    }

    numroom.map(itemtest => {
      roomCount = roomCount + 1;

      roomDetails[roomCount] = {
        room_id: item.room_id,
        rate_id: item.rate_id,
        room_count: 1,
        room_rate: item.price,
        adults: item.adults,
        children: item.child,
        children_ages: item.childage,
        infants: 0,
        infant_ages: [],
        addons: [],
        arrival_time: checkout_submission.hour + ":" + checkout_submission.min,
        departure_time: "12:00",
        gurantee_codes: "",
        room_pay_now: 0,
        user_selected_room_count: "1",
        bed_type: bedType[roomCount],
        guestdetails: [
          {
            title: "Mr.",
            first_name: checkout_submission.firstname,
            last_name: checkout_submission.lastname,
            email: checkout_submission.email,
            check_in_time_hour: checkout_submission.hour,
            check_in_time_minute: checkout_submission.min,
            check_out_time_hour: "12",
            check_out_time_minute: "00",
            type: "adult",
            iamstay: 1,
            age: 0
          }
        ]
      };
    });
  });

  //let publickey = 'ddfd';
  //var constants = require("constants");
  //var encrypted = crypto.publicEncrypt({"key":publickey,
  //  padding:constants.RSA_PKCS1_PADDING}, checkout_submission.card_number).toString("base64");

  let cardExipry = checkout_submission.card_expiry.split("/");

  roomInfo = roomInfo[0];

  let saveReservation = {
    start_date: checkInDate,
    end_date: checkoutDate,
    hotel_id: hotel_id,
    res_action: "new",
    reference_id: "",
    reservation_source: "js_ibe",
    access_source: "pc",
    site_name: configData.site_title,
    site_id: configData.site_id,
    user_ip: "",
    user_currency: 1,
    hotel_currency: hotel_currency,
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    company_id: null,
    flexi_check: false,
    allow_day_restriction: true,
    allow_rateplan_restriction: false,
    user_language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    company_name: null,
    substitute_room: false,
    isMobile: "false",
    update_profile_address: 0,
    maxCancellationTime: null,
    samedays: configData.allow_same_day_booking,
    sameday_override_policy: configData.allow_same_day_override_policy,
    allow_card_same_day_booking: configData.allow_card_same_day_booking,
    customer: {
      title: "Mr.",
      first_name: checkout_submission.firstname,
      last_name: checkout_submission.lastname,
      phone: checkout_submission.phone,
      email: checkout_submission.email,
      confirm_email: checkout_submission.confirm_email,
      password: "",
      confirm_password: null,
      country: checkout_submission.billingCountry,
      address: {
        address1: checkout_submission.addressLine1
          ? checkout_submission.addressLine1
          : "",
        city: checkout_submission.City ? checkout_submission.City : "",
        state: checkout_submission.addressLine2
          ? checkout_submission.addressLine2
          : checkout_submission.City,
        postal_code: checkout_submission.billingZipCode
          ? checkout_submission.billingZipCode
          : "",
        country: checkout_submission.billingCountry
          ? checkout_submission.billingCountry
          : "",
        address2: checkout_submission.addressLine2
          ? checkout_submission.addressLine2
          : ""
      }
    },
    code_type: "",
    code_used: "",
    customer_type: 1,
    membership_type: "",
    is_membership: 0,
    bclubmember: "",
    iatano: "",
    travel_agent_id: 0,
    customer_id: 0,
    is_guest: 1,
    flight_arrival: ":",
    flight_origin: "",
    flight_number: "",
    arrival_time: checkout_submission.hour + ":" + checkout_submission.min,
    departure_time: "12:00",
    loyalty_club: 0,
    newsletter: 0,
    customBE: "",
    trackingId: "",
    user_browser: "0",
    user_os: "0",
    is_hold: 0,
    hold_without_pay: 0,
    hearus: null,
    payment_gateway: "",
    payment_gateway_pay_now: 0,
    payment_gateway_pay_later: 0,
    xpm_hold: null,
    special_requests: checkout_submission.specialrequest,
    invoice_instructions: "",
    comment: "",
    purpose_of_stay: "",
    colleague_id: null,
    colleague_name: null,
    smoking_room: null,
    microsite_name: "",
    meta_source: "",
    save_cc: null,
    booker_profile: null,
    payment_method: {
      card_type: cardtype,
      card_number: checkout_submission.card_number,
      cardholder_name: checkout_submission.card_holder_name,
      expiration_month: cardExipry[0],
      expiration_year: cardExipry[1],
      cvv: checkout_submission.cvv,
      valid_month: "",
      valid_year: "",
      issue_no: "",
      billing_address: {
        address1: checkout_submission.addressLine1,
        city: checkout_submission.City,
        state: checkout_submission.addressLine2
          ? checkout_submission.addressLine2
          : checkout_submission.City,
        postal_code: checkout_submission.billingZipCode,
        country: checkout_submission.billingCountry,
        address2: checkout_submission.addressLine2
      }
    },
    roomstay: roomDetails,
    campaign_id: 0,
    lead_id: 0,
    is_allow_childbucket: 0,
    tnc: checkout_submission.tnc,
    is_corporate_enabled: 0,
    club_tier_join: 0,
    site_group: "",
    is_booking_engine: "",
    site_membership_type: "",
    loyalty_club_abbr: null,
    term_and_conditions: "",
    cookie_policy: "",
    privacy_policy: "",
    hotelier_client: null,
    channel_id: "",
    request_source: "IBE",
    referrer_endpoint:
      "https://core-ibe-dev.webbuild.hotelierideas.com/en/reservation/checkout",
    refund_amount: null,
    use_points: 0,
    total_points_used: 0,
    totalAmountAfterPoint: 0,
    club_id: null,
    tier_id: null,
    club_currency_id: null,
    is_loyality_member: null,
    apply_promo_code: 0,
    http_user_agent: ""
  };
  let reservationNumber = sessionStorage.getItem("modifyFlag");

  if (reservationNumber !== null) {
    saveReservation.reservation_id = reservationNumber;
    saveReservation.group_reservation_id = reservationNumber;
    saveReservation.res_action = "modify";
    saveReservation.customer_id = sessionStorage.getItem("customerID");
  }

  return saveReservation;
}

export function prepareModifyReservationRequest(modifyRequest) {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    site_id: configData.site_id,
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    site_name: configData.site_title,
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    reservation_id: modifyRequest.reservation_number,
    email: modifyRequest.email,
    call_from: "express_booking"
  };
  return data;
}

export function prepareFetchReservationRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let data = {
    group_reservation_id: null,
    reservation_ids: [],
    site_id: configData.site_id,
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    site_name: configData.site_title,
    get_reservation_ids: "true"
  };
  return data;
}

export function prepareCategoryData(roomList) {
  let roomCategoriesArray = [];
  let roomcategoriesData =
    roomList &&
    roomList.rooms.length > 0 &&
    roomList.rooms.map(item => {
      item.room_categories.map(roomCategory => {
        if (!roomCategoriesArray.includes(roomCategory)) {
          roomCategoriesArray.push(roomCategory);
        }
      });
      return roomCategoriesArray;
    });
  return roomcategoriesData[0];
}

export function getCommonRequest() {
  let data = {
    ip: "",
    user_ip: "",
    user_session_id: "",
    http_user_agent: "",
    mobile_device: "",
    user_browser: "",
    user_os: "",
    request_source: "IBE",
    referer_page: "",
    ip_country: "",
    associated_ibe: null
  };
  return data;
}

export function ucfirst(str) {
  //str = str.toLowerCase();
  //return str.charAt(0).toUpperCase() + str.slice(1);
  return str
    .split(" ")
    .map(function(word, index) {
      // If it is the first word make sure to lowercase all the chars.
      //if(index == 0){
      //return word.toLowerCase();
      //}
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export function capitalizeFirst(str) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase();
}

export function prepareInquiryData(data) {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let requestdata = {
    site_id: configData.site_id,
    site_name: configData.site_title,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    hotel: data.hotel_name,
    estimatedTimeOfArrival: data.datepickertime_of_arrival,
    guest: data.guest,
    additionalComment: data.additional_comment,
    site_url: configData.site_url
  };
  return requestdata;
}

export function prepareRoomCancelRequest(data) {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];
  if (configData.site_id === undefined) {
    return false;
  }
  let requestdata = {
    reservation_id: data.reservationId,
    site_id: configData.site_id,
    customer_id: data.customer_id,
    reservation_type: "single",
    meta_source: "",
    mobile_device: 0,
    microsite: null,
    booking_code: "",
    customer_type: data.customer_type,
    customer_membership_name: "",
    channel_id: data.channel_id,
    source: "ibe",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    policy_time_format: "1",
    dateformat: "d M Y",
    site_name: configData.site_title,
    hotel_id: data.hotel_id,
    hotelier_client: "no",
    cancellation_reason: "Manual"
  };
  return requestdata;
}

export function getRoomUpgradeRequest() {
  let configData = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : [];

  if (configData.site_id === undefined) {
    return false;
  }

  let roomData = JSON.parse(sessionStorage.getItem("cartItems"));
  let filteredRoomData =
    roomData.length > 0 &&
    roomData.filter(item => item.isSelected === true && item.quantity > 0);

  let data = {
    start_date: "",
    end_date: "",
    party: [
      {
        adults: 1,
        child: 0
      }
    ],
    source_type: "ibe",
    language: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    currency: "",
    site_id: configData.site_id,
    customer_id: null,
    customer_type: "Customer",
    agent_id: "",
    code_used: "",
    code_type: null,
    seibu_login: null,
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: "",
    user_country_id: configData.user_country_id
      ? configData.user_country_id
      : 0,
    device_type: "desktop",
    device_os: null,
    allow_average_price: configData.allow_average_price,
    previous_day_threshold_time: configData.previous_day_threshold_time,
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    allow_tripadvisor: configData.allow_trip_adviser,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    is_corporate_enabled: 0,
    special_offer: false,
    flexi_check: false,
    ignore_same_day: 1,
    allow_same_day: configData.allow_same_day_booking,
    customer_data: [],
    show_public_rates: null,
    allow_hold_reservation: configData.allow_hold_reservation,
    club_enabled: configData.allow_club_tier,
    loyalty_club: {},
    club_enabled: false,
    hotel_id: "",
    source_page: "room_upgrade",
    selected_room: filteredRoomData,
    http_user_agent: ""
  };
  return data;
}

export function processCheckout() {
  let roomData = JSON.parse(sessionStorage.getItem("cartItems"));
  let filteredRoomData =
    roomData.length > 0 &&
    roomData.filter(item => item.isSelected === true && item.quantity > 0);

  if (filteredRoomData.length > 1) {
    return 1;
  }

  return 0;
}

export function upgradeUserRoom(roomData) {
  var cartItems = sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [];
  if (cartItems.length > 0) {
    const cartItemData = cartItems.map(item => {
      if (item.isSelected === true) {
        return {
          room_id: roomData.room_id,
          rate_id: item.rate_id,
          adults: item.adults,
          child: item.child,
          childage: item.childage,
          price: roomData.min_price,
          quantity: item.quantity,
          rate_name: item.rate_name,
          room_image:
            roomData.room_images.length > 0
              ? roomData.room_images[0].thumb_img_name
              : "",
          room_name: roomData.room_name,
          roomcount: item.roomcount,
          isSelected: item.isSelected,
          uid:
            item.adults +
            "" +
            item.child.length +
            "" +
            item.rate_id +
            "" +
            roomData.room_id
        };
      }
    });

    //cartItems.push(cartItemData);
    const cartItemDatanew = cartItemData.filter(n => n);

    sessionStorage.setItem("cartItems", JSON.stringify(cartItemDatanew));

    return true;
  }
}

export function roomdescriptiondiplay(keyHighlight, screen) {
  let beddingDetail = "";
  let beddingArray = keyHighlight.bedding_attr ? keyHighlight.bedding_attr : {};
  if (beddingArray) {
    beddingDetail = "";
    Object.keys(beddingArray).map(function(key) {
      beddingDetail = beddingDetail + beddingArray[key] + " " + t(key) + ", ";
    });
    beddingDetail = beddingDetail.slice(0, -2);
  }

  let occupancyDetail = "";
  let occupancyArray = keyHighlight.occupancy_attr
    ? keyHighlight.occupancy_attr
    : {};
  if (beddingArray) {
    occupancyDetail = "";
    Object.keys(occupancyArray).map(function(key) {
      if (key == "sleeps_up_to") {
        let displayText = t(key);

        displayText = displayText.replace(
          new RegExp("sleepsupto", "g"),
          occupancyArray[key]
        );

        occupancyDetail = occupancyDetail + displayText + ", ";
      } else {
        occupancyDetail =
          occupancyDetail + occupancyArray[key] + " " + t(key) + ", ";
      }
    });
    occupancyDetail = occupancyDetail.slice(0, -2);
  }

  let viewDetail = "";
  let viewArray = keyHighlight.room_attr ? keyHighlight.room_attr : {};
  if (viewArray) {
    viewDetail = "";
    Object.keys(viewArray).map(function(key) {
      viewDetail = viewDetail + t(key) + ": " + t(viewArray[key]) + ", ";
    });
    viewDetail = viewDetail.slice(0, -2);
  }

  let areaDetail = "";
  if (keyHighlight.room_size) {
    areaDetail =
      t("average_size") +
      ": " +
      keyHighlight.room_size +
      " " +
      keyHighlight.room_size_type;
  }

  let keyHighlightsData = [];

  if (beddingDetail) {
    keyHighlightsData.push(beddingDetail);
  }
  if (occupancyDetail) {
    keyHighlightsData.push(occupancyDetail);
  }
  if (viewDetail) {
    keyHighlightsData.push(viewDetail);
  }
  if (areaDetail) {
    keyHighlightsData.push(areaDetail);
  }

  return keyHighlightsData;
}

export function currencyDisplay(value) {
  let selectedCurr = localStorage.getItem("selectedCurrency")
    ? localStorage.getItem("selectedCurrency")
    : "GBP";
  let currArray = [
    "BRL",
    "CAD",
    "CZK",
    "DKK",
    "EUR",
    "HUF",
    "ILS",
    "PLN",
    "RUB",
    "SEK",
    "CHF",
    "VND"
  ];
  let currFormat = "en-US";

  if (currArray.includes(selectedCurr)) {
    //currFormat = 'pt-br';
  }

  return value.toLocaleString(currFormat, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export function setSearchPanelCode(status) {
  let checkInDate = sessionStorage.getItem("checkInDate")
    ? sessionStorage.getItem("checkInDate")
    : "";

  let checkoutDate = sessionStorage.getItem("checkoutDate")
    ? sessionStorage.getItem("checkoutDate")
    : "";

  let hotel_id = sessionStorage.getItem("hotelId")
    ? sessionStorage.getItem("hotelId")
    : "";

  let roomdata = sessionStorage.getItem("roomData")
    ? sessionStorage.getItem("roomData")
    : 1;

  let bookingCode = sessionStorage.getItem("bookingcode")
    ? sessionStorage.getItem("bookingcode")
    : "";

  let panelcode =
    checkInDate + checkoutDate + hotel_id + roomdata + bookingCode;
  if (status === true) {
    sessionStorage.setItem("searchcode", panelcode);
  }

  return panelcode;
}

export function setUrlParams(query){
  
  let hotel_id = query.get("hotel_id");
  let checkindate = query.get("checkin");
  let checkoutdate = query.get("checkout");
  let rooms = query.get("rooms");
  let bookingcode = query.get("bookingcode");
  let los = query.get("los");

  if(checkindate)
  {
    checkindate = Moment(checkindate,"YYYYMMDD").format('YYYY-MM-DD');
    sessionStorage.setItem("checkInDate", checkindate);
  }else{
    checkindate = Moment().format('YYYY-MM-DD');
    sessionStorage.setItem("checkInDate", checkindate);
  }

  if(checkoutdate)
  {
    checkoutdate = Moment(checkoutdate,"YYYYMMDD").format('YYYY-MM-DD');
    sessionStorage.setItem("checkoutDate", checkoutdate);
    
  }else if(los){
    checkoutdate = Moment(checkindate, "YYYY-MM-DD").add(los, 'days').format('YYYY-MM-DD');
    sessionStorage.setItem("checkoutDate", checkoutdate);
  }else{
    checkoutdate = Moment(checkindate, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');
    sessionStorage.setItem("checkoutDate", checkoutdate);
  }

  if(bookingcode)
  {
    sessionStorage.setItem("bookingcode", bookingcode);
  }
  let roomData = [];
  
  if(rooms && rooms >= 1)
  {
    let roomCount = 0;
    for (let i = 0; i < rooms; i++) {
      roomCount = i + 1;
      roomData[i] = {
        "room": i+1,
        "adults":query.get("adults_"+roomCount) ? parseInt(query.get("adults_"+roomCount)) : 1,
        "childrens":[]
      }
    }
    sessionStorage.setItem("roomData", JSON.stringify(roomData));
    
  }
  
}
