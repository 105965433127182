import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { route } from "../utils/route";
import { KEYS } from "../utils/constant";
import { LocalStorage } from "../utils/storage";
import DeclineBookingComponent from "../components/error/DeclineBooking";

const DeclineBooking = () => {
  let navigate = useNavigate();

  const hotelEmail = LocalStorage.get(KEYS.HOTEL_EMAIL);
  const hotelPhone = LocalStorage.get(KEYS.HOTEL_PHONE);
  const callToAction = () => navigate(route.roomList);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <DeclineBookingComponent
      hotelEmail={hotelEmail}
      hotelPhone={hotelPhone}
      callToAction={callToAction}
    />
  );
};

export default DeclineBooking;
