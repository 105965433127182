import { useState, useEffect, useContext } from "react";
import { cancelReservationEmail, fetchCancelReservationData } from "../services/confirmation";
import {
	getCancelReservationRequest,
	getReservationCancellationEmailRequest,
} from "../utils/requestData";
import AppContext from "../utils/appContext";
import { useErrorBoundary } from "react-error-boundary";
import { useAuth } from "./useAuth";

export const useCancelReservationHook = ({
	authToken,
	siteConfig,
	searchPanelData,
	bookingData,
}) => {
	const context = useContext(AppContext);
	const { showBoundary } = useErrorBoundary();
	const { getAuth } = useAuth()
	const [loading, setLoading] = useState(true);
	const [cancelReservationData, setCancelReservationData] = useState(null);
	const [retryAttempted, setretryAttempted] = useState(false);
	const [cancelBookingData, setcancelBookingData] = useState(false);
	const [emailTriggerStatus, setEmailTriggerStatus] = useState(false);

	const sendEmailOnSuccessfulCancelation = async (siteConfig, bookingData, cancelationData) => {
		let requestData = getReservationCancellationEmailRequest({
			siteConfig,
			bookingData,
			cancelationData,
		});
		try {
			await cancelReservationEmail(requestData, authToken).then((res) => {
				console.log("email send.");
			});
		} catch (error) {
			await getAuth()
			await cancelReservationEmail(requestData, authToken).then((res) => {
				console.log("email send.");
			});
			console.log("error - email not send.");
		}
	};

	const getCancelReservationDetails = async () => {
		let requestData = getCancelReservationRequest({
			// currencyData: context.currencyData,
			// source_page: "cancelconfirmation",
			bookingData: bookingData ? bookingData : cancelBookingData,
		});
		try {
			await setLoading(true);
			await fetchCancelReservationData(requestData, context.authToken).then(
				(res) => {
					setEmailTriggerStatus(true);
					setCancelReservationData(res.data);
					if (!res.data?.original?.CannotBeCancelled && res.data?.status) {
						sendEmailOnSuccessfulCancelation(siteConfig, bookingData, res.data);
					}
				}
			);
		} catch (error) {
			if (!emailTriggerStatus) {
				if (error === "Network Error") {
					let cancelData = {
						cancelReservationData: {
							original: {
								CannotBeCancelled: true
							}
						}
					}
					setCancelReservationData(cancelData)
				} else if (error.renewToken && !retryAttempted) {
					setcancelBookingData(bookingData)
					getAuth().then(() => {
						setretryAttempted(true);
					})
				} else {
					showBoundary(error);
				}
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (context.authToken && searchPanelData && !emailTriggerStatus) {
			getCancelReservationDetails();
		}
	}, [context.authToken]);

	return {
		loading,
		setLoading,
		cancelReservationData,
	};
};
