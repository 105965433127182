import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getSendEmailRequest } from "../../utils/requestData";
import { sendReservationEmail } from "../../services/confirmation";
import { useAuth } from "./../../hooks/useAuth";
import AppContext from "../../utils/appContext";

export default function ConfirmationEmailForm({
	authToken,
	siteConfig,
	confirmationData,
}) {
	const { t } = useTranslation();
	const { getAuth } = useAuth()
	const context = useContext(AppContext);
	let [validationError, setValidationError] = useState(false);
	let [displaySuccessMessage, setSuccessMessage] = useState(false);
	const [bookingDetails, setBookingDetails] = useState("")
	let retryAttempted = false;
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const onSubmit = async (data) => {
		if (data.multipleemails === "") {
			setValidationError(true);
			return false;
		}
		let emailList = data.multipleemails;
		let emails = emailList.replace(/\s/g, "").split(",");
		let valid = true;
		let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		for (let i = 0; i < emails.length; i++) {
			if (emails[i] == "" || !regex.test(emails[i])) {
				valid = false;
			}
		}
		if (valid === false) {
			setValidationError(true);
			return false;
		}
		let requestData = getSendEmailRequest({ confirmationData, siteConfig });
		requestData.toemail = emails.toString();
		try {
			await sendReservationEmail(requestData, context.commaBookingToken || authToken).then(({ data }) => {
				setValidationError(false);
				setSuccessMessage(true);
				reset();
				setTimeout(function () {
					setSuccessMessage(false);
				}, 5000);
			});
		} catch (error) {
			if (error.renewToken && !retryAttempted) {
				setBookingDetails(data);
				getAuth("", "", "", true);
				retryAttempted = true;
			} else {
			  console.log("error - email not send.");
			}
		}
	};

	useEffect(() => {
		if (context.commaBookingToken) {
			onSubmit(bookingDetails)
		}
	}, [context.commaBookingToken])

	return (
		<form className='sendEmailFormWrap' onSubmit={handleSubmit(onSubmit)}>
			<div className='sendEmailWrap'>
				<input
					type='text'
					className='form-control requiredElement'
					{...register("multipleemails")}
					placeholder={t("enter_email_id_comma")}
				/>
				{validationError === true ? <div className='errorMessage'>{t("validemail")}</div> : ""}
				{displaySuccessMessage === true ? <div className='successMsg'>{t("sendEventMailMsg")}</div> : ""}
				<div className='modalBottomBtn paddingT30'>
					<input
						type='submit'
						value={t("submit")}
						name='bookNow'
						id='bookNow'
						className='btn-reserve-booking customBtn customBtnDark w100'
					/>
				</div>
			</div>
		</form>
	);
}
