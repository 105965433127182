import React, { useContext, useEffect } from 'react'
import FilterIcon from "../../styles/images/filter-icon.png"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppContext from "../../utils/appContext";
import { useTranslation } from "react-i18next"
import { capitalizeStr } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 380,
  p: 0,
};

const FilterRooms = ({
  setRoomList,
  setOfferList,
  setViewHideRoomOffer,
  getOfferList,
  setLoading,
  showData,
  setInquireList
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const context = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [filterCategory, setFilterCategory] = useState([])
  const [filterFacility, setFilterFacility] = useState([])
  const [sortOrder, setSortOrder] = useState("lowToHigh")
  const [viewBy, setViewBy] = useState("rooms")
  const [clearFilter, setClearFilter] = useState(false)
  const [viewError, setError] = useState(false)

  useEffect(() => {
   if (clearFilter) {
     applyFilter()
   }
  }, [clearFilter])

  const handleClose = () => {
    setOpen(false)
  }

  const onChangePrice = (event) => {
    const checkboxId = event.target.id;
    setSortOrder(checkboxId === sortOrder ? null : checkboxId);
  }

  useEffect(() => {
     if (searchParams.get("view") === "ratetype") {
        setViewBy("rates")
	 }
	 if (searchParams.get("view") === "roomtype") {
        setViewBy("rooms")
	 }
  }, [searchParams?.get("view")])

  useEffect(() => {
	setFilterCategory([])
	setFilterFacility([])
  }, [context.roomData, context.offerData])

  const onChangeViewBy = (event) => {
    setViewBy(event.target.id === 'rates' ? 'rates' : 'rooms')
  }

  const onChangeCategory = (type) => {
    setFilterCategory((prev) => (prev.includes(type) ? prev.filter((item) => item !== type) : [...prev, type]));
  }

  const onChangeFacility = (name) => {
    setFilterFacility((prev) => (prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name]));
  }

  const removeFilter = async () => {
	await setOpen(false)
	await setFilterCategory([])
    await setFilterFacility([])
	await setSortOrder("lowToHigh")
    await setViewBy('rooms')
	await setError(false)
          setClearFilter(true)
  }

  const applyFilter = async () => {
	setError(false)
    if (viewBy === "rooms") {
      setLoading(true)
      setOpen(false);
      setTimeout(() => {
          let filteredData = []
          if (filterCategory?.length || filterFacility?.length) {
            filteredData = context?.roomData?.filter((item) => {
              let hasRoomCategory = item.room_categories.some((category) =>
                filterCategory.includes(category)
              );
              let hasFeature = Object.values(item.facilities).some((facility) =>
              filterFacility.includes(facility)
              );
			  
			  if(filterCategory?.length > 0 && filterFacility?.length > 0){
				return hasRoomCategory && hasFeature;
			  }else{
				return hasRoomCategory || hasFeature;
			  }	  
            });
			if (filteredData.length > 0) {
            	setRoomList(filteredData)
				// context.setRoomData(filteredData)
			} else {
				setError(true)
				setOpen(1)
			}
          }
          if (sortOrder) {
            let arrayToSort = filteredData.length ? filteredData : context.roomData
            const filteredArray = arrayToSort.filter((obj) => obj.min_price > 0)
            let sortedArray = filteredArray?.sort((a, b) => sortOrder === "lowToHigh" ? a.min_price - b.min_price : b.min_price - a.min_price);
			const priceSortedArray = sortedArray.map(x => {
				return {
					...x,
					price: x?.price?.sort((a, b) => sortOrder === "lowToHigh" ? a.min_price - b.min_price : b.min_price - a.min_price)
				}
			})
            const zeroPriceArray = arrayToSort?.filter((obj) => !obj.min_price)
            setRoomList(priceSortedArray?.concat(zeroPriceArray) || [])
			// context.setRoomData(priceSortedArray?.concat(zeroPriceArray) || [])
          }
          if (!filterCategory.length && !filterFacility.length && !sortOrder) {
            setRoomList(context.roomData)
          }
          setOfferList([])
          setViewHideRoomOffer(false)
          setLoading(false);
          setClearFilter(false)
      }, 300);
    } else {
      setOpen(false)
      setLoading(true)
    //   getOfferList().then((newData) => {
        const data = JSON.parse(JSON.stringify(context.offerData))
        setTimeout(() => {
          let filteredData = []
		  let inquireFilteredData = []
          if (filterCategory.length || filterFacility.length) {
            filteredData = data?.map(item => {
              const filteredRooms = item.rooms.filter(room => {
                const matchCategory = room.room_categories.some(category =>
                  filterCategory.includes(category)
                )
                const matchFacility = Object.values(room.facilities).some(facility =>
                  filterFacility.includes(facility)
                )

				if(filterCategory?.length > 0 && filterFacility?.length > 0){
					return matchCategory && matchFacility;
				}else{
					return matchCategory || matchFacility;
				}
              })
              return { ...item, rooms: filteredRooms }
            }).filter(i => i.rooms.length > 0)
			inquireFilteredData = context.inquireData?.filter(room => {
				  const matchCategory = room.room_categories.some(category =>
					filterCategory.includes(category)
				  )
				  const matchFacility = Object.values(room.facilities).some(facility =>
					filterFacility.includes(facility)
				  )
  
				  if(filterCategory?.length > 0 && filterFacility?.length > 0){
					  return matchCategory && matchFacility;
				  }else{
					  return matchCategory || matchFacility;
				  }
				})

			if(filteredData?.length || inquireFilteredData?.length){
				setOfferList(filteredData?.length ? filteredData : [])
				setInquireList(inquireFilteredData?.length ? inquireFilteredData : [])
				// context.setOfferData(filteredData)
			}else{
				setError(true)
				setOpen(1)
			}
          }
          if (sortOrder) {
            let arrayToSort = filteredData.length ? filteredData : data
            const filteredArray = arrayToSort.filter((obj) => obj.min_price > 0)
            let sortedArray = filteredArray?.sort((a, b) => sortOrder === "lowToHigh" ? a.min_price - b.min_price : b.min_price - a.min_price);
			const priceSortedArray = sortedArray.map(item => {
				const sortedRooms = item.rooms.slice().sort((room1, room2) => {
				  return sortOrder === "lowToHigh" ? room1.price.min_price - room2.price.min_price : room2.price.min_price - room1.price.min_price	
				});
				return {
				  ...item,
				  rooms: sortedRooms
				};
			  })
            const zeroPriceArray = arrayToSort?.filter((obj) => !obj.min_price)
            setOfferList(priceSortedArray?.concat(zeroPriceArray) || [])
			// context.setOfferData(priceSortedArray?.concat(zeroPriceArray) || [])
          }
          if (!filterCategory.length && !filterFacility.length && !sortOrder) {
            setOfferList(data)
			setInquireList(context.inquireData)
			// context.setOfferData(data)
          }
          setRoomList([])
          setViewHideRoomOffer(true)
          setLoading(false)
        }, 300);
    //  })
    }
  }

  let cat = context?.roomData?.map(item => item?.room_categories ? item.room_categories : "")
  const roomCategories = cat?.length && cat?.reduce((acc, [roomType]) => (acc[roomType] = (acc[roomType] || 0) + 1, acc), {});
  const roomType = roomCategories && Object.keys(roomCategories)?.length && Object.entries(roomCategories).map(([type, count]) => ({ type, count }));

  let fac = context?.roomData?.map(item => item?.facilities)
  const features = fac && Object.keys(fac)?.length && Object.entries(
    fac.reduce((acc, cur) => {
      cur && Object.keys(cur)?.length && Object.keys(cur)?.forEach(key => {
        const value = cur?.[key];
        if (acc?.hasOwnProperty(value)) {
          acc[value]++;
        } else {
          acc[value] = 1;
        }
      });
      return acc;
    }, {})
  ).map(([name, count]) => ({ name, count }));

  const uniquePriceObjects = context?.roomData?.length && context?.roomData?.reduce((acc, obj) => {
	obj?.price?.forEach((priceObj) => {
	  const isExisting = acc.find((uniqueObj) => uniqueObj.rate_id === priceObj.rate_id);
	  if (!isExisting) {
		acc.push(priceObj);
	  }
	});
	return acc;
  }, []);

  const addToCompare = () => {
    context.setCompareRooms(true);
  }

  const hideCompare = () => {
    context.setCompareRooms(false);
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [open])

  return (
		<>
			<div className='filtersCompareBar paddingTB15 dFlex justifyContentSpBtw alignItemsCenter'>
				<div className='filtersWrap'>
					<a
						href='javascript:void(0);'
						className='filterModal filtersLink dInlineBlock'
						tabIndex='0'
						onClick={() => setOpen(true)}
					>
						<img
							src={FilterIcon}
							alt='Filter Rooms Icon'
							width='17'
							height='17'
						/>
						{t("filter_rooms")}
					</a>
				</div>
				{showData ? (
					<div className='compareWrap'>
						<a
							className='customBtn customBtnTransparent compareRoomBtn'
							tabIndex='0'
							id='comparenowsection'
							href='javascript:void(0);'
							onClick={() =>
								context.compareRooms
									? hideCompare()
									: addToCompare()
							}
						>
							{context.compareRooms
								? t("hide_comparison")
								: t("compare_rooms")}
						</a>
					</div>
				) : (
					""
				)}
			</div>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='filterRoomModalPopup'
				role="dialog"
			    aria-modal="true"
			>
				<Box sx={style}>
					<div className='white-popup-block'>
						<Typography
							id='modal-modal-title'
							variant='h6'
							component='h2'
						>
							{t("filter_rooms")}
							<button
								type='button'
								className='mfp-close'
								onClick={() => handleClose()}
								aria-level='Close Button'
							>
								Close
							</button>
						</Typography>
						<div className='filterCollapseWrap'>
							{viewError ? <div className="filterNoRoomSearch">{t("noroomsearch")}</div> : <></>}
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<h3>{t("sort")}</h3>
								</AccordionSummary>
								<AccordionDetails>
									<ul>
										<li className='posRelative'>
											<input
												type='checkbox'
												id='lowToHigh'
												checked={
													sortOrder === "lowToHigh"
												}
												onChange={(event) =>
													onChangePrice(event)
												}
											/>
											<label for='lowToHigh'>
												{t("low_to_high")}
											</label>
										</li>
										<li className='posRelative'>
											<input
												type='checkbox'
												id='highToLow'
												checked={
													sortOrder === "highToLow"
												}
												onChange={(event) =>
													onChangePrice(event)
												}
											/>
											<label for='highToLow'>
												{t("high_to_low")}
											</label>
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
							<Accordion defaultExpanded>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel2a-content'
									id='panel2a-header'
								>
									<h3>{t("view_by")}</h3>
								</AccordionSummary>
								<AccordionDetails>
									<ul>
										<li className='posRelative'>
											<input
												type='checkbox'
												id='rooms'
												checked={viewBy === "rooms"}
												onChange={(event) =>
													onChangeViewBy(event)
												}
											/>
											<label for='rooms'>
												{t("rooms")}{" "}
												<span className='filterCount posAbsolute'>
													(
													{context?.roomData?.length
														? context.roomData
																.length
														: context?.offerData
																?.length
														? context.offerData.reduce(
																(acc, obj) =>
																	acc +
																	obj.rooms
																		.length,
																0
														  )
														: 0}
													)
												</span>
											</label>
										</li>
										<li className='posRelative'>
											<input
												type='checkbox'
												id='rates'
												checked={viewBy === "rates"}
												onChange={(event) =>
													onChangeViewBy(event)
												}
											/>
											<label for='rates'>
												{t("rates")}{" "}
												<span className='filterCount posAbsolute'>
													(
													{context?.offerData?.length
														? context.offerData
																.length
														: context?.roomData
																?.length
														? uniquePriceObjects?.length
														: 0}
													)
												</span>
											</label>
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<h3>{t("room_type")}</h3>
								</AccordionSummary>
								<AccordionDetails>
									{roomType?.length ? (
										<ul>
											{roomType?.map((item) => (
												<li className='posRelative'>
													<input
														type='checkbox'
														id={item.type}
														value={item.type}
														onChange={() =>
															onChangeCategory(
																item.type
															)
														}
														checked={filterCategory.includes(
															item.type
														)}
													/>
													<label for={item.type}>
														{capitalizeStr(
															item.type
														)}{" "}
														<span className='filterCount posAbsolute'>
															({item.count})
														</span>
													</label>
												</li>
											))}
										</ul>
									) : (
										<div className='notTypeFeature'>
											{t("room_type_not_available")}
										</div>
									)}
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel2a-content'
									id='panel2a-header'
								>
									<h3>{t("features")}</h3>
								</AccordionSummary>
								<AccordionDetails>
									{features?.length ? (
										<ul>
											{features?.map((item) => (
												<li className='posRelative'>
													<input
														type='checkbox'
														id={item.name}
														value={item.name}
														onChange={() =>
															onChangeFacility(
																item.name
															)
														}
														checked={filterFacility.includes(
															item.name
														)}
													/>
													<label for={item.name}>
														{item.name}{" "}
														<span className='filterCount posAbsolute'>
															({item.count})
														</span>
													</label>
												</li>
											))}
										</ul>
									) : (
										<div className='notTypeFeature'>
											{t("room_features_not_available")}
										</div>
									)}
								</AccordionDetails>
							</Accordion>
						</div>
						<div className='filterBtnWrap buttonsGroup textCenter'>
							<button
								className='customBtn customBtnTransparent'
								type='button'
								onClick={() => removeFilter()}
							>
								{t("clear")}
							</button>
							<button
								className='customBtn customBtnDark'
								type='button'
								onClick={() => applyFilter()}
							>
								{t("apply")}
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
  );
}

export default FilterRooms
