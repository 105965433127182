import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchAuthToken = (data) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.getToken.method, apiList.getToken.url, data).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}
