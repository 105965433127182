import { useContext, useEffect, useState } from "react"
import { fetchMsgClosedUserGroup } from "../services/msgClosedUserGroup"
import { getMessageClosedUserGroupRequest } from "../utils/requestData";
import { useAuth } from "./useAuth";
import AppContext from "../utils/appContext";

/**
 * Custom hook to fetch and manage closed user group messages
 * @param {Object} options - Object containing authToken and siteId
 * @returns {Object} - Object containing messageClosedUserGroup, isPopupVisible, setIsPopupVisible
 */
export const useMessageClosedUserGroup = ({ authToken, siteId }) => {
    const [messageClosedUserGroup, setMessageClosedUserGroup] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const { getAuth } = useAuth();
    const context = useContext(AppContext);
    let retryAttempted = false;

    /**
     * Function to fetch closed user group messages
     * @param {string} closeUpToken - Close up token for fetching messages
     */
    const fetchMessageClosedUserGroup = async (closeUpToken) => {
        try {
            const requestData = getMessageClosedUserGroupRequest({ siteId });
            const response = await fetchMsgClosedUserGroup(closeUpToken || authToken, requestData);
            setMessageClosedUserGroup(response.data);
        } catch (error) {
            if (error.renewToken && !retryAttempted) {
                await getAuth("", "", true);
                retryAttempted = true;
            }
        }
    }

    useEffect(() => {
        if (siteId && authToken) {
            fetchMessageClosedUserGroup();
        }
    }, [siteId, authToken])
    
    useEffect(() => {
        if (context.closeUpMsg && siteId) {
            fetchMessageClosedUserGroup(context.closeUpMsg);
        }
    }, [context.closeUpMsg])

    return {
        messageClosedUserGroup,
        isPopupVisible,
        setIsPopupVisible
    }
}