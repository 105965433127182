// import { MetaTags } from 'react-meta-tags';
import { useSeoMetaHook } from "../../../hooks/useSEOMetaHook";

export default function SeoTags({ authToken, currentPage }) {
	const { metaTitle, metaDescription, metaKeywords } = useSeoMetaHook({
		authToken,
		currentPage,
	});

	return (
		<>
			<title>{metaTitle}</title>
			<meta name='description' content={metaDescription} />
			{/* <meta name='keywords' content={metaKeywords} /> */}
		</>
	);
}
