import React from 'react'
import { useFieldArray } from "react-hook-form"
import GuestInfo from './guestInfo'

const GuestDetails = ({
    // activeTabGuest,
    stopClose,
    // handleTabClickGuest,
    control,
    setValue,
    getValues,
    errors,
    register,
    watch,
    checkboxIndex,
    setCheckBoxIndex,
    clearErrors,
    checkoutData,
    trigger,
    bookingData
}) => {
    const { fields } = useFieldArray({
        control,
        name: "guest",
    })

    return (
        <>
            {fields.map((item, index) => (
               <GuestInfo
                 item={item}
                 fields={fields}
                 index={index}
                 stopClose={stopClose}
                 setValue={setValue}
                 getValues={getValues}
                 errors={errors}
                 register={register}
                 watch={watch}
                 checkboxIndex={checkboxIndex}
                 setCheckBoxIndex={setCheckBoxIndex}
                 clearErrors={clearErrors}
                 checkoutData={checkoutData}
                 trigger={trigger}
                 bookingData={bookingData}
               />
            ))}
        </>
    )
}

export default GuestDetails