import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchSiteMaintenance = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(
		apiList.siteMaintenance.method,
		apiList.siteMaintenance.url,
		data,
		authToken
	)
		.then((res) => {
			resolve(res);
		})
		.catch((err) => reject(err));
  })
}