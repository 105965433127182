import React from 'react'

const Input = ({
    title = "title",
    type = "text",
    placeholder = "",
    id="",
    required = false,
    maxLength = "",
    isDisabled = false,
    error,
    inputMode = "text",
    onChange = () => false,
    onKeyUp = () => false,
    onKeyDown = () => false,
    rest,
}) => {
  return (
    <>
    <input
      title={title}
      id={id}
      type={type}
      placeholder={`${placeholder}${required ? "*" : ""}`}
      maxLength={maxLength}
      disabled={isDisabled}
      inputMode={inputMode}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      {...rest}
    />
    {error && <p className='errorMessage'>{error}</p>}
    </>
  )
}

export default Input