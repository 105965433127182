import React, { useContext, useRef, useState } from "react";
import Input from "../common/Input";
import Select from "../common/Select";
import { imageUrl, KEYS } from "../../utils/constant";
import Checkbox from "../common/Checkbox";
import {
	TranslatedPrefix,
	alphabeticName,
	checkImage,
	replaceEmoji,
	PREFIX
} from "../../utils/utils";
import defaultEnhanceImage from "../../styles/images/champagne.jpg";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/appContext";
// import GenderSelect from '../common/Select'
const GuestInfo = ({
	item,
	fields,
	index,
	stopClose,
	setValue,
	errors,
	register,
	watch,
	checkboxIndex,
	setCheckBoxIndex,
	clearErrors,
	checkoutData,
	trigger,
}) => {
	const { t } = useTranslation();
	let context = useContext(AppContext);
	const [activeTabGuest, setActiveTabGuest] = useState(false);
	const [showEarlyCheckinMessage, setshowEarlyCheckinMessage] =
		useState(false);
	const roomDetail = SessionStorage.getJSON(KEYS.ROOM_DATA);
	const ref = useRef(null);
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	const countUpdate = (fieldId, addonId, increment) => {
		const updatedAddonData = watch("guest").map((item) => {
			if (item.room !== fieldId) {
				return item;
			}
			const updatedAddons = item.addonData.map((add) => {
				if (add.id === addonId && add.room_id == item.room_info.room_id && add.rate_id == item.room_info.rate_id) {
					let inventory = Object.values(add.amount).reduce((acc, curr) => acc + curr.inventory, 0);
					let newCount = (add.count || 0) + (increment ? 1 : -1);

					if (newCount > inventory) {
						newCount = inventory;
					} else if (newCount < 0) {
						newCount = 0;
					}
					const updatedRoomDetail = roomDetail.map((room) => {
						if (!room.enhancement) {
							room.enhancement = [];
						}
						if (room.room === fieldId) {
							const findData = room.enhancement.find((addon) => addon.id === addonId);
							const newPrice = add.addon_type === "one_off" ? 
							  Object.values(add.amount)?.[0]?.rate
							: Object.values(add.amount).reduce((acc, curr) => acc + curr.rate, 0);
							if (findData) {
								findData.price = newPrice;
								findData.count = newCount;
								findData.name = add.title;
								findData.id = addonId;
								findData.type = add.addon_type;
							} else {
								room.enhancement.push({
									price: newPrice,
									count: newCount,
									name: add.title,
									id: addonId,
									type: add.addon_type
								});
							}
							if (newCount === 0) {
								room.enhancement = room.enhancement.filter(
									(item) => item.id !== addonId
								);
							}
						}
						return room;
					});
					SessionStorage.setJSON(KEYS.ROOM_DATA, updatedRoomDetail);
					context.setRoomData(updatedRoomDetail);
					return { ...add, count: newCount };
				} else {
					return add;
				}
			});
			return { ...item, addonData: updatedAddons };
		});
		setValue("guest", updatedAddonData);
		//   enableScroll()
	};
	let formatString = (e) => {
		setshowEarlyCheckinMessage(false);
		var code = e.keyCode;
		const regex = /^[0-9:]*$/;
		var allowedKeys = [8];
		if (code === 8) {
			return false;
		}
		if (!regex.test(e.target.value)) {
			e.target.value = "";
			return;
		}
		let value = e.target.value;
		if (value.length > 23) {
			value = value.substring(0, 23);
		}
		if (value.length === 2) {
			let hour = value;
			if (hour > 23) {
				hour = 23;
			}
			value = hour.toString() + ":";
		}
		if (value.length > 2) {
			let hour = value.substring(0, 2);
			let minute = value.substring(3);
			minute = minute.replace(/[^\d]/g, "");
			if (minute.length > 2) {
				minute = minute.substring(0, 2);
			}
			minute = minute.replace(/[^\d]/g, "");
			if (parseInt(minute) > 59) {
				minute = "59";
			}
			value = hour.toString() + ":" + minute;
		}
		if (value.length === 5) {
			let earlyval = value.split(":");
			if (earlyval[0] < 15) {
				setshowEarlyCheckinMessage(true);
			} else {
				setshowEarlyCheckinMessage(false);
			}
		} else {
			setshowEarlyCheckinMessage(false);
		}
		e.target.value = value;
	};
	const scrollToTop = () => {
		setActiveTabGuest(!activeTabGuest);
		if (!activeTabGuest) {
			const marginTop = 125;
			const elementTop = ref.current.getBoundingClientRect().top;
			window.scrollBy({
				top: elementTop - marginTop,
				behavior: "smooth",
			});
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter" && event.target.tagName === "DIV") {
			// setActiveTabGuest(!activeTabGuest);
			scrollToTop();
		}
	};

	const TRANS_PREFIX = TranslatedPrefix(PREFIX, language);

	return (
		<div
			className={`guestDetailsCheckout ${
				activeTabGuest && "showGuestDetail"
			}`}
			tabIndex='0'
			onClick={(e) => scrollToTop()}
			ref={ref}
			onKeyPress={(e) => handleKeyPress(e)}
		>
			<h3 className='textUppercase'>
				{t("room")} {index + 1}{" "}
				<span className='dInlineBlock posRelative'>
					{t("guest_details")}
				</span>
			</h3>
			<div className='guestDetailsWrap' onClick={(e) => stopClose(e)}>
				<div className='columnWrap'>
					<div className='column100 marginB25 customCheckBox'>
						<Checkbox
							text={t("stay_room")}
							id={`stayRoom${index}`}
							isChecked={
								!watch("notStayRoom") && checkboxIndex === index
							}
							rest={register(`guest.${index}.stayInRoom`, {
								onChange: (event) => {
									if (event.target.checked) {
										setCheckBoxIndex(index);
										setValue(
											`guest.${index}.stayInRoom`,
											event.target.checked
										);
										setValue(
											"notStayRoom",
											watch("guest")?.find(
												(x) => x.stayInRoom === true
											)
												? false
												: true
										);
										if (
											watch("guest").find(
												(x) => x.stayInRoom
											)
										) {
											setValue(
												`guest.${index}.prefix`,
												watch("prefix")
											);
											setValue(
												`guest.${index}.firstName`,
												watch("firstName")
											);
											setValue(
												`guest.${index}.lastName`,
												watch("lastName")
											);
											clearErrors(
												`guest.${index}.firstName`
											);
											clearErrors(
												`guest.${index}.lastName`
											);
											clearErrors(
												`guest.${index}.prefix`
											);
										}
									} else {
										setCheckBoxIndex();
										setValue("notStayRoom", true);
									}
								},
							})}
						/>
					</div>
					<div className='column33 marginB25 mobileTwoColumn'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag' aria-lablledby='Title'>Title</span> */}
							<Select
								title='Title'
								dropdown={TRANS_PREFIX}
								rest={register(`guest.${index}.prefix`)}
								isDisabled={watch(`guest.${index}.stayInRoom`)}
								error={errors?.guest?.[index]?.prefix?.message}
							/>
							{/* <GenderSelect
                      rest={register(`guest.${index}.prefix`)}
                      isDisabled={watch(`guest.${index}.stayInRoom`)}
                      setValue={setValue}
                      labelText={`guest.${index}.prefix`}
                    /> */}
						</label>
					</div>
					<div className='column33 marginB25 mobileTwoColumn'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag'>{t("first_name")}</span> */}
							<Input
								title={t("first_name")}
								placeholder={t("first_name")}
								required
								maxLength='20'
								rest={register(`guest.${index}.firstName`, {
									onChange: (e) => {
										setValue(
											`guest.${index}.firstName`,
											alphabeticName(e.target.value)
										);
									},
								})}
								error={
									errors?.guest?.[index]?.firstName?.message
								}
								isDisabled={watch(`guest.${index}.stayInRoom`)}
							/>
						</label>
					</div>
					<div className='column33 marginB25'>
						<label className='inputFieldWrap'>
							{/* <span className='hideTag'>{t("last_name")}</span> */}
							<Input
								title={t("last_name")}
								placeholder={t("last_name")}
								required
								maxLength='20'
								rest={register(`guest.${index}.lastName`, {
									onChange: (e) => {
										setValue(
											`guest.${index}.lastName`,
											alphabeticName(e.target.value)
										);
									},
								})}
								error={
									errors?.guest?.[index]?.lastName?.message
								}
								isDisabled={watch(`guest.${index}.stayInRoom`)}
							/>
						</label>
					</div>
					<div className='column33 marginB25'>
						<label className='dBlock guestTime1'>
							<span
								className='dBlock'
								aria-lablledby={t("estimated_time")}
							>
								{t("estimated_time")}
							</span>
							<Input
								// title={t("estimated_time")}
								placeholder='HH : MM'
								maxLength='5'
								onKeyUp={(e) => formatString(e)}
								inputMode='numeric'
								error={
									errors?.guest?.[index]?.arrivalTime?.message
								}
								rest={register(`guest.${index}.arrivalTime`, {
									/*onBlur: () => {
                            if (watch("guest")) {
                              trigger("guest");
                            }
                          }*/
								})}
							/>
						</label>
						{showEarlyCheckinMessage ? (
							<span className='earlyCheckin'>
								{t("early_checkin_message")}
							</span>
						) : (
							<></>
						)}
					</div>
				</div>
				{item?.addonData?.length ? (
					<div className='enhancementCheckout'>
						<h4 className='textUppercase'>
							{t("optional_enhancement")}
						</h4>
						<div className='columnWrap'>
							{item.addonData?.map((add) => {
								if (
									add.booking_type == "0" &&
									add.is_complimentary === 0 &&
									(add.addon_type == "daily" ||
										add.addon_type === "one_off") &&
									add.room_id == item.room_info.room_id &&
									add.rate_id == item.room_info.rate_id
								) {
									let imgUrl = `${imageUrl}/addon_images/thumb/${add.large_img}`;
									return (
										<div className='column20'>
											<div className='enhancementBox textCenter'>
												<img
													src={
														checkImage(imgUrl)
															? imgUrl
															: defaultEnhanceImage
													}
													alt={add.title}
												/>
												<div className='enhancementBoxText'>
													<h5>{add?.title || ""}</h5>
													<span className='dBlock'>
														{add.amount
															? `${
																	checkoutData
																		?.hotel
																		?.abbrevation
																		? getSymbolFromCurrency(
																				checkoutData
																					.hotel
																					.abbrevation
																		  )
																		: LocalStorage.get(
																				KEYS.SELECTED_CURRENCY
																		  ) &&
																		  getSymbolFromCurrency(
																				LocalStorage.get(
																					KEYS.SELECTED_CURRENCY
																				)
																		  )
															  }${
																	add.addon_type ===
																	"one_off"
																		? Object.values(
																				add.amount
																		  )?.[0]
																				?.rate
																		: Object.values(
																				add.amount
																		  ).reduce(
																				(
																					acc,
																					curr
																				) =>
																					acc +
																					curr.rate,
																				0
																		  )
															  }`
															: "-"}{" "}
														<span className='textCapitalize'>
															{add.addon_type ===
															"one_off"
																? t("once")
																: t(
																		add.rate_calculation
																  )}
														</span>
													</span>
												</div>
												<div className='roomQuantity qtyBoxSizeSmall textCenter'>
													<label
														for={`qtyValue${add.id}${item.room}`}
														className='hideTag'
													>
														{add?.title || ""}
													</label>
													<span
														className={`qtyDecrease minusLargeIcon dInlineBlock posRelative ${
															add.count === 0
																? "minusPlusDisabled"
																: ""
														}`}
														onClick={() =>
															countUpdate(
																item.room,
																add.id,
																false
															)
														}
														onKeyDown={(event) => {
															if (
																event.keyCode ===
																13
															) {
																event.preventDefault();
																countUpdate(
																	item.room,
																	add.id,
																	false
																);
															} else if (
																event.keyCode !==
																9
															) {
																event.preventDefault();
															}
														}}
														onKeyUp={(event) => {
															if (
																event.keyCode !==
																	9 ||
																event.keyCode !==
																	13
															) {
																event.preventDefault();
															} else if (
																event.keyCode ===
																13
															) {
																countUpdate(
																	item.room,
																	add.id,
																	false
																);
															}
														}}
														role={
															add.count === 0
																? ""
																: "button"
														}
														tabIndex={
															add.count === 0
																? "-1"
																: "0"
														}
														aria-label='Decrease Quantity'
													></span>
													<input
														id={`qtyValue${add.id}${item.room}`}
														className='qtyValue dInlineBlock'
														type='text'
														value={add.count || 0}
														name='counter'
														size='5'
														readonly='readonly'
														aria-label={
															add?.title || ""
														}
														tabIndex='-1'
													/>
													<span
														className={`qtyIncrease plusLargeIcon dInlineBlock posRelative 
														    ${
																Object.values(
																	add.amount
																).reduce(
																	(
																		acc,
																		curr
																	) =>
																		acc +
																		curr.inventory,
																	0
																) === add.count
																	? "minusPlusDisabled"
																	: ""
															}`}
														onClick={() =>
															countUpdate(
																item.room,
																add.id,
																true
															)
														}
														onKeyDown={(event) => {
															if (
																event.keyCode ===
																13
															) {
																event.preventDefault();
																countUpdate(
																	item.room,
																	add.id,
																	true
																);
															} else if (
																event.keyCode !==
																9
															) {
																event.preventDefault();
															}
														}}
														onKeyUp={(event) => {
															if (
																event.keyCode !==
																	9 ||
																event.keyCode !==
																	13
															) {
																event.preventDefault();
															} else if (
																event.keyCode ===
																13
															) {
																countUpdate(
																	item.room,
																	add.id,
																	true
																);
															}
														}}
														role={
															Object.values(
																add.amount
															).reduce(
																(acc, curr) =>
																	acc +
																	curr.inventory,
																0
															) === add.count
																? ""
																: "button"
														}
														tabIndex={
															Object.values(
																add.amount
															).reduce(
																(acc, curr) =>
																	acc +
																	curr.inventory,
																0
															) === add.count
																? "-1"
																: "0"
														}
														aria-label='Increase Quantity'
													></span>
												</div>
											</div>
										</div>
									);
								}
							})}
						</div>
					</div>
				) : (
					""
				)}
				<div className='columnWrap additionalReq'>
					<div className='column100'>
						<label
							for={`addiReq${index}`}
							className='dBlock textUppercase'
						>
							{t("additional_requests")}
						</label>
						<textarea
							id={`addiReq${index}`}
							placeholder={t("additional_request_placeholder")}
							{...register(`guest.${index}.additional`, {
								onChange: (event) => {
									setValue(
										`guest.${index}.additional`,
										replaceEmoji(event.target.value)
									);
								},
							})}
						></textarea>
					</div>
				</div>
			</div>
			{errors?.guest?.[index] && (
				<p className='errorMessage'>{t("guest_details_required")}</p>
			)}
		</div>
	);
};
export default GuestInfo;
