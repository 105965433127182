import React from 'react'
import { useTranslation } from "react-i18next"

const Select = ({
    dropdown = [],
    rest,
    isDisabled = false,
    error,
    title = [],
}) => {
  const { t } = useTranslation()
  return (
    <>
      <select {...rest} disabled={isDisabled} title={title}>
        <option value="">{t("title")}</option>
        {dropdown.map(item => <option key={item} value={item}>{item}</option>)}
      </select>
      {error && <p className='errorMessage'>{error}</p>}
    </>
  )
}

export default Select