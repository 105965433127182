import React, { useEffect } from 'react'
import ManageBooking from '../components/manageBooking'

const ManageBookingIndex = (props) => {

  useEffect(() => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 100)
  }, [])

  return (
    <ManageBooking siteConfig={props.siteConfig}/>
  )
}

export default ManageBookingIndex
