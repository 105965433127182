
import React, { useState } from 'react'
import { DEFAULT_LANG, KEYS, NUMBER_REGEX } from '../../utils/constant';
import { alphabeticName, capitalizeStr, getCardType, privacyPolicyLink, replaceEmoji } from '../../utils/utils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input'
// import ReactHtmlParser from "react-html-parser"
import CommonModal from "../common/Modal/commonModal"
import parse from "html-react-parser";
import { useTranslation } from "react-i18next"
import { LocalStorage } from '../../utils/storage';

const PaymentDetails = ({
	handleTabClick,
	stopClose,
	activeTab,
	errors,
	watch,
	register,
	setValue,
	clearErrors,
	setError,
	checkoutData,
	siteConfig,
	ref2
}) => {
	const { t } = useTranslation()
	let [openTerms, setOpenTerms] = useState(false)
	let [openPrivacy, setOpenPrivacy] = useState(false)
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";

	String.prototype.stripSlashes = function () {
		return this.replace(/\\(.)/mg, "$1");
	}

	let formatString = e => {
		var code = e.keyCode
		var allowedKeys = [8]
		if (allowedKeys.indexOf(code) !== -1) {
			return
		}
		e.target.value = e.target.value
			.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
			.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
			.replace(/^([0-1])([3-9])$/g, "0$1/$2")
			.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
			.replace(/^([0]+)\/|[0]+$/g, "0")
			.replace(/[^\d\/]|^[\/]*$/g, "")
			.replace(/\/\//g, "/")
	}

	const scrollToTop = () => {
		handleTabClick(2);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			const targetTagName = e.target.tagName.toLowerCase();
			if (targetTagName !== "input") {
				scrollToTop();
			}
		}
	};

	const paymentErrors = errors?.name_on_card?.message ||
		errors?.card_number?.message ||
		errors?.cardType?.message ||
		errors?.expiryDate?.message ||
		errors?.cvv?.message
	return (
		<div
			className={`checkoutTab ${activeTab === 2 ? "checkoutTabShow" : ""
				}`}
			tabIndex='0'
			onClick={scrollToTop}
			ref={ref2}
			onKeyPress={handleKeyPress}
		>
			<h2 className='textUppercase'>{t("payment_details")}</h2>
			<div className='checkoutTabDetails' onClick={(e) => stopClose(e)}>
				<div className='columnWrap'>
					<div className='column33 marginB25'>
						{/* <label className="hideTag" for="nameonCard" aria-lablledby={t("name_on_card")}>{t("name_on_card")}</label> */}
						<Input
							title={t("name_on_card")}
							id='nameonCard'
							placeholder={t("name_on_card")}
							required
							maxLength='20'
							rest={register("nameOnCard", {
								onChange: (e) => {
									setValue(
										"nameOnCard",
										alphabeticName(e.target.value)
									);
								},
							})}
							error={errors.nameOnCard?.message}
						/>
					</div>
					<div className='column33 marginB25'>
						{/* <label className="hideTag" for="cardNumber" aria-lablledby={t("card_number")}>{t("card_number")}</label> */}
						<Input
							title={t("card_number")}
							id='cardNumber'
							placeholder={t("card_number")}
							required
							maxLength='19'
							inputMode='numeric'
							rest={register("cardNumber", {
								onBlur: (e) => {
									const cardNumber = replaceEmoji(
										e.target.value
									);
									setValue(
										"cardNumber",
										cardNumber.replace(NUMBER_REGEX, "")
									);
									if (cardNumber) {
										let cardNumberType =
											getCardType(cardNumber);
										if (cardNumberType === null) {
											setError("cardType", {
												type: "custom",
												message: t("invalid_card_type"),
											});
											setValue("cardType", "");
										} else {
											setValue(
												"cardType",
												cardNumberType
											);
											clearErrors("cardType");
										}
									} else {
										setValue("cardType", "");
										setError("cardType", {
											type: "custom",
											message: t("card_type_required"),
										});
									}
								},
								onChange: (e) => {
									const cardNumber = replaceEmoji(
										e.target.value
									);
									setValue(
										"cardNumber",
										cardNumber.replace(NUMBER_REGEX, "")
									);
								},
							})}
							error={errors.cardNumber?.message}
						/>
					</div>
					{ }
					<div className='column33 marginB25'>
						{/* <label className="hideTag" for="cardType" aria-lablledby={t("card_type")}>{t("card_type")}</label> */}
						<Input
							title={t("card_type")}
							id='cardType'
							placeholder={t("card_type")}
							required
							isDisabled
							rest={register("cardType")}
							error={errors.cardType?.message}
						/>
					</div>
					<div className='column33 marginB25 mobileTwoColumn'>
						{/* <label className="hideTag" for="expiryDate" aria-lablledby='Expiry Date'>{t("expiry_date")}</label> */}
						<Input
							title='Expiry Date'
							id='expiryDate'
							placeholder={t("expiry_date")}
							required
							maxLength='5'
							inputMode='numeric'
							rest={register("expiryDate")}
							onKeyUp={(e) => formatString(e)}
							error={errors.expiryDate?.message}
						/>
					</div>
					<div className='column33 marginB25 mobileTwoColumn'>
						{/* <label className="hideTag" for="password" aria-lablledby={t("cvv")}>{t("cvv")}</label> */}
						<Input
							title={t("cvv")}
							type='password'
							id='password'
							placeholder={t("cvv")}
							inputMode='numeric'
							required
							maxLength='4'
							rest={register("cvv", {
								onChange: (event) => {
									setValue(
										"cvv",
										event.target.value.replace(
											NUMBER_REGEX,
											""
										)
									);
								},
							})}
							error={errors.cvv?.message}
						/>
					</div>
					<div className='column100 paymentDetailContent paddingT25'>
						<p className='textUppercase'>{t("policies")}</p>
						{checkoutData?.rooms?.map((pol, index) => {
							let policyMsg = "";
							if (pol?.policy?.samemodifycancel) {
								policyMsg += `<p><strong>${t(
									"amendment_policy"
								)}</strong>${pol?.policy?.cancellation?.summary
									}</p>`;
							} else {
								policyMsg += `<p><strong>${t(
									"modification_policy"
								)}</strong>${pol?.policy?.amendment?.summary
									}</p>`;
								policyMsg += `<p><strong>${t(
									"cancellation_policy"
								)}</strong>${pol?.policy?.cancellation?.summary
									}</p>`;
							}
							if (pol?.policy?.payment.summary) {
								policyMsg += `<p><strong>${t(
									"payment_policy"
								)}</strong>${pol?.policy?.payment.summary}</p>`;
							}
							return (
								<>
									<p>
										<strong style={{ color: "#000" }}>
											{/* {t("room_name")} / {t("rate_name")} */}
											{t("policy_room")} {index + 1}
										</strong>
										{capitalizeStr(pol.room_type_name)} |{" "}
										{capitalizeStr(pol.rate_name)?.replace(/\\'/g, "'")}
									</p>
									{/* {ReactHtmlParser(policyMsg)} */}
									<div
										dangerouslySetInnerHTML={{
											__html: policyMsg,
										}}
									/>
								</>
							);
						})}
						<div className='termPolicyCheckbox' id='termsdata'>
							<div className='marginB10 customCheckBox'>
								<Checkbox
									id='termsCheckbox'
									text={[
										t("terms_of_booking_1"),
										" ",
										<a
											href='javascript:void(0);'
											onClick={() => setOpenTerms(true)}
										>
											{t("terms_of_booking_2")}
										</a>, <>*</>
									]}
									isChecked={watch("termsCheckbox")}
									rest={register("termsCheckbox")}
									error={errors.termsCheckbox?.message}
								/>
								<CommonModal
									popupClass='checkoutPrivacyPolicy commonModalPopup termsncondition'
									open={openTerms}
									setOpen={setOpenTerms}
									data={LocalStorage.get(KEYS.I18_NEXT_LNG) !== DEFAULT_LANG ?
										siteConfig?.site_title_lang?.[LocalStorage.get(KEYS.I18_NEXT_LNG)]?.term_and_conditions &&
										parse(siteConfig?.site_title_lang?.[LocalStorage.get(KEYS.I18_NEXT_LNG)]?.term_and_conditions?.stripSlashes()) : parse(siteConfig?.term_and_conditions.stripSlashes())}
									title={t("terms_and_conditions")}
								/>
							</div>
							<div className='marginB10 customCheckBox'>
								<Checkbox
									id='policyCheckbox'
									text={[
										t("privacy_policy_1"),
										" ",
										<a
											href='javascript:void(0);'
											onClick={() =>
												window.open(
													privacyPolicyLink(
														siteConfig?.site_id,
														language
													),
													"_blank"
												)
											}
										>
											{t("privacy_policy_2")}
										</a>, <>*</>
									]}
									isChecked={watch("policyCheckbox")}
									rest={register("policyCheckbox")}
									error={errors.policyCheckbox?.message}
								/>
								<CommonModal
									popupClass='checkoutPrivacyPolicy commonModalPopup'
									open={openPrivacy}
									setOpen={setOpenPrivacy}
									data={
										siteConfig?.privacy_policy &&
										parse(
											siteConfig?.privacy_policy?.stripSlashes()
										)
									}
									title={t("privacy_policy")}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{activeTab !== 2 ? (
				paymentErrors ||
					errors?.termsCheckbox?.message ||
					errors?.policyCheckbox?.message ? (
					paymentErrors &&
						errors?.termsCheckbox?.message &&
						errors?.policyCheckbox?.message ? (
						<p className='errorMessage'>
							{t("payment_privacy_terms_required")}
						</p>
					) : paymentErrors && errors?.termsCheckbox?.message ? (
						<p className='errorMessage'>
							{t("payment_terms_required")}
						</p>
					) : paymentErrors && errors?.policyCheckbox?.message ? (
						<p className='errorMessage'>
							{t("payment_privacy_required")}
						</p>
					) : paymentErrors ? (
						<p className='errorMessage'>
							{t("payment_details_required")}
						</p>
					) : errors?.termsCheckbox?.message &&
						errors?.policyCheckbox?.message ? (
						<p className='errorMessage'>
							{t("terms_privacy_required")}
						</p>
					) : errors?.termsCheckbox?.message ? (
						<p className='errorMessage'>
							{t("terms_checkbox_required")}
						</p>
					) : (
						<p className='errorMessage'>
							{t("policy_checkbox_required")}
						</p>
					)
				) : (
					""
				)
			) : (
				""
			)}
		</div>
	);
}

export default PaymentDetails