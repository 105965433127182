export const route = {
  roomList: "/",
  roomListIndex: "/index/:id",
  roomDetail: "/roomdetail",
  checkout: "/checkout",
  confirmation: "/confirmation",
  manageBookings: "/managebooking",
  cancelConfirmation: "/cancelconfirmation",
  declineBooking: "/declinebooking",
  fourZeroFour: "/404",
  maintenance: "/maintenance",
  internalError: "/errorpage",
};