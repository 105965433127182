import { useState, useEffect, useContext } from "react";
import { fetchMetaTagsData } from "../services/seoTags";
import { getMetaTagsRequest } from "../utils/requestData";
import { useErrorBoundary } from "react-error-boundary";

export const useSeoMetaHook = ({ authToken, currentPage }) => {
	// const { showBoundary } = useErrorBoundary();
	const [loading, setLoading] = useState(true);
	const [metaTitle, setMetaTitle] = useState("");
	const [metaDescription, setMetaDescription] = useState("");
	const [metaKeywords, setMetaKeywords] = useState("");
	const [hotelName, setHotelName] = useState("");

	const getMetaTags = async () => {
		let requestData = getMetaTagsRequest({
			currentPage: currentPage,
		});

		try {
			await setLoading(true);
			await fetchMetaTagsData(requestData, authToken).then((res) => {
				setMetaTitle(res.data.site_metatitle);
				setMetaDescription(res.data.site_metadescription);
				setMetaKeywords(res.data.site_metakeywords);
				setHotelName(res.data.eng_hotel_name);
			});
		} catch (error) {
			// Show error boundary
			// showBoundary(error);
		} finally {
			setLoading(false);
		}
	};

	// send API call, if 'currency' OR 'language' OR 'authToken' (from context data) OR 'searchPanelData' is changed
	useEffect(() => {
		getMetaTags();
	}, []);

	useEffect(() => {
		const defaultTitle = "";
		const defaultDesc = "";

		document.title = metaTitle || defaultTitle;
		document
			.querySelector("meta[name='description']")
			.setAttribute("content", metaDescription || defaultDesc);
	}, [metaTitle, metaDescription]);

	return {
		loading,
		metaTitle,
		metaDescription,
		metaKeywords,
		hotelName,
	};
};
