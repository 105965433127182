import { imageUrl } from "../../utils/constant";
import { useTranslation } from "react-i18next";

export default function DiscoverHotel({ addon_list, confirmationData }) {
  const { t } = useTranslation();
  return (
    
    <div className='discoverSection'>
      {addon_list ?
      <h2 className='customTitle paddingB30 m0'>
        {t("discover")} {confirmationData?.hotel_details.hotel_name}
      </h2>
      : <></>}
      <div className='columnWrap'>
        {addon_list && addon_list.map(function (item, index) {
          const thumbImageURL = `${imageUrl}/addon_images/thumb/${item.thumb_img}`;

          return (
            <div key={index} className='column25 marginB30'>
              <div className='imageColumn textCenter'>
                <figure className='m0'>
                  {item.img_link ? (
                    <a
                      className='dBlock'
                      href={item.img_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={thumbImageURL}
                        alt={`${item.title}`}
                      />
                    </a>
                  ) : (
                    <img
                      src={thumbImageURL}
                      alt={`${item.title}`}
                    />
                  )}

                  <figcaption className='textUppercase'>
                    <a
                      className='dBlock'
                      href={item.img_link ? item.img_link : ""}
                      target='_blank'
                      rel='noreferrer'
                    >
                    {item.title}
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
