import React, { useContext } from "react";
import CompareRooms from "./compareRooms";
import AppContext from "../../utils/appContext";
import { imageUrl, KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import { capitalizeStr, roomDescriptionDisplay } from "../../utils/utils";
import { useTranslation } from "react-i18next"
import './compareRoomsFooter.scss'

const CompareRoomsFooter = () => {
  const context = useContext(AppContext);
  const { t } = useTranslation()
  //let compareItems = JSON.parse(context.compareRoomsData);
  let compareItems = JSON.parse(SessionStorage.get(KEYS.COMPARE_ROOM_ITEMS));
  const details = keyHighlight => {
    const descData = roomDescriptionDisplay(keyHighlight);
    const bedData = descData?.[0];
    const sleepData = descData?.[1];
    const sizeData = descData?.[3];
    const freetext = descData?.[4];
    let displayfeature = 1;

	if(bedData){ displayfeature++};
	if(sleepData){ displayfeature++};
	if(sizeData){ displayfeature++};
    return (
      <ul class="listStyled">
        {bedData && <li>{bedData}</li>}
        {sleepData && <li>{sleepData}</li>}
        {sizeData && <li>{sizeData}</li>}
        {freetext && displayfeature < 4 && <li>{freetext}</li>}
        
      </ul>
    );
  };
  let path = `${imageUrl}/hotel_images/${SessionStorage.get(KEYS.HOTEL_ID)}`;

  const compareRoomRemove = roomDetail => {
    if (compareItems.length > 0) {
      let isItemExist = compareItems.findIndex(
        filterItem => filterItem.room_id === roomDetail.room_id
      );
      if (isItemExist >= 0) {
        compareItems.splice(isItemExist, 1);
      }

      if (compareItems.length <= 0) {
        sessionStorage.removeItem("compareRoomItems");
      }
      sessionStorage.setItem("compareRoomItems", JSON.stringify(compareItems));
      context.setCompareRoomsData(JSON.stringify(compareItems));
    }

  };
  return (
    <>
      {compareItems.length > 0 ? (
        <>
          <CompareRooms fromfooter={true} />
          <div className="compareRoomsListWrap marginT40" id="compareRoomsListWrap">
            <h2 className="customTitle paddingB25 m0" id={document?.getElementById('userAlertNotification')?.getAttribute('data-ispopupvisible')?"compareTitlePop":"compareTitle"}>{t("compare_room")}</h2>
            <div className="compareRoomsList">
              <div className="columnWrap">
                {compareItems.length > 0 &&
                  compareItems?.map((item, index) => {
                    return (
                      <div className="column25">
                        <div className="compareRoomsListBox">
                          <div className="compareRoomsListImg posRelative">
                            <img
                              src={`${path}/${item.room_image}`}
                              alt={`${item.room_name}`}
                              className="w100"
                            />
                            <span className="addinCompareList addRoomComp" onClick={() => compareRoomRemove(item)}></span>
                          </div>
                          <div className="compareRoomsListContent">
                            <h3 className="textUppercase m0">{item.room_name && capitalizeStr(item.room_name)}</h3>

                            {item.keyhighlight && details(item.keyhighlight)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      ) : <></>}
    </>
  );
};

export default CompareRoomsFooter;
