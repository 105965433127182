import React, { useEffect, useContext,useMemo } from 'react'
import { useState } from 'react';
import { KEYS } from '../../utils/constant';
import { LocalStorage, SessionStorage } from '../../utils/storage';
import { capitalizeStr, currencyDisplay } from '../../utils/utils';
import RoomListDetails from './roomListDetails';
import getSymbolFromCurrency from "currency-symbol-map"
import ViewDescription from '../room-details/viewDescription';
import ViewMoreLess from '../room-details/viewMoreLess';
import { useTranslation } from "react-i18next"
import AppContext from "../../utils/appContext";
import moment from 'moment';
import {SITE_ID_LIST_HASH} from "../../utils/utils";

const OfferList = ({
    item,
    siteConfig,
    index,
    hotelData
}) => {
    const { t } = useTranslation()
    const [showMore, setShowMore] = useState(false)
    const [viewRooms, setViewRooms] = useState(index === 0 ? true : false)
    const context = useContext(AppContext)
    let currentSelectedRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
    let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
    let occKey = "";
    let checkInDate = SessionStorage.get(KEYS.CHECK_IN_DATE)
    let checkoutDate = SessionStorage.get(KEYS.CHECKOUT_DATE)
    let checkIn = moment(checkInDate, "YYYY-MM-DD");
    let checkout = moment(checkoutDate, "YYYY-MM-DD");
    let totalNights = checkout.diff(checkIn, "days");
  if(item){
    let singleRoom = cartItems.find(item => item.room == currentSelectedRoom)
    if(singleRoom){
      occKey = singleRoom.adults+ "-" + singleRoom.childrens.length + "i0";
    }
  }
  const [availableroominfo, setavailableroominfo] = useState([]);
  
  useEffect(()=> {
    if(context.hotelData.room_occupancy !== undefined && context.hotelData.room_occupancy[occKey]){
      setavailableroominfo(context.hotelData.room_occupancy[occKey].room);
    }
  }, [context.hotelData])
  
  let minimumSum = Number.MAX_SAFE_INTEGER;
  item?.rooms?.forEach(obj => {
    const datewiserates = obj.price.datewiserates || {};
    const nestedValues = datewiserates[occKey] || {};
    const rateSum = Object.values(nestedValues).reduce((acc, val) => acc + val, 0);
    if (rateSum < minimumSum) {
      minimumSum = rateSum / totalNights;
    }
  });

  return (
    <div className={`offerListWrap ${viewRooms && "showRoomRates"}`}>
        <div
          className={
            showMore === true
              ? "column100 roomRatesInfo marginT30 arrowUp"
              : "column100 roomRatesInfo marginT30"
          }
        >
            <div className="columnWrap">
                <div className="column100">
                    <h3 onClick={() => setShowMore(!showMore)}>
                      {item?.rate_name ? capitalizeStr(item.rate_name)?.replace(/\\'/g, "'") : "" }
                    </h3>

                    <div className='roomPriceMobile'>
                      <span className="roomPrice dInlineBlock">
                          {LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) ? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY)+" "}
                          {currencyDisplay(minimumSum)}
                      </span>
                      {t("average_nightly_rate")}
                      {hotelData?.showinclusivetext === 1 ? ` (${t("inclusive_of_vat_sm")})` : ` (${t("excl_taxes_and_fees")})`}
                    </div>
                </div>
                <div className="column40">
                  <ViewDescription
                    showMore={showMore}
                    rateShortDesc={item?.rate_short_desc}
                  />
                </div>
                <div className="column30">
                    <span className="roomPrice dBlock">
                        {LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) ? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY)+" "}
                        {currencyDisplay(minimumSum)}
                    </span>
                    {t("average_nightly_rate")}
                    {" "}
                    <span>
                    ({SITE_ID_LIST_HASH.mbh?.includes(siteConfig?.site_id?.toString())?t("mbh_total_price_helper_text"):hotelData?.showinclusivetext === 1 ? t("inclusive_of_vat_sm") : t("excl_taxes_and_fees")})
                    </span>
                </div>
                <div className="column30 textRight">                                
                    <a
                      className="customBtn customBtnDark viewRoomBtn"
                      href="javascript:void(0);"
                      onClick={() => setViewRooms(!viewRooms)}
                    >
                      {viewRooms ? t("hide_rooms") : t("view_rooms")}
                    </a>
                </div>
                <div className="column100">
                    <div className="roomViewLinks">
                      <ViewMoreLess
                        showMore={showMore}
                        setShowMore={setShowMore}
                        rateShortDesc={item?.rate_short_desc}
                      />
                    </div>
                </div>
            </div>
        </div>
        {viewRooms ? 
            item?.rooms.filter(({room_id,price})=>JSON.parse(window.sessionStorage.getItem("roomData")).filter(({room_info})=>room_info.rate_id===price.rate_id&&room_info.room_id===room_id).length<price.roomcount)?.map(roomDetail => 
              (availableroominfo.indexOf(roomDetail.room_id) != -1) || roomDetail.sale_type == 0 ? (
            <RoomListDetails item={roomDetail} siteConfig={siteConfig} hotelData={hotelData} showAddToStay />
            ) : <></>
            )
           : ""}
    </div>
  )
}

export default OfferList