import React, { useState, useRef } from "react";
import { capitalizeStr, currencyDisplay } from "../../../utils/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../includes/spinner/Spinner";
import ComponentToPrint from "./componentToPrint";
import { useReactToPrint } from "react-to-print";
import ConfirmPrintPopupRoomBasedStayDetails from "./ConfirmPrintPopupRoomBasedStayDetails";
import Html2Pdf from 'html2pdf.js/dist/html2pdf.min';

export default function PrintPreviewPopup({
	authToken,
	siteConfig,
	ref,
	confirmationData,
	currencySymbol,
	hotelCheckInTime,
	hotelCheckOutTime,
	setPrintModalOpen,
}) {
	const [loading, setLoading] = useState(false);
	const [issPrinting, setIsPrinting] = useState(false);
	const { t } = useTranslation();
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onBeforePrint: () => setIsPrinting(true),
		onAfterPrint: () => setIsPrinting(false),
	});

	const handleDownload = useReactToPrint({
		onPrintError: (error) => console.log(error),
		content: () => componentRef.current,
		removeAfterPrint: true,
		print: async (printIframe) => {
			const document = printIframe.contentDocument;
			if (document) {
				const html = document.getElementsByTagName("html")[0];
				const exporter = new Html2Pdf(html);
				await exporter.getPdf(true);
			}
		},
	});

	// Change Day & Month Sting dynamically for moment lib
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	moment.locale(language);

	let dateFormat = language === "fr" ? "ddd DD MMM YYYY" : "ddd, DD MMM YYYY";

	let arrivalDate = confirmationData?.date_in
		? moment(confirmationData?.date_in).format(`${dateFormat}`)
		: "-";
	let departureDate = confirmationData?.date_out
		? moment(confirmationData?.date_out).format(`${dateFormat}`)
		: "- ";
	let stayTotalNights =
		moment(confirmationData?.date_out).diff(
			moment(confirmationData?.date_in),
			"days"
		) || "-";
	let totalNightsWithStr =
		stayTotalNights <= 1
			? stayTotalNights + " " + capitalizeStr(t("night"))
			: stayTotalNights + " " + t("nights");
	let overallTotalCost = confirmationData?.total_cost
		? currencySymbol + currencyDisplay(confirmationData?.total_cost)
		: "-";

	function iOS() {
		return (
			[
				"iPad Simulator",
				"iPhone Simulator",
				"iPod Simulator",
				"iPad",
				"iPhone",
				"iPod",
			].includes(navigator.platform) ||
			// iPad on iOS 13 detection
			(navigator.userAgent.includes("Mac") && "ontouchend" in document)
		);
	}

	let isIOS = iOS();
	var isChromium = window.chrome;
	var winNav = window.navigator;
	var vendorName = winNav.vendor;
	var isOpera = typeof window.opr !== "undefined";
	var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
	var isIOSChrome = winNav.userAgent.match("CriOS");

	let ischromeBrowser = false;

	if (isIOSChrome) {
		// is Google Chrome on IOS
		ischromeBrowser = true;
	} else if (
		isChromium !== null &&
		typeof isChromium !== "undefined" &&
		vendorName === "Google Inc." &&
		isOpera === false &&
		isIEedge === false
	) {
		ischromeBrowser = true;
		// is Google Chrome
	}

	return (
		<>
			{loading && <Spinner />}

			<section className='bookingInfoColumn'>
				<div className='onlyMessageTop PageTitleWrap3 textCenter'>
					<span className='dBlock textUppercase'>
						{confirmationData?.hotel_details?.hotel_name || "-"}
					</span>
					<h2 className='m0 paddingT10'>{t("your_reservation")}</h2>
				</div>
				<ul>
					<li key='reservation-details'>
						<div className='columnWrap'>
							<div className='column100'>
								<span className='dBlock textUppercase'>
									{t("stay_overview")}
								</span>
							</div>
						</div>
						<div className='bookingDetailInfoWrap'>
							{confirmationData && (
								<>
									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("guest_name")}</span>
										</div>
										<div className='column50'>
											{confirmationData?.customer_name ||
												"-"}
										</div>
									</div>

									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("email_address")}</span>
										</div>
										<div className='column50'>
											{confirmationData?.email || "-"}
										</div>
									</div>

									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("arrival_date")}</span>
										</div>
										<div className='column50'>
											{arrivalDate}
										</div>
									</div>

									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("departure_date")}</span>
										</div>
										<div className='column50'>
											{departureDate}
										</div>
									</div>

									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("total_nights")}</span>
										</div>
										<div className='column50'>
											{totalNightsWithStr}
										</div>
									</div>

									<div className='columnWrap'>
										<div className='column50'>
											<span>{t("grand_total")}</span>
										</div>
										<div className='column50'>
											{overallTotalCost}
										</div>
									</div>
								</>
							)}
						</div>
					</li>

					<ConfirmPrintPopupRoomBasedStayDetails
						room_stay={confirmationData?.room_stay}
						currencySymbol={currencySymbol}
						confirmationData={confirmationData}
						hotelCheckInTime={hotelCheckInTime}
						hotelCheckOutTime={hotelCheckOutTime}
					/>

					<li className='bookingInfoTotal' key='total'>
						<div className='columnWrap'>
							<div className='column50'>
								<span className='dBlock textUppercase'>
									{t("booking_Total")}
								</span>
							</div>
							<div className='column50 booklastColumn50'>
								{overallTotalCost}
							</div>
						</div>
					</li>

					<li className='ConfirmationPrint' key='print'>
						<div className='columnWrap'>
							<div className='column100'>
								<button
									type='button'
									className='customBtn customBtnDark w100'
									onClick={(event) => {
										setPrintModalOpen(false); // close the print modal
										isIOS && ischromeBrowser
											? handleDownload()
											: handlePrint(); // open the print preview
									}}
								>
									{t("print")}
								</button>
								<ComponentToPrint
									ref={componentRef}
									currencySymbol={currencySymbol}
									hotelCheckInTime={hotelCheckInTime}
									hotelCheckOutTime={hotelCheckOutTime}
									confirmationData={confirmationData}
								/>
							</div>
						</div>
					</li>
				</ul>
			</section>
		</>
	);
}
