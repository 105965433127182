import React, { useContext } from "react";
import AppContext from "../../utils/appContext";
import { imageUrl, KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import { capitalizeStr } from "../../utils/utils";
import { useTranslation } from "react-i18next"

const CompareRooms = (props) => {
  const context = useContext(AppContext);
  const { t } = useTranslation()
  //let compareItems = JSON.parse(context.compareRoomsData);
  let compareItems = JSON.parse(SessionStorage.get(KEYS.COMPARE_ROOM_ITEMS));

  const compareRoomRemove = roomDetail => {
    if (compareItems.length > 0) {
      let isItemExist = compareItems.findIndex(
        filterItem => filterItem.room_id === roomDetail.room_id
      );
      if (isItemExist >= 0) {
        compareItems.splice(isItemExist, 1);
      }
  
      if (compareItems.length <= 0) {
        sessionStorage.removeItem("compareRoomItems");
      }
      sessionStorage.setItem("compareRoomItems", JSON.stringify(compareItems));
      context.setCompareRoomsData(JSON.stringify(compareItems));
    }

  };

  const navigateToCompareList = () => {
    let element= document.getElementById(document?.getElementById('userAlertNotification')?.getAttribute('data-ispopupvisible')?"compareTitlePop":"compareTitle");			
    element?.scrollIntoView();
  }
  

  return (
    <div className="compareRoomsBar marginT30" id={`${props?.fromfooter === true ? 'compareroombarfooter' : ''}`}>
      {compareItems.length > 0 ? (
         
      <div className="columnWrap alignItemsCenter">
        <div className="column100">
          <div className="roomComparisonName">
            {compareItems.length > 0 &&
              compareItems.map((item, index) => {
                return (
                <span className="dInlineBlock" role="button">
                  {item?.room_name ? capitalizeStr(item.room_name) : ""}
                  <em tabIndex='0' aria-label={`Remove Room ${item?.room_name ? capitalizeStr(item.room_name) : ""}`} onClick={() => compareRoomRemove(item)}>&times;</em>
                </span>
                );
              })}
          </div>
        </div>
        <div className="column100 borderTopComapare"></div>
        <div className="column50">
          <label>{t("select_four_rooms")}</label>
        </div>
        <div className="column50">
          <div className="roomComparisonBtn textRight">
            <a className="customBtn customBtnDark" onClick={navigateToCompareList}>
              {t("compare_room")}
            </a>
          </div>
        </div>
      </div>
         
      ) : (
        <div className="columnWrap alignItemsCenter">
          <div className="column100 noRoomSelect">
            {t("compare_filter")}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompareRooms;
