import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchRoomListData = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.hotelList.method, apiList.hotelList.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}

export const fetchOfferListData = (data, authToken, context) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.offerList.method, apiList.offerList.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}

export const fetchAvailabilityData = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.getcrosssellsite.method, apiList.getcrosssellsite.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}