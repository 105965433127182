import React, {useEffect} from "react";
import ConfirmationComponent from "../components/confirmation";

const Confirmation = ({
  currencyList,
  authToken,
  searchPanelData,
  siteConfig,
  ip,
}) => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <ConfirmationComponent
      currencyList={currencyList}
      authToken={authToken}
      searchPanelData={searchPanelData}
      siteConfig={siteConfig}
      ip={ip}
    />
  );
};

export default Confirmation;
