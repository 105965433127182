/**
 * @description Class with functions with post, put, get, delete method api call
 */
import Axios from "axios";
import UrlConstants from "./UrlConstants";
import UserAgent from "user-agents";

class APIUtil {
  /**
   *
   * @description function to call external api with url
   * @param {*} url - API URL
   * @returns error.response || "status":"404".
   * @memberof APIUtil
   */
  checAPIData() {
    // Check API Data
  }

  generateToken() {
    
    let myip = this.getIP();
    let random = (Math.random() + 1).toString(36).substring(0, 12);

    let currencntDate = Math.floor(Date.now() / 1000);

    const fixedChars = "80D_iVz9qVORz!S";

    let siteToken = myip + "" + currencntDate + "" + fixedChars + "" + random;

    return siteToken;
  }

  getDevice() {
    const userAgent = new UserAgent();
    return userAgent;
  }
  getIP() {
    
   return 0;
  }
  externalAPI(url, auth) {}
  /**
   *
   * @description action to call get api with/without auth token
   * @param {*} url - API URL
   * @param {*} auth - true/false for auth token pass or not (Optional)
   * @memberof APIUtil
   */
  getMethod(url) {
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return Axios({
      method: "get",
      url: url,
      headers: headersSet
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }

  postMethodwithoutauth(url, data) {
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return Axios({
      method: "post",
      url: url,
      headers: headersSet,
      data: data
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }

  postMethod(url, data, token = "") {
    let siteToken = this.generateToken();
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
      accessCode: `${siteToken}`
    };
    let agentInfo = this.getDevice();
    // console.log(agentInfo.userAgent, "agentInfo.userAgent");
    //agentInfo = JSON.stringify(agentInfo.data, null, 2);
    data.ip = data?.ip || 0;
    data.user_ip = data?.ip || 0;
    data.user_session_id = "";
    data.http_user_agent = agentInfo.userAgent;
    data.mobile_device = false;
    data.user_browser = agentInfo.userAgent;
    data.user_os = agentInfo.userAgent;
    return Axios({
      method: "post",
      url: url,
      headers: headersSet,
      data: data
    })
      .then(response => response)
      .catch(error => {
        if (error.response) {
          if (
            error.response.data == "Unauthorized." &&
            error.response.status == 401
          ) {
            throw { renewToken: true };
          }
        }
        return { status: "404" };
      });
  }

  postMethodWithAdditionalHeader(headers, url, data) {
    var headersSet = headers;

    return Axios({
      method: "post",
      url: url,
      headers: headersSet,
      data: data
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }

  locationReload() {
    window.location.reload();
  }
  /**
   *
   * @description action to call put api with/without auth token and post data
   * @param {*} url - API URL
   * @param {*} data - Post data object
   * @param {*} auth - true/false for auth token pass or not (Optional)
   * @memberof APIUtil
   */
  putMethod(url, data) {
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return Axios({
      method: "PUT",
      url: url,
      headers: headersSet,
      data: data
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }
  /**
   *
   * @description action to call delete api with/without auth token and post data
   * @param {*} url - API URL
   * @param {*} auth - true/false for auth token pass or not (Optional)
   * @memberof APIUtil
   */
  deleteMethod(url, data) {
    const apicheck = this.checAPIData();
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return Axios({
      method: "DELETE",
      url: url,
      data: data ? data : null,
      headers: headersSet
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }

  /**
   *
   * @description action to call post api with/without auth token and post data with form data
   * @param {*} url - API URL
   * @param {*} data - Form data object
   * @param {*} auth - true/false for auth token pass or not (Optional)
   * @memberof APIUtil
   */
  postFormDataMethod(url, data) {
    const apicheck = this.checAPIData();
    var headersSet = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data"
    };

    return Axios({
      method: "post",
      url: url,
      headers: headersSet,
      data: data
    })
      .then(response => response)
      .catch(error => {
        return { status: "404" };
      });
  }
}
export default new APIUtil();
