import { useContext, useEffect, useState } from "react";
import AppContext from "../utils/appContext";
import { getCountryRequest } from "../utils/requestData";
import { fetchCountry } from "../services/country";
import { useErrorBoundary } from "react-error-boundary";
import { DEFAULT_COUNTRY_CODES } from "../utils/constant";
import { useAuth } from "./useAuth";

export const useCountry = ({ countryId = "" }) => {
	const context = useContext(AppContext);
	const token = context.authToken;
	const { showBoundary } = useErrorBoundary();
	const [countryData, setCountryData] = useState(null);
	const [phoneCode, setPhoneCode] = useState(null);

	const getCountryList = async () => {
		let requestData = getCountryRequest();
		try {
			await fetchCountry(requestData, token).then((res) => {
				if (res.data.dial_code?.length) {
					const data = res.data.dial_code;
					const modifiedData = [];					
					for (const countryCode of DEFAULT_COUNTRY_CODES) {
					  const countryObject = data.find((obj) => obj.cc_iso === countryCode);
					  if (countryObject) {
						modifiedData.push(countryObject);
					  }
					}
					const remainingObjects = data.filter((obj) => !DEFAULT_COUNTRY_CODES.includes(obj.cc_iso));
					const finalData = [...modifiedData, ...remainingObjects];
					setPhoneCode(finalData);
				}
				if (res.data.all_countries?.length) {
					const allCountries = res.data.all_countries;
					const modifiedCountriesList = [];
					for (const countryCode of DEFAULT_COUNTRY_CODES) {
					  const countryObj = allCountries.find((obj) => obj.cc_iso === countryCode);
					  if (countryObj) {
						modifiedCountriesList.push(countryObj);
					  }
					}
					const remainingCountriesObj = allCountries.filter((obj) => !DEFAULT_COUNTRY_CODES.includes(obj.cc_iso));
					const finalCountriesList = [...modifiedCountriesList, ...remainingCountriesObj];
					setCountryData(finalCountriesList);
				}
			});
		} catch (error) {
			if (!error.renewToken) {
				showBoundary(error);		
			}
		}
	};

	useEffect(() => {
		if (context.authToken && context.configData) {
			getCountryList();
		}
	}, [context.authToken, context.configData]);

	return { countryData, phoneCode };
};
