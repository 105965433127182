import { yupResolver } from "@hookform/resolvers/yup"
import React, { useContext, useState } from 'react'
import { useManageBooking } from "../../hooks/useManageBooking";
import Input from '../common/Input';
import { useTranslation } from "react-i18next"
import { NUMBER_REGEX } from '../../utils/constant';
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { BOOKING_STATUS, currencyDisplay } from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import AppContext from "../../utils/appContext";
import ModifyBookingModal from "../common/Modal/modifyBookingModal";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/route";
import Loader from "../common/Loader";
import moment from "moment";
import 'moment/locale/fr'
import momentTimeZone from 'moment-timezone';

const { object, string } = yup

const defaultValues = {
	reservation_id: undefined,
	email: undefined,
}

const ManageBooking = (props) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	let context = useContext(AppContext);
	const reservationSchema = object({
		reservation_id: string().required(t("reservation_number_required")),
		email: string().required(t("email_required")).email(t("invalid_email")),
	});

	const {
		register,
		handleSubmit,
		setValue,
		clearErrors,
		trigger,
		reset,
		formState: { errors = {} },
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues,
		shouldFocusError: true,
		resolver: yupResolver(reservationSchema),
	});

	const { loading, bookingData, invalidMsg, getYourReservation } =
		useManageBooking({
			clearErrors,
			reset,
		});

	const onKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSubmit(getYourReservation)();
		}
	};

	const roomId =
		bookingData?.res_detail && Object.keys(bookingData.res_detail)?.[0];
	const totalNights =
		bookingData?.date_out &&
		bookingData?.date_in &&
		moment(bookingData?.date_out).diff(
			moment(bookingData?.date_in),
			"days"
		);
	
	const timeZone = bookingData?.hotel_detail?.timezone;	
	const parsedDate = bookingData?.date_in && momentTimeZone.tz(bookingData?.date_in, 'YYYY-MM-DD', timeZone);
	const todayInUserTimeZone = timeZone && momentTimeZone().tz(timeZone).startOf('day');
	const isSameOrAfter = parsedDate && parsedDate.isSameOrAfter(todayInUserTimeZone, 'day');
	const dateCheck = isSameOrAfter ? true : false;

	const pol = bookingData?.res_detail[roomId]?.policy;
	let policyMsg = "";

	if (pol) {
		if (pol?.samemodifycancel === true) {
			policyMsg += `<h3>${t("amendment_policy")}</h3><p>${
				pol.cancellation.summary
			}</p>`;
		} else {
			policyMsg += `<h3>${t("modification_policy")}</h3><p>${
				pol.amendment.summary
			}</p>`;
			policyMsg += `<h3>${t("cancellation_policy")}</h3><p>${
				pol.cancellation.summary
			}</p>`;
		}
		if (pol?.payment?.summary) {
			policyMsg += `<h3>${t("payment_policy")}</h3><p>${
				pol.payment.summary
			}</p>`;
		}
	}

	let navigate = useNavigate();
	const cancelStay = () => {
		navigate(route.cancelConfirmation, {
			state: {
				bookingData: bookingData,
			},
		});
	};

	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	moment.locale(language);

	let dateFormat = language === "fr" ? "ddd DD MMM YYYY" : "ddd, DD MMM YYYY";

	return (
		<>
			{loading && <Loader />}
			<main
				className='mainContent viewReservationPage'
				id='viewReservationPageId'
			>
				{context?.isBookingData ? (
					<>
						<section className='onlyMessageTopWrap paddingTB100 textCenter'>
							<div className='columnContainer'>
								<div className='onlyMessageTop PageTitleWrap3'>
									<span className='dBlock textUppercase'>
										{bookingData?.hotel_detail
											?.hotel_name || ""}
									</span>
									<h1 className='m0 paddingT15'>
										{t("your_reservation")}
									</h1>
								</div>
							</div>
						</section>
						<section className='bookingInfoColumn paddingTB60'>
							<div className='columnContainer'>
								<div className='columnWrap justifyContentCenter'>
									<div className='column40'>
										<ul>
											<li>
												<div className='columnWrap'>
													<div className='column100'>
														<span className='dBlock textUppercase'>
															{t(
																"reservation_details"
															)}
														</span>
													</div>
												</div>
												<div className='bookingDetailInfoWrap'>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t(
																	"reservation_number"
																)}
															</span>
														</div>
														<div className='column50'>
															{bookingData?.ConfirmationNumber ||
																"-"}
														</div>
													</div>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("status")}
															</span>
														</div>
														<div className='column50'>
															{
																BOOKING_STATUS[
																	bookingData
																		?.status
																]
															}
														</div>
													</div>
													
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("label_total_nights")}
															</span>
														</div>
														<div className='column50'>
															{totalNights}{" "}
															{totalNights
																? totalNights >
																  1
																	? t(
																			"nights"
																	  )
																	: t(
																			"cap_night"
																	  )
																: "-"}
														</div>
													</div>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t(
																	"nightly_rate"
																)}
															</span>
														</div>
														<div className='column50'>
															{bookingData
																?.res_detail?.[
																roomId
															]
																?.price_per_room_without_tax ? (
																<>
																	{getSymbolFromCurrency(
																		bookingData.currsign
																	)}
																	{currencyDisplay(
																		bookingData
																			?.res_detail?.[
																			roomId
																		]
																			?.price_per_room_without_tax
																	)}
																</>
															) : (
																"-"
															)}
														</div>
													</div>
													{bookingData?.total_cost -
														Number(
															bookingData
																?.res_detail?.[
																roomId
															]
																?.price_per_room_without_tax
														) >=
													0 ? (
														<div className='columnWrap'>
															{bookingData?.res_detail?.[
																roomId
															]?.tax?.inclusive?.map(
																(
																	item,
																	index
																) => (
																	<>
																		<div className='column50'>
																			{index ===
																			0 ? (
																				<span>
																					{t(
																						"taxes"
																					)}
																				</span>
																			) : (
																				""
																			)}
																		</div>
																		<div className='column50'>
																			{item.calculation_type ===
																			"Percentage"
																				? `${item.tax_title} at ${item.tax_value}%`
																				: ""}
																		</div>
																	</>
																)
															)}
															{bookingData?.res_detail[roomId].tax?.exclusive?.map((item, index) => (
																<>
															        {item.calculation_type === "Percentage" ?
																		<div className='columnWrap'>
																			<div className='column50'>
																				{!bookingData?.res_detail?.[roomId]?.tax?.inclusive?.length ? 
																					index === 0 ? <span>{t("taxes")}</span> : "" : ""
																				}
																			</div>
																			<div className='column50'>
																				{`${item.tax_title} at ${item.tax_value}%`}
																			</div>
																		</div> : ""
																	}
																</>)
															)}
														</div>
													) : (
														""
													)}
												</div>
											</li>
											<li>
												<div className='columnWrap'>
													<div className='column100'>
														<span className='dBlock textUppercase'>
															{t("stay_details")}
														</span>
													</div>
												</div>
												<div className='bookingDetailInfoWrap'>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("name")}
															</span>
														</div>
														<div className='column50'>
															{bookingData?.customer_name ||
																"-"}
														</div>
													</div>

													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("room")}
															</span>
														</div>
														<div className='column50'>
															{bookingData
																?.res_detail[
																roomId
															]?.room_type_name ||
																"-"}
														</div>
													</div>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("rate")}
															</span>
														</div>
														<div className='column50'>
															{bookingData
																?.res_detail[
																roomId
															]?.rate_name.replace(/\\'/g, "'") || "-"}
														</div>
													</div>
													<div className='columnWrap'>
														<div className='column50'>
															<span>
																{t("dates")}
															</span>
														</div>
														<div className='column50'>
															{bookingData?.date_in &&
																moment(
																	bookingData?.date_in
																).format(
																	`${dateFormat}`
																)}{" "}
															-{" "}
															{bookingData?.date_out &&
																moment(
																	bookingData?.date_out
																).format(
																	`${dateFormat}`
																)}
														</div>
													</div>
													{bookingData?.addon_data && (
														<>
															<div className='columnWrap emailEnhenceEmailTitle enhenceNewPopup'>
																<div className='column50'>
																	<span>
																		{t(
																			"your_enhancement"
																		)}
																	</span>
																</div>
																<div className='column50 viewEnhenceLabel'>
																	{Object.values(
																		bookingData?.addon_data
																	).map(
																		(
																			item
																		) => {
																			let addonquantity = 0;
																			let addonprice = 0;
																			Object.values(
																				item.date
																			).map(
																				(
																					dailyaddon
																				) => {
																					addonquantity +=
																						dailyaddon?.addon_count ||
																						0;
																					addonprice +=
																						dailyaddon?.total_addon_price ||
																						0;
																				}
																			);
																			const {
																				title,
																				quantity,
																				addon_rate,
																			} =
																				item;
																			return (
																				<>
																					<p
																						key={
																							item.id
																						}
																					>
																						{`${item.quantity} x ${title}`}
																						{
																							", "
																						}
																						{getSymbolFromCurrency(
																							bookingData.currsign
																						)}
																						{currencyDisplay(
																							addonprice
																						)}
																					</p>
																				</>
																			);
																		}
																	)}
																</div>
															</div>
														</>
													)}
												</div>
											</li>
											<li>
												<div className='columnWrap'>
													<div className='column100'>
														{policyMsg ? (
															<div
																dangerouslySetInnerHTML={{
																	__html: policyMsg,
																}}
															/>
														) : (
															"-"
														)}
													</div>
												</div>
											</li>
											<li>
												<div className='columnWrap'>
													<div className='column100'>
														<span className='dBlock textUppercase'>
															{t("charges")}
														</span>
													</div>
												</div>
												<div className='bookingDetailInfoWrap'>
													<div className='columnWrap'>
														<div className='column50'>
															{t(
																"cancellation_fee"
															)}
														</div>
														<div className='column50 booklastColumn50'>
															{bookingData
																?.res_detail?.[
																roomId
															]
																?.cancellation_charges >=
															0 ? (
																<>
																	{getSymbolFromCurrency(
																		bookingData.currsign
																	)}
																	{bookingData?.total_cost &&
																		currencyDisplay(
																			bookingData
																				?.res_detail?.[
																				roomId
																			]
																				?.cancellation_charges
																		)}
																</>
															) : (
																"-"
															)}
														</div>
													</div>
													<div className='columnWrap'>
														<div className='column50'>
															{t("amendment_fee")}
														</div>
														<div className='column50 booklastColumn50'>
															{bookingData
																?.res_detail?.[
																roomId
															]
																?.amendment_charges >=
															0 ? (
																<>
																	{getSymbolFromCurrency(
																		bookingData.currsign
																	)}
																	{bookingData?.total_cost &&
																		currencyDisplay(
																			bookingData
																				?.res_detail?.[
																				roomId
																			]
																				?.amendment_charges
																		)}
																</>
															) : (
																"-"
															)}
														</div>
													</div>
												</div>
											</li>
											<li className='bookingInfoTotal'>
												<div className='columnWrap'>
													<div className='column50'>
														<span className='dBlock textUppercase'>
															{t(
																"booking_total_b"
															)}
														</span>
													</div>
													<div className='column50 booklastColumn50'>
														{bookingData?.total_cost ? (
															<>
																{getSymbolFromCurrency(
																	bookingData.currsign
																)}
																{bookingData?.total_cost &&
																	currencyDisplay(
																		bookingData.total_cost
																	)}
															</>
														) : (
															"-"
														)}
													</div>
												</div>
											</li>
										</ul>
										{!bookingData || pol?.cancellation?.rules?.modifiable === "no" || dateCheck === false || bookingData.status === 0 ? "" : (
											<div className='manageReserveBtn buttonsGroup paddingB30'>
												<button
													className='customBtn customBtnTransparent'
													type='button'
													onClick={() => cancelStay()}
												>
													{t("cancel_stay")}
												</button>
												<button
													className='customBtn customBtnDark'
													type='button'
													onClick={() =>
														setOpen(true)
													}
												>
													{t("modify_stay")}
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</section>
					</>
				) : (
					<section className='viewReservationForm paddingTB100'>
						<div className='columnContainer'>
							<div className='columnWrap justifyContentCenter'>
								<div className='column45'>
									<div className='viewReservationFormWrap'>
										<div className='formTitleWrap textCenter paddingB20'>
											<h2 className='textUppercase m0 paddingB10'>
												{t("modify_your_reservation")}
											</h2>
											<span>
												{t("enter_reservation_details")}
											</span>
										</div>
										<div className='marginB25'>
											<label
												className='hideTag'
												for='reserNum1'
											>
												{t("reservation_number")}
											</label>
											<Input
												id='reserNum1'
												placeholder={t(
													"reservation_number"
												)}
												required
												rest={register(
													"reservation_id",
													{
														onChange: (event) => {
															setValue(
																"reservation_id",
																event.target.value.replace(
																	NUMBER_REGEX,
																	""
																)
															);
														},
													}
												)}
												onKeyDown={(event) =>
													onKeyDown(event)
												}
												error={
													errors.reservation_id
														?.message
												}
											/>
										</div>
										<div className='marginB25'>
											<label
												className='hideTag'
												for='emailReser1'
											>
												{t("email")}
											</label>
											<Input
												id='emailReser1'
												type='email'
												placeholder={t("email")}
												required
												maxLength='50'
												rest={register("email")}
												onKeyDown={(event) =>
													onKeyDown(event)
												}
												error={errors.email?.message}
											/>
										</div>
										{invalidMsg && (
											<p className='errorMessage managepageError'>
												{invalidMsg}
											</p>
										)}
										<button
											className='customBtn customBtnDark marginT10 w100'
											type='button'
											onClick={handleSubmit(
												getYourReservation
											)}
										>
											{t("submit")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</section>
				)}
			</main>
			<ModifyBookingModal
				open={open}
				setOpen={setOpen}
				bookingData={bookingData}
				roomDetail={bookingData?.res_detail[roomId]}
				siteConfig={props.siteConfig}
			/>
		</>
	);
}

export default ManageBooking