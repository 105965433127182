import React, { useContext } from "react";
import { useCountry } from "../../../hooks/useCountry";
import { useEffect } from "react";
import AppContext from "../../../utils/appContext";
import { useTranslation } from "react-i18next";

const PhoneInput = ({
	title = "title",
	type = "text",
	placeholder = "",
	maxLength = "",
	required = false,
	disabled = false,
	error,
	phoneCodeError,
	onChange = () => false,
	rest,
	codeRest,
	bookingData,
	watch,
	setValue,
	errors
}) => {
	const { t } = useTranslation();
	const context = useContext(AppContext);
	const { phoneCode, countryData } = useCountry({
		countryId: bookingData?.country_id,
	});

	useEffect(() => {
		if (watch("phoneCode") && phoneCode?.length && countryData?.length) {
			let countryId = phoneCode.find((x) => x.country_id == watch("phoneCode"))?.country_id;
			let selectedPhone = countryData.find((x) => x.country_id === countryId);
			context.setSelectedPhone(selectedPhone);
		}
	}, [watch("phoneCode")]);

	const countryId = bookingData?.country_id || null
	useEffect(() => {
		if (countryId && countryData?.length) {
			let countryIds = countryData?.find(code => code.country_id === countryId)
			setValue("phoneCode", countryIds?.country_id)
		}
	}, [countryId, countryData])

	return (
		<>
			<div className='column20 marginB25 mobileTwoColumn countryCode'>
				<label className='inputFieldWrap'>
					<select
						{...codeRest}
						title={t("country_code")}
						autoComplete={`new-country` + Math.random().toString(36).substring(7)}
					>
						<option value="">{t("country_code")}</option>
						{phoneCode?.map((code) => {
							return (
								<option value={code.country_id}>
									{code.country_name} (+{code.isd_code})
								</option>
							);
						})}
					</select>
					{(error || phoneCodeError) ? <p className='errorMessage pL-1'>{(error && phoneCodeError) ? t("country_code_phone_number_required") : error ? error : phoneCodeError}</p> : ""}
				</label>
			</div>
			<div className='column33 marginB25 mobileTwoColumn'>
				<label className='inputFieldWrap'>
					<input
						title={title}
						id='phoneNum1'
						type={type}
						placeholder={placeholder}
						maxLength={maxLength}
						disabled={disabled}
						inputMode='numeric'
						onChange={onChange}
						// onKeyPress={(event) => {
						//   const keyCode = event.keyCode || event.which;
						//   const keyValue = String.fromCharCode(keyCode);
						//   if (!/^[0-9]+$/.test(keyValue)) {
						//     event.preventDefault();
						//   }
						// }}
						{...rest}
					/>
					<span className='label' aria-lablledby={t("phone_number")}>{t("phone_number") + "*"}</span>
					{errors?.phoneNumber?.message &&
						<p className='errorMessage pL-1'>{errors.phoneNumber?.message}</p>
					}
				</label>
			</div>
		</>
	);
};

export default PhoneInput;
