import React from "react";

const Maintenance = ({ maintenanceMsg }) => {
	return (
		<main className='mainContent maintenancePage textCenter downTimeMaintenancePage'>
			<div className='columnContainer'>
				<div className='maintenanceMessage'>
					<div dangerouslySetInnerHTML={{ __html: maintenanceMsg }} />
				</div>
			</div>
		</main>
	);
};

export default Maintenance;
