import React, { useState } from 'react'
import BillingDetails from './billingDetails'
import { DEFAULT_LANG, KEYS, NUMBER_REGEX } from '../../utils/constant';
import { alphabeticName, capitalizeStr, getCardType, privacyPolicyLink, replaceEmoji } from '../../utils/utils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input/inputField'
// import ReactHtmlParser from "react-html-parser"
import CommonModal from "../common/Modal/commonModal"
import parse from "html-react-parser";
import { useTranslation } from "react-i18next"
import { LocalStorage } from '../../utils/storage';

const PaymentDetails = ({
	handleTabClick,
	fields,
	stopClose,
	activeTab,
	errors,
	watch,
	register,
	setValue,
	clearErrors,
	setError,
	checkoutData,
	siteConfig,
	ref2,
	bookingData,
	ref1
}) => {
	const { t } = useTranslation()
	let [openTerms, setOpenTerms] = useState(false)
	let [openPrivacy, setOpenPrivacy] = useState(false)
	let [viewAllStatus, setViewAllStatus] = useState(false)
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";

	String.prototype.stripSlashes = function () {
		return this.replace(/\\(.)/mg, "$1");
	}

	let formatString = e => {
		var code = e.keyCode
		var allowedKeys = [8]
		if (allowedKeys.indexOf(code) !== -1) {
			return
		}
		e.target.value = e.target.value
			.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
			.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
			.replace(/^([0-1])([3-9])$/g, "0$1/$2")
			.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
			.replace(/^([0]+)\/|[0]+$/g, "0")
			.replace(/[^\d\/]|^[\/]*$/g, "")
			.replace(/\/\//g, "/")
	}

	const scrollToTop = () => {
		handleTabClick(2);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			const targetTagName = e.target.tagName.toLowerCase();
			if (targetTagName !== "input") {
				scrollToTop();
			}
		}
	};

	const paymentErrors = errors?.name_on_card?.message ||
		errors?.card_number?.message ||
		errors?.cardType?.message ||
		errors?.expiryDate?.message ||
		errors?.cvv?.message
	return (
		<>
			<div
				className="checkoutTab checkoutTabShow"
				tabIndex='0'
				onClick={scrollToTop}
				ref={ref2}
				onKeyPress={handleKeyPress}
			>
				<h2 className='textUppercase'>{t("payment_details")}</h2>
				<div className='checkoutTabDetails' onClick={(e) => stopClose(e)}>
					<div className='columnWrap'>
						<div className='column100 marginB25'>
							<div className="cardType">
								<ul>
									<li className={`express ${(watch("cardType") === "American Express") ? "active" : ""}`}></li>
									<li className={`club ${(watch("cardType") === "Diners Club") ? "active" : ""}`}></li>
									<li className={`discover ${(watch("cardType") === "Discover") ? "active" : ""}`}></li>
									<li className={`JSB ${(watch("cardType") === "JCB") ? "active" : ""}`}></li>
									<li className={`Master ${(watch("cardType") === "Mastercard") ? "active" : ""}`}></li>
									<li className={`Visa ${(watch("cardType") === "Visa") ? "active" : ""}`}></li>
								</ul>
							</div>
							{/* <Input
							title={t("card_type")}
							id='cardType'  
							placeholder={t("card_type")+"*"}
							isDisabled
							rest={register("cardType")} 
						/>  */}
							{errors.cardType?.message &&
								<p className='errorMessage pL-1'>{errors.cardType.message}</p>
							}
						</div>
						<div className='column33 marginB25'>
							<Input
								title={t("card_number")}
								id='cardNumber'
								maxLength='19'
								inputMode='numeric'
								rest={register("cardNumber", {
									onBlur: (e) => {
										const cardNumber = replaceEmoji(
											e.target.value
										);
										setValue(
											"cardNumber",
											cardNumber.replace(NUMBER_REGEX, "")
										);
										if (cardNumber) {
											let cardNumberType =
												getCardType(cardNumber);
											if (cardNumberType === null) {
												setError("cardType", {
													type: "custom",
													message: t("invalid_card_type"),
												});
												setValue("cardType", "");
											} else {
												setValue(
													"cardType",
													cardNumberType
												);
												clearErrors("cardType");
											}
										} else {
											setValue("cardType", "");
											setError("cardType", {
												type: "custom",
												message: t("card_type_required"),
											});
										}
									},
									onChange: (e) => {
										const cardNumber = replaceEmoji(
											e.target.value
										);
										setValue(
											"cardNumber",
											cardNumber.replace(NUMBER_REGEX, "")
										);
									},
								})}
							/>
							<span className="label" for="cardNumber" aria-lablledby={t("card_number")}>{t("card_number") + "*"}</span>
							{errors.cardNumber?.message &&
								<p className='errorMessage pL-1'>{errors.cardNumber.message}</p>
							}
						</div>
						{ }
						<div className='column33 marginB25 mobileTwoColumn'>
							<Input
								title='Expiry Date'
								id='expiryDate'
								maxLength='5'
								inputMode='numeric'
								rest={register("expiryDate")}
								onKeyUp={(e) => formatString(e)}
							/>
							<span className="label" for="expiryDate" aria-lablledby='Expiry Date'>{t("expiry_date") + "*"}</span>
							{errors.expiryDate?.message &&
								<p className='errorMessage pL-1'>{errors.expiryDate.message}</p>
							}
						</div>
						<div className='column33 marginB25 mobileTwoColumn'>
							<Input
								title={t("cvv")}
								type='password'
								id='password'
								inputMode='numeric'
								maxLength='4'
								rest={register("cvv", {
									onChange: (event) => {
										setValue(
											"cvv",
											event.target.value.replace(
												NUMBER_REGEX,
												""
											)
										);
									},
								})}
							/>
							<span className="label" for="password" aria-lablledby={t("cvv")}>{t("cvv") + "*"}</span>
							{errors.cvv?.message &&
								<p className='errorMessage pL-1'>{errors.cvv.message}</p>
							}

						</div>
						<div className='column33 marginB25'>
							<Input
								title={t("name_on_card")}
								id='nameonCard'
								maxLength='20'
								rest={register("nameOnCard", {
									onChange: (e) => {
										setValue(
											"nameOnCard",
											alphabeticName(e.target.value)
										);
									},
								})}
							/>
							<span className="label" for="nameonCard" aria-lablledby={t("name_on_card")}>{t("name_on_card") + "*"}</span>
							{errors.nameOnCard?.message &&
								<p className='errorMessage pL-1'>{errors.nameOnCard.message}</p>
							}
						</div>
						<div className='column100'>
							<BillingDetails
								activeTab={activeTab}
								errors={errors}
								watch={watch}
								register={register}
								handleTabClick={handleTabClick}
								stopClose={stopClose}
								setError={setError}
								setValue={setValue}
								clearErrors={clearErrors}
								bookingData={bookingData}
								ref1={ref1}
							/>
						</div>
					</div>
				</div>
				<div className='column100 paymentDetailContent paddingT25'>
					<label className='textUppercase policyHead'>{t("policies")}</label>
					{checkoutData?.rooms?.map((pol, index) => {
						let policyMsg = "";
						if (pol?.policy?.samemodifycancel) {
							policyMsg += `<p><strong>${t(
								"amendment_policy"
							)}:</strong> ${pol?.policy?.cancellation?.summary
								}</p>`;
						} else {
							policyMsg += `<p><strong>${t(
								"modification_policy"
							)}:</strong> ${pol?.policy?.amendment?.summary
								}</p>`;
							policyMsg += `<p><strong>${t(
								"cancellation_policy"
							)}:</strong> ${pol?.policy?.cancellation?.summary
								}</p>`;
						}
						if (pol?.policy?.payment.summary) {
							policyMsg += `<p><strong>${t(
								"payment_policy"
							)}:</strong> ${pol?.policy?.payment.summary}</p>`;
						}
						return (
							<>
								{index === 0 && <div className='roomPolicy'>
									<p>
										<strong style={{ color: "#000" }}>
											{/* {t("room_name")} / {t("rate_name")} */}
											{t("policy_room")} {index + 1}:
										</strong>
										{capitalizeStr(pol.room_type_name)} |{" "}
										{capitalizeStr(pol.rate_name)?.replace(/\\'/g, "'")}
									</p>
									{/* {ReactHtmlParser(policyMsg)} */}
									<div
										dangerouslySetInnerHTML={{
											__html: policyMsg,
										}}
									/>
									{fields.length > 1 && !viewAllStatus && <span className='viewAllTypo' onClick={() => {
										setViewAllStatus(!viewAllStatus)
									}} >{t("viewAll")}</span>}
								</div>}
								{viewAllStatus && index > 0 && <div className='roomPolicy'>
									<p>
										<strong style={{ color: "#000" }}>
											{/* {t("room_name")} / {t("rate_name")} */}
											{t("policy_room")} {index + 1}:
										</strong>
										{capitalizeStr(pol.room_type_name)} |{" "}
										{capitalizeStr(pol.rate_name)?.replace(/\\'/g, "'")}
									</p>
									{/* {ReactHtmlParser(policyMsg)} */}
									<div
										dangerouslySetInnerHTML={{
											__html: policyMsg,
										}}
									/>
									{fields.length === index + 1 && <span className='viewAllTypo' onClick={() => {
										setViewAllStatus(!viewAllStatus)
									}} >{t("view_less")} </span>}
								</div>}
							</>
						);
					})}
					<div className='termPolicyCheckbox' id='termsdata'>
						<div className='customCheckBox'>
							<Checkbox
								id='termsCheckbox'
								text={[
									t("terms_of_booking_1"),
									" ",
									<a
										href='javascript:void(0);'
										onClick={() => setOpenTerms(true)}
									>
										{t("terms_of_booking_2")}
									</a>, <>*</>
								]}
								isChecked={watch("termsCheckbox")}
								rest={register("termsCheckbox")}
								error={errors.termsCheckbox?.message}
							/>
							<CommonModal
								popupClass='checkoutPrivacyPolicy commonModalPopup termsncondition'
								open={openTerms}
								setOpen={setOpenTerms}
								data={LocalStorage.get(KEYS.I18_NEXT_LNG) !== DEFAULT_LANG ?
									siteConfig?.site_title_lang?.[LocalStorage.get(KEYS.I18_NEXT_LNG)]?.term_and_conditions &&
									parse(siteConfig?.site_title_lang?.[LocalStorage.get(KEYS.I18_NEXT_LNG)]?.term_and_conditions?.stripSlashes()) : parse(siteConfig?.term_and_conditions.stripSlashes())}
								title={t("terms_and_conditions")}
							/>
						</div>
						<div className='customCheckBox'>
							<Checkbox
								id='policyCheckbox'
								text={[
									t("privacy_policy_1"),
									" ",
									<a
										href='javascript:void(0);'
										onClick={(event) => {
											window.open(
												privacyPolicyLink(
													siteConfig?.site_id,
													language
												),
												"_blank"
											)
										}
										}
									>
										{t("privacy_policy_2")}
									</a>, <>*</>
								]}
								isChecked={watch("policyCheckbox")}
								rest={register("policyCheckbox")}
								error={errors.policyCheckbox?.message}
							/>
							<CommonModal
								popupClass='checkoutPrivacyPolicy commonModalPopup'
								open={openPrivacy}
								setOpen={setOpenPrivacy}
								data={
									siteConfig?.privacy_policy &&
									parse(
										siteConfig?.privacy_policy?.stripSlashes()
									)
								}
								title={t("privacy_policy")}
							/>
						</div>
						<div className='customCheckBox'>
							<Checkbox
								id='optIn'
								text={t("opt_in").replace("[hotelname]", checkoutData?.hotel?.hotel_name)}
								isChecked={watch("optIn")}
								rest={register("optIn", {
									onChange: (event) => {
										setValue("optIn", event.target.checked);
									},
								})}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PaymentDetails