import React, { useEffect } from "react";
import TagManager from 'react-gtm-module';


  
  const Gtm = (props) => {

    useEffect(() => {
      if(props.siteConfig.google_tag_head_code != 0 && props.siteConfig.google_tag_head_code != '')
      {
        const tagManagerArgs = {
          "gtmId": "GTM-"+props.siteConfig.google_tag_head_code
        };
        TagManager.initialize(tagManagerArgs);  
      }
    }, [props.siteConfig])

    
    return null;
  };

  
  export default Gtm;