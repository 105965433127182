import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchMetaTagsData = (data, authToken) => {
	return new Promise((resolve, reject) => {
		fetchUrl(
			apiList.seoMetaTags.method,
			apiList.seoMetaTags.url,
			data,
			authToken
		)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => reject(err));
	});
};
