import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchMsgClosedUserGroup = (authToken, requestData) => {
    return new Promise((resolve, reject) => {
        fetchUrl(
            apiList.guestGroupMessage.method,
            apiList.guestGroupMessage.url,
            requestData,
            authToken
        )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => reject(err));
    })
}