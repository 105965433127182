import React, { useState, useEffect } from 'react';

const WithHeader = ({
    className = "column15",
    siteConfig
}) => {
    const [HeaderComponent, setHeaderComponent] = useState(null);
    const siteID = siteConfig?.site_id || false;
    // const siteID = 31237;

    useEffect(() => {
      const HeaderComponentLazy = React.lazy(() => {
        return import(`../../../../../includes/headers/${siteID}/CustomHeader`).then(
          (module) => ({ default: module.default })
        );
      });
      setHeaderComponent(HeaderComponentLazy);
    }, [siteID]);

    return (
      <>
        {HeaderComponent && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <HeaderComponent className={className} />
          </React.Suspense>
        )}
      </>
    );
};

export default WithHeader;
