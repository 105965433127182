import React, { useState } from "react";
import { currencyDisplay } from "../../../utils/utils";
import moment from "moment";

export default function YourStayBookedRoomAddons({
	addonsData,
	currencySymbol,
	strEnhancements,
	strTotalEnhancement,
}) {
	const [viewEnhancement, setViewEnhancement] = useState(false);
	let addonTotalAmtArr = [];
	let addonName = "";
	let addons_breakup_ui = Object.keys(addonsData)?.map((addon_key, index) => {
		let totalAddonPrice = 0;
		let addonQuantity = 0;
		Object.keys(addonsData[addon_key]?.date)?.map(
			(date_key, index) => {
				addonQuantity = addonQuantity+ addonsData[addon_key]["quantity"];
				addonName = addonsData[addon_key]["title"];
				totalAddonPrice =
				totalAddonPrice + addonsData[addon_key]["date"][date_key][
						"total_addon_price"
					];
				 // store each days addon's total in array
				}
			);
			addonTotalAmtArr.push(totalAddonPrice);
				return (
					<li key={index} className='dFlex justifyContentSpBtw'>
						<day className='cartDate'>
							{/* {moment(date_key).format("ddd, DD MMM YYYY")} {" ("}
							{addonName} {")  "} */}
							{addonsData[addon_key]["quantity"]} {' x '} {addonName}
						</day>
						<div className='perDayPrice'>
							{currencySymbol}
							{currencyDisplay(totalAddonPrice)}
						</div>
					</li>
				);
			
	});

	return (
		<div className='column100 afterAddtoCart pL0 pR0'>
			{viewEnhancement ? (
				<div className='column100 cartPriceBreak'>
					<p className='m0'>{strEnhancements}:</p>
					<ul className='optionEnhance'>{addons_breakup_ui}</ul>
				</div>
			) : (
				""
			)}

			<div
				className='dFlex justifyContentSpBtw'
				style={{ cursor: "pointer" }}
				onClick={() => setViewEnhancement(!viewEnhancement)}
			>
				<div>{strTotalEnhancement}: </div>
				<div
					className={
						viewEnhancement
							? "cartTotalPrice arrowUp"
							: "cartTotalPrice"
					}
				>
					{currencySymbol}
					{currencyDisplay(
						addonTotalAmtArr.reduce((accumulator, item) => {
							return accumulator + item;
						}, 0)
					)}
				</div>
			</div>
		</div>
	);
}
