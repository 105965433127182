import React, { useContext, useEffect } from "react";
import { KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import AppContext from "../../utils/appContext";
import { useTranslation } from "react-i18next";
import checkcircleicon from "../../styles/images/check-circle-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { route } from "../../utils/route";

const AddCodes = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let context = useContext(AppContext);
  let bookingcode = SessionStorage.get(KEYS.BOOKING_CODE) || "";

  const [localbookingCode, setlocalbookingCode] = React.useState(bookingcode);
  const [displayBookingCode, setdisplayBookingCode] = React.useState(bookingcode);
  
  const handleChange = event => {
    setdisplayBookingCode(event.target.value);
    if(event.target.value!=''){
        props.setValidBookingCode(false);
    }
  };

  let openCodeSection = () => {
    props.openCodeSection();
    props.setValidBookingCode(false);
  };

  const goToLoadRoomList = event => {
    event.preventDefault();
    setlocalbookingCode(displayBookingCode);
    
    if (displayBookingCode) {
      SessionStorage.set(KEYS.BOOKING_CODE, displayBookingCode);
      props.setValidBookingCode(false);
      context.setAddedCode(true)
      if (location.pathname === route.checkout || location.pathname === route.roomDetail) {
        navigate(route.roomList);
      } else {
        props.getRoomList();
        props.getRoomList("", true)
      }		
    } else {
      props.setValidBookingCode(true);
    }
  };

  useEffect(() => {
    if (context.codeAdded) {
      context.setYourStay(true);
    }
  }, [context.codeAdded])

  useEffect(()=> {
    if (context.hotelData){
      if(context.hotelData.valid_code && localbookingCode && context.codeAdded === true){
        SessionStorage.set(KEYS.BOOKING_CODE, localbookingCode);
        props.setValidBookingCode(false);
        openCodeSection()
        if(context.hotelData.code_used.trim() != ''){
          let roomData = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
         // sessionStorage.setItem("roomData", roomData);
         roomData.length > 0 && roomData.map((item, index) => {
					
          roomData[index].price = [];
        roomData[index].room_info = {
          room_id: 0,
          rate_id: 0,
          room_name: "",
          rate_name: ""
        };
        roomData[index].enhancement = [];
        
    
      });
				  SessionStorage.setJSON(KEYS.ROOM_DATA, roomData);
          context.setOldCode(true);
        }
       // context.setAddedCode(false)
        
      }else if(!context.hotelData.valid_code && context.codeAdded === true){
        setlocalbookingCode('');
        setdisplayBookingCode('');
        props.setValidBookingCode(true);
        SessionStorage.remove(KEYS.BOOKING_CODE);
        context.setAddedCode(false)
				if(context.oldCode === true){
					let roomData =
					'[{"room":1,"adults":1,"childrens":[],"price":[],"room_info":{"room_id":0, "rate_id":0, "room_name": "", "rate_name":""}}]';
					//sessionStorage.setItem("roomData", roomData);
					context.setOldCode(false);
				}
        props.getRoomList();
        props.getRoomList("", true)
      }
    }
  }, [context.codeAdded])

  return (
    <div className="cartSidebar">
      <div className="cartSpecialCodeSidebar posRelative">
        <button
          type="button"
          className="closeSideBarPopup closePopup closeIcon posAbsolute"
          onClick={() => openCodeSection()}
          aria-label="Close Button"
        ></button>
        <h2 className="m0 paddingTB25 textUppercase">{t("special_code")}</h2>
        <div className="codeInputWrap posRelative">
          <label className="dBlock" htmlFor="bookingcode">
            {/* <span class="hideTag">{t("add_code")}</span> */}
            <input
              className={`w100 ${
                props.validBookingCode ? "validBookingCode" : ""
              }`}
              type="text"
              maxLength="20"
              id="bookingcode"
              name="bookingcode"
              onChange={handleChange}
              value={displayBookingCode}
              autoComplete="off"
              title={t("add_code")}
            />
            {(!props.validBookingCode && context.hotelData.valid_code && localbookingCode && SessionStorage.get(KEYS.BOOKING_CODE)) && 
              <img className="posAbsolute" src={checkcircleicon} alt="Close Icon" width="22" />
            }
            {props.validBookingCode ? <div className="errorMessage">{t("enter_valid_booking_code")}</div> : ""}
          </label>
        </div>

        <div className="cartSidebarBtn paddingT25 marginT40">
          <button
            className="addCodeBtn customBtn customBtnDark w100"
            onClick={goToLoadRoomList}
          >
            {t("add_code")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCodes;
