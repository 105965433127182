import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

// export const fetchConfirmationData = (data, authToken) => {
//   return new Promise((resolve, reject) => {
//     fetchUrl(
//       apiList.confirmation.method,
//       apiList.confirmation.url,
//       data,
//       authToken
//     )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => reject(err));
//   });
// };

export const saveReservation = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(
      apiList.savereservation.method,
      apiList.savereservation.url,
      data,
      authToken
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const sendReservationEmail = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.sendreservationemail.method, apiList.sendreservationemail.url, data, authToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
}

export const cancelReservationEmail = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.cancelReservationEmail.method, apiList.cancelReservationEmail.url, data, authToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
}

export const modifyReservation = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(
      apiList.modifyReservation.method,
      apiList.modifyReservation.url,
      data,
      authToken
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const fetchCancelReservationData = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(
      apiList.cancelreservation.method,
      apiList.cancelreservation.url,
      data,
      authToken
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
