import React, { useEffect, useState } from "react";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import UrlConstants from "../../../theme1/config/UrlConstants";
import Slider from "react-slick";
import ReactPlayer from "react-player";

function RoomSlider(props) {
  let roomImages = props.roomImage ? props.roomImage : [];

  const [isPlaying, setIsPlaying] = useState(false);
  const [seconds, setSeconds] = useState(false);
  const [videoKey, setVideoKey] = useState(0);
	const [autoplay, setAutoPlay] = useState(false);

  // Function to re-render the video tag
	const reRenderVideo = () => {
		setVideoKey((prevKey) => prevKey + 1);
	  };



  const [displayControl, setdisplayControl] = useState(false);
  const handleProgress = (secs) => {
    setSeconds(secs);
    if(secs >= 3){
      setdisplayControl(true);
    }
  };

  let selectedhotelId = sessionStorage.getItem("hotelId")
    ? sessionStorage.getItem("hotelId")
    : UrlConstants.hoteId;
  let imageArray = [];
  let videoArray = [];

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  roomImages &&
    roomImages.map((item, index) => {
      let loopImage = isMobile ? item.thumb_img_name : item.full_img_name;
      let checkImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(loopImage);
      if (checkImage) {
        let imageUrl =
          UrlConstants.imageUrl +
          "/hotel_images/" +
          selectedhotelId +
          "/" +
          loopImage;
        imageArray[index] = imageUrl;
      } else {
        videoArray[index] = item.full_img_name;
      }
    });        

  const settings = {
		lazyLoad: true,
		centerMode: true,
		centerPadding: "185px",
		slidesToShow: 1,
		speed: 500,
		slidesToScroll: 1,
		autoplaySpeed: 2000,
		autoplay: false,
		dots: true,
		infinite: true,
		responsive: [
			{
				breakpoint: 1385,
				settings: {
					centerPadding: "100px",
				},
			},
			{
				breakpoint: 1023,
				settings: {
					centerPadding: "25px",
					arrows: false,
				},
			},
			{
				breakpoint: 400,
				settings: {
					centerPadding: "25px",
					arrows: false,
				},
			},
		],
		afterChange: () => {
			setIsPlaying(true);
			setAutoPlay("autoplay");
			reRenderVideo();
		},
		customPaging: (i) => (
			<button title={`Go to slide ${i + 1}`}>
				{`Go to slide ${i + 1}`}
			</button>
		),
  };

  return (
    <section className='roomDetailSlider'>
      <Slider {...settings}>
        {imageArray &&
          imageArray.map((item, index) => {
            return (
				<div key={index}>
					<img src={item} alt={`${props?.roomName} ${index + 1}`} />
				</div>
			);
          })}

        {videoArray &&
          videoArray.map((item, index) => {
            return (
              <div key={index}>
                {/*
                <ReactPlayer
                  url={item}
                  playing={isPlaying}
                  onStart={() => setIsPlaying(false)}
                  onPause={() => setIsPlaying(false)}
                  onEnded={() => setIsPlaying(false)}
                  volume={0}
                  height='100%'
                  width='100%'
                  loop={true}
                  playsinline={true}
                  controls={displayControl}
                  onProgress={(e) => handleProgress(e.playedSeconds)}
                  mute={"true"}
                />
            */}
            <div  style={{width: "100%", height: "100%"}}><video key={videoKey} style={{width: "100%", height: "100%"}} preload="auto" controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar" autoplay={autoplay} muted controls  loop playsinline="playsinline" webkit-playsinline x5-playsinline tabIndex="-1" ><source src={item} type="video/mp4" /></video></div>
              </div>
            );
          })}
      </Slider>
    </section>
  );
}
export default RoomSlider;
