import React from 'react';
import { useNavigate } from 'react-router-dom';
import { route } from '../../../../utils/route';
import {SITE_ID_LIST } from "../../../../utils/constant"

import ClaridgesMobileLogoImage from '../../../../styles/images/claridges-logo-colored-mobile.svg';
import BerkeleyMobileLogoImage from '../../../../styles/images/berkeley-logo-mobile.svg';
import ConnaughtMobileLogoImage from '../../../../styles/images/connaught-logo-mobile.svg';
import MaybourneMobileLogoImage from '../../../../styles/images/maybourne-beverly-hills-mobile-logo.svg';
import RivieraMobileLogoImage from '../../../../styles/images/riviera-logo.svg';
import EmoryMobileLogoImage from '../../../../styles/images/emory-mobile-logo1.svg';

const siteLogos = {
  [SITE_ID_LIST[0]]: ClaridgesMobileLogoImage,
  [SITE_ID_LIST[1]]: BerkeleyMobileLogoImage,
  [SITE_ID_LIST[2]]: ConnaughtMobileLogoImage,
  [SITE_ID_LIST[3]]: MaybourneMobileLogoImage,
  [SITE_ID_LIST[4]]: RivieraMobileLogoImage,
  [SITE_ID_LIST[5]]: EmoryMobileLogoImage,
  [SITE_ID_LIST[6]]: RivieraMobileLogoImage,
	[SITE_ID_LIST[7]]: MaybourneMobileLogoImage,
	[SITE_ID_LIST[8]]: EmoryMobileLogoImage
};

const MobileLogo = ({ siteId }) => {
  const navigate = useNavigate();
  const imageSource = siteLogos[siteId] || '';

  return (
    <a tabindex="0" className="dInlineBlock" href="javascript:void(0);" onClick={() => navigate(route.roomList)}>
      <img className="mobileLogo" src={imageSource} alt="Mobile claridges logo" />
    </a>
  );
};

export default MobileLogo;
