import React from "react";
import { currencyDisplay } from "../../../utils/utils";
import moment from "moment";

export default function ConfirmPrintPopupDateWiseAddons({
	enhancements_str,
	total_enhancements,
	currencySymbol,
	addons_data,
}) {
	let addonTotalAmtArr = [];
	
	let addonName = "";

	return (
		<>
			<div className='columnWrap enhancementsPrintPreviewPopupLabel enhenceNewPopup'>
				<div className='column50'>
					<span>
						{enhancements_str}
					</span>
				</div>
				<div className="column50 viewEnhenceLabel">
				{Object.keys(addons_data)?.map((addon_key, index) => {
					let totalAddonPrice = 0;
					let addonQuantity = 0;
					 Object.keys(addons_data[addon_key]?.date)?.map(
						(date_key, index) => {
							addonQuantity += addons_data[addon_key]["quantity"];
							addonName = addons_data[addon_key]["title"];
							totalAddonPrice +=
								addons_data[addon_key]["date"][date_key][
									"total_addon_price"
								];
							 // store each days addon's total in array
						}
						);
						addonTotalAmtArr.push(totalAddonPrice);
						return (
							<>
								<p key={index}>
									{/* {moment(date_key).format(
										"ddd, DD MMM YYYY"
									)}{" "}
									{" ("}
									{addonName} {") "} */}
									{addons_data[addon_key]["quantity"]} {" x "}{" "}
									{addonName}{", "}{currencySymbol}
									{}
									{currencyDisplay(
										totalAddonPrice
									)}
								</p>
							</>
						);						
					})
				}
				</div>
			</div>
			<div className='columnWrap'>
				<div className='column50'>
					<span>{total_enhancements}</span>
				</div>
				<div className='column50'>
					{currencySymbol}
					{currencyDisplay(
						addonTotalAmtArr.reduce((accumulator, item) => {
							return accumulator + item;
						}, 0)
					)}
				</div>
			</div>
		</>
	);
}
