import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../../../includes/spinner/Spinner";
import { BigAdultChild } from "../Buttons";
import { LocalStorage, SessionStorage } from "../../../utils/storage";
import { DEFAULT_LANG, KEYS } from "../../../utils/constant";
import { useTranslation } from "react-i18next"
import { getDeviceType } from "../../../utils/utils";
import { route } from "../../../utils/route";
import { useLocation, useNavigate } from "react-router";
import AppContext from "../../../utils/appContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  maxWidth: 460,
  minWidth: 460,
  p: 0,
  bgcolor: "#fff",
  textAlign: "left"
};

const RemoveRoomModal = ({
  room_no,
  openRemoveRoom,
  setopenRemoveRoom,
  getRoomList
}) => {
  const { t } = useTranslation()
  const context = useContext(AppContext)
  const handleCloseRemoveRoom = () => {
    setopenRemoveRoom(false);
  };

  let navigate = useNavigate();
  
  let location = useLocation()
  let deviceType = getDeviceType();
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));

  useEffect(() => {
    if (openRemoveRoom) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [openRemoveRoom])

  const removeRooms = () => {
    cartItems.splice((room_no-1), 1); // 2nd parameter means remove one item only
    let updateCurrentRoomSelection = cartItems.length;
    cartItems.length > 0 &&
    cartItems.map((item, index) => {
      cartItems[index].room = index + 1
      if(cartItems[index].room_info.room_id == 0 || cartItems[index].room_info.room_id == '')
      {
        updateCurrentRoomSelection = cartItems[index].room;
      }
    });
    LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, updateCurrentRoomSelection);

    SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems);
    setopenRemoveRoom(false);
    getRoomList();
		if (
			location.pathname === route.checkout ||
			location.pathname == route.roomDetail
		) {
			navigate(route.roomList);
		}
  };

  const changeRooms = () => {
    cartItems[room_no-1].room_info ={"room_id":0, "rate_id":0, "room_name": "", "rate_name":""};
    cartItems[room_no-1].price =[];
    cartItems[room_no-1].enhancement =[];
    SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems);  
    LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, room_no);
    setopenRemoveRoom(false);
    context.setOverlay(false)
    if (location.pathname === route.checkout) {
      navigate(route.roomList)
    }
    
  };
  return (
    <Modal
      open={openRemoveRoom}
      onClose={handleCloseRemoveRoom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addModalPopup removeRoomModalPopup"
      role="dialog"
			aria-modal="true"
    >
      <Box sx={style}>
        <div id="removeRoomModal" class="white-popup-block textCenter">
          <button
            type="button"
            class="mfp-close"
            onClick={() => handleCloseRemoveRoom()}
            aria-label='Close Button'
          >
            Close
          </button>
          <h2>{deviceType === 'mobile' ? t("remove_room") : t("your_stay")}</h2>
          <p>
            {t("would_you_like_to_remove")}
          </p>
          <div class="modalBottomBtn paddingT30">
            <a
              class="customBtn customBtnDark w100"
              href="javascript:void(0);"
              onClick={() => removeRooms()}
            >
              {deviceType === 'mobile' ? t("remove") : t("remove_room")}
            </a>
            <a
              class="customBtn customBtnTransparent w100"
              href="javascript:void(0);"
              onClick={() => changeRooms()}
            >
              {deviceType === 'mobile' ? t("change") : t("change_room")}
            </a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RemoveRoomModal;
