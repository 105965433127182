import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const CommonModal = ({
    open,
    setOpen,
    title = "",
    data,
    width = 700,
    popupClass = "commonModalPopup"
}) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    maxWidth: width,
    minWidth: width,
    // maxHeight: 600,
    // overflowY: "auto",
    p: 0,
    bgcolor: "#fff",
    textAlign: "left",
  }

  const handleClose = () => {
    setOpen(false)
    if(popupClass === 'checkoutPrivacyPolicy commonModalPopup termsncondition')
    {
      var scrollDiv = document.getElementById("termsdata").scrollIntoView({
        behavior: 'smooth'
      });
      //window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [open])

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={popupClass}
        role="dialog"
			  aria-modal="true"
    >
        <Box sx={style}>
          <div className="white-popup-block">          
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
              <button aria-label='Close Button' type="button" className="mfp-close" onClick={() => handleClose()}>Close</button>
            </Typography>
            <div className="contentPopupWrap">
              <div className="contentPopup">
                {data}
              </div>
            </div>
          </div>
        </Box>
    </Modal>
  )
}

export default CommonModal