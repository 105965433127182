import React, { useContext } from "react";
import { getCommonRequest } from "../utils/requestData";
import AppContext from "../utils/appContext";
import { fetchAuthToken } from "../services/auth";
import { useErrorBoundary } from "react-error-boundary";
import UrlConstants from "../../theme1/config/UrlConstants";

export const useAuth = () => {
	const context = useContext(AppContext);
	const { showBoundary } = useErrorBoundary();

	const getAuth = async (doBooking, saveReservation, closeUpMsg, doBookingComma) => {
		let reqObj = getCommonRequest();
		reqObj.site_url = UrlConstants.siteUrl;
		reqObj.referer_page = UrlConstants.siteUrl;
		reqObj.username = "test";
		reqObj.password = "123456";
		try {
			const res = await fetchAuthToken(reqObj);
			if (doBooking) {
              context.setBookingToken(res.token)
			} else if (saveReservation) {
				context.setReservationToken(res.token)	
			} else if (closeUpMsg) {
				context.setCloseUpMsg(res.token)	
			}else if (doBookingComma) {
				context.setCommaBookingToken(res.token)	
			} else {
			  context.setAuthToken(res.token);
			}
		} catch (error) {
			showBoundary(error);
		} finally {
		}
	};

	return {
		getAuth,
	};
};
