import React from "react";
import { useTranslation } from "react-i18next";
export default function SignatureSuiteEnquire({ hotelData, roomDetails }) {
	const { t } = useTranslation();

	let roomPhone =
		roomDetails && roomDetails.room_enq_contact
			? roomDetails.room_enq_contact
			: hotelData?.phone;
	let roomEmail =
		roomDetails && roomDetails.room_enq_mail
			? roomDetails.room_enq_mail
			: hotelData?.email;
	return (
		<div class="availityText">
			<p>{roomDetails.sale_type === "0" ? t("check_availability_text_with_suite") : t("check_availability_text")}:</p>
			<p className='enquiryPopupContact'>
				<span className='dBlock'>
					T. <a href={`tel: ${roomPhone}`}>{roomPhone}</a>
				</span>
				<span className='dBlock'>
					E. <a href={`mailto: ${roomEmail}`}>{roomEmail}</a>
				</span>
			</p>
		</div>
	);
}
