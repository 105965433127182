import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../../../includes/spinner/Spinner";
import { BigAdultChild } from "../Buttons";
import { LocalStorage, SessionStorage } from "../../../utils/storage";
import { DEFAULT_LANG, KEYS } from "../../../utils/constant";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../../../utils/utils";
import { route } from "../../../utils/route";
import { useLocation, useNavigate } from "react-router";
import AppContext from "../../../utils/appContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: 460,
	minWidth: 460,
	p: 0,
	bgcolor: "#fff",
	textAlign: "left",
};

const RemoveSelectedRoomModal = ({
	room_no,
	getRoomList,
	selectedRoomNoToRemove,
	setSelectedRoomNoToRemove,
	openSelectedRemoveRoomModal,
	setOpenSelectedRemoveRoomModal,
}) => {
	const { t } = useTranslation();
	const context = useContext(AppContext);
	const handleCloseRemoveRoom = () => {
		setOpenSelectedRemoveRoomModal(false);
	};

	let navigate = useNavigate();

	let location = useLocation();
	let deviceType = getDeviceType();
	let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));

	const changeRooms = () => {
		cartItems[room_no - 1].room_info = {
			room_id: 0,
			rate_id: 0,
			room_name: "",
			rate_name: "",
		};
		cartItems[room_no - 1].price = [];
		cartItems[room_no - 1].enhancement = [];
		SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems);
		LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, room_no);
		setOpenSelectedRemoveRoomModal(false);
		context.setOverlay(false);
		// if (location.pathname === route.checkout) {
		navigate(route.roomList);
		// }
	};

	useEffect(() => {
		if (openSelectedRemoveRoomModal) {
			document.body.classList.add("bodyScrollHidden");
		} else {
			document.body.classList.remove("bodyScrollHidden");
		}
		return () => {
			document.body.classList.remove("bodyScrollHidden");
		};
	}, [openSelectedRemoveRoomModal]);

	return (
		<Modal
			open={openSelectedRemoveRoomModal}
			onClose={handleCloseRemoveRoom}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			className='addModalPopup removeRoomModalPopup'
			role='dialog'
			aria-modal='true'
		>
			<Box sx={style}>
				<div id='removeRoomModal' class='white-popup-block textCenter'>
					<button
						type='button'
						class='mfp-close'
						onClick={() => handleCloseRemoveRoom()}
						aria-label='Close Button'
					>
						Close
					</button>
					<h2>
						{deviceType === "mobile"
							? t("remove_room")
							: t("your_stay")}
					</h2>
					<p>{t("would_you_like_to_change_this_room")}</p>
					<div class='modalBottomBtn paddingT30'>
						<a
							class='customBtn customBtnDark w100'
							href='javascript:void(0);'
							onClick={() => changeRooms()}
						>
							{deviceType === "mobile"
								? t("change")
								: t("change_room")}
						</a>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default RemoveSelectedRoomModal;
