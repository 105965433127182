import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../../includes/spinner/Spinner";
import { imageUrl, KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import { useTranslation } from "react-i18next";
import { floorPlanUrl } from "../../utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  maxWidth: 1000,
  minWidth: 1000,
  p: 0,
};

const SingleRoomFloorPlan = ({ roomDetails }) => {
  const [openFloorPlan, setOpenFloorPlan] = useState(false);
  const handleFloorplanPopUp = () => {
    setOpenFloorPlan(false);
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (openFloorPlan) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [openFloorPlan])

  return (
		<>
			<a
				className='customBtn customBtnTransparent'
				tabIndex='0'
				href='javascript:void(0);'
				onClick={() => setOpenFloorPlan(true)}
			>
				{t("view_Floorplan")}
			</a>
			<Modal
				open={openFloorPlan}
				onClose={handleFloorplanPopUp}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='floorPlanModalPopup'
			>
				<Box sx={style}>
					<div className='white-popup-block textCenter'>
						<Typography
							id='modal-modal-title'
							variant='h6'
							component='h2'
						>
							{t("floor_Plan")}
							<button
								type='button'
								className='mfp-close'
								onClick={() => handleFloorplanPopUp()}
							>
								&times;
							</button>
						</Typography>
						<img
							className='single-room-floor-plan'
							alt={roomDetails.room_name}
							src={floorPlanUrl(roomDetails)}
						/>
					</div>
				</Box>
			</Modal>
		</>
  );
};

export default SingleRoomFloorPlan;
