import React from 'react'
import { useTranslation } from "react-i18next"

const ViewMoreLess = ({
    showMore,
    setShowMore,
    rateShortDesc
}) => {
    const { t } = useTranslation()
    const shouldHideButton = () => {
        const matchCount = (rateShortDesc.match(/<\/div>/g) || []).length;
        return matchCount <= 2;
      }

  return (
		<>
			{(!shouldHideButton() || rateShortDesc?.length > 50) && (
				<a
					className='viewMore'
					tabIndex='0'
					href='javascript:void(0);'
					onClick={() => setShowMore(!showMore)}
				>
					{showMore ? t("view_less") : t("view_more")}
				</a>
			)}
		</>
  );
}

export default ViewMoreLess