import { useContext, useEffect, useState } from "react";
import { getAddonRequest, getCheckoutRequest } from "../utils/requestData";
import AppContext from "../utils/appContext";
import { fetchCheckout, fetchAddon } from "../services/checkout";
import { useErrorBoundary } from "react-error-boundary";
import { useAuth } from "./useAuth";
import { getThirdPartyPolicy } from "../utils/utils";

export const useCheckout = ({ searchPanelData }) => {
	const context = useContext(AppContext);
	const token = context.authToken;
	const [checkoutData, setCheckoutData] = useState(null);
	const [addonData, setAddonData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const { getAuth } = useAuth()
	let retryAttempted = false;
	const { showBoundary } = useErrorBoundary();

	const getCheckoutDetails = async () => {
		let requestData = getCheckoutRequest();
		requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
		try {
			await setLoading(true);
			await fetchCheckout(requestData, token).then((res) => {
				setCheckoutData(res.data);
			});
		} catch (error) {
			if (error.renewToken && !retryAttempted) {
				getAuth();
			} else {
				showBoundary(error);
			}
		} finally {
			setLoading(false);
		}
	};

	const getAdvanceAddon = async () => {
		let requestData = getAddonRequest();
		try {
			await setLoading2(true);
			await fetchAddon(requestData, token).then((res) => {
				if (res.data) {
					const newData = Object.values(res.data).flatMap(
						(category) => category.addons
					);
					let uni = newData.reduce((unique, o) => {
						
							unique.push(o);
						
						return unique;
					}, []);
					setAddonData(uni);
				}
			});
		} catch (error) {
			if (!error.renewToken) {
				showBoundary(error);		
			}
		} finally {
			setLoading2(false);
		}
	};

	useEffect(() => {
		context.setOverlay(false);
		if (context.authToken && searchPanelData) {
			getCheckoutDetails();
			getAdvanceAddon();
		}
	}, [context.authToken, searchPanelData]);

	return { checkoutData, addonData, loading, loading2 };
};
