import React, { useState, useEffect } from "react";

const WithFooter = (WrappedComponent) => {
  return function WithFooter(props) {
    const [FooterComponent, setFooterComponent] = useState(null);
    const footerID = props?.siteConfig?.site_id || false;
    // const footerID = 31237;
    

    useEffect(() => {
      const FooterComponentLazy = React.lazy(() => {
        return import(
          `../../../../../includes/footers/${footerID}/CustomFooter`
        ).then((module) => ({ default: module.default }));
      });
      setFooterComponent(FooterComponentLazy);
    }, [footerID]);

    return (
      <>
        <WrappedComponent {...props} />
        {FooterComponent && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <FooterComponent siteId={ footerID} />
          </React.Suspense>
        )}
      </>
    );
  };
};

export default WithFooter;
