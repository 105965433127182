import React, { useState, useContext, useEffect } from "react";
import i18n from "i18next";
import Autosuggest from "react-autosuggest";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../utils/appContext";
import usePrevious from "../../../../hooks/usePrevious";
import { useLocation } from "react-router-dom";
import { route } from "../../../../utils/route";
import { getSuggestedCurrencyBasedOnSiteId, langPopupReleaseTabFocusWithinSelection } from "../../../../utils/utils";

export default function LanguagePopup(props) {
	const { t } = useTranslation();
	const location = useLocation();
	const context = useContext(AppContext);

	let config = localStorage.getItem("config")
		? JSON.parse(localStorage.getItem("config"))
		: [];
	let languageList = config.languageList ? config.languageList : [];
	let [selectedCurrency, setSelectedCurrency] = useState(
		localStorage.getItem("selectedCurrency")
	);
	let [selectedLanguage, setSelectedLanguage] = useState(
		localStorage.getItem("lang")
			? localStorage.getItem("lang")
			: config.default_language
	);

	let [value, setValue] = useState("");
	let [suggestions, setSuggestions] = useState([]);

	const oldSelectedCurrency = usePrevious(JSON.stringify(selectedCurrency));
	useEffect(() => {
		if (selectedCurrency !== oldSelectedCurrency) {
			context.setCurrencyData(selectedCurrency);
		}
	}, [selectedCurrency]);

	const oldSelectedLanguage = usePrevious(selectedLanguage); // get previous selected language
	useEffect(() => {
		// Add selected language in global context
		if (selectedLanguage !== oldSelectedLanguage) {
			context.setLanguageData(selectedLanguage);
		}
	}, [selectedLanguage]);

	let onChange = (event, { newValue }) => {
		setValue(newValue);
	};
	let onSuggestionsFetchRequested = ({ value }) => {
		let getValue = getSuggestions(value);
		setSuggestions(getValue);
	};

	let onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const setLanguage = (languageCode) => {
		setSelectedLanguage(languageCode);
		localStorage.setItem("lang", languageCode);
		i18n.changeLanguage(languageCode);
		document.documentElement.setAttribute("lang", languageCode);
		props.changeUserLang(languageCode);
		document.getElementById("languagePopup").style.display = "none";
		langPopupReleaseTabFocusWithinSelection();
	};
	let ChangeCurrency = (abbr, value) => {
		let cartItems = sessionStorage.getItem("cartItems")
			? JSON.parse(sessionStorage.getItem("cartItems"))
			: [];
		let oldCurrency = localStorage.getItem("selectedCurrency");
		sessionStorage.setItem("previousCurrency", oldCurrency);
		sessionStorage.setItem("newCurrency", abbr);
		localStorage.setItem("selectedCurrency", abbr);
		props.changeLanguage(abbr);
		setSelectedCurrency(abbr);
		document.getElementById("languagePopup").style.display = "none";
		let newCartItem = {};
		let newbaseCurrency = [];
		let newCurrencyValue = 1;
		let oldbaseCurrency = [];
		let oldCurrencyValue = 1;
		newbaseCurrency =
			props.currencyList.all_currency.length > 0 &&
			props.currencyList.all_currency.filter(
				(item) => item.abbrevation === abbr
			);

		newCurrencyValue = newbaseCurrency ? newbaseCurrency[0].value : "";

		oldbaseCurrency =
			props.currencyList.all_currency.length > 0 &&
			props.currencyList.all_currency.filter(
				(item) => item.abbrevation === oldCurrency
			);
		oldCurrencyValue = oldbaseCurrency ? oldbaseCurrency[0].value : "";
		cartItems.length > 0 &&
			cartItems.map((data) => {
				let updatedPrice = parseFloat(
					(data.price / oldCurrencyValue) * newCurrencyValue
				).toFixed(2);
				data.price = updatedPrice;
				return data;
			});
		sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
		langPopupReleaseTabFocusWithinSelection();
	};
	const getSuggestions = (value) => {
		langPopupReleaseTabFocusWithinSelection();
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: props.currencyList.all_currency.filter(
					(lang) =>
						lang.title.toLowerCase().slice(0, inputLength) ===
						inputValue
			  );
	};

	const getSuggestionValue = (suggestion) => suggestion.title;
	const renderSuggestion = (suggestion) => (
		<div className='searchTextPlace'>{suggestion.title}</div>
	);

	let onSuggestionSelected = () => {
		ChangeCurrency(suggestions[0].abbrevation, "");
	};

	let displayClass = languageList.length > 1 ? "" : "mui--is-active";
	const inputProps = {
		placeholder: t("search_currencies"),
		value,
		onChange: onChange,
		name: "searchCurrencies",
		title: t("search_currencies_title"),
	};
	const renderInputComponent = (inputProps) => (
		<>
			<label for='searchCurrencies'>{t("search_currencies")}</label>
			<input {...inputProps} />
		</>
	);

	let showLangContent = () => {
		document.getElementById("panel-events-2").style.display = "block";
		document.getElementById("event-panel-1").className = "";

		if (languageList.length > 1) {
			document.getElementById("event-panel-2").className = "mui--is-active";
			document.getElementById("panel-events-1").style.display = "none";
		}
	};

	let showCurrencyContent = () => {
		document.getElementById("panel-events-2").style.display = "none";
		document.getElementById("event-panel-2").className = "";
		
		if (languageList.length > 1) {
			document.getElementById("event-panel-1").className = "mui--is-active";
			document.getElementById("panel-events-1").style.display = "block";
		}
	};
	let currencyArray =
		props.currencyList.all_currency &&
		props.currencyList.all_currency.length > 0
			? props.currencyList.all_currency
			: [];

	if (
		selectedLanguage !== "en" &&
		props.currencyList.multilingual_currency &&
		props.currencyList.multilingual_currency.length > 0
	) {
		currencyArray = props.currencyList.multilingual_currency;
	}
	let allCurrency =
		currencyArray.length > 0 &&
		currencyArray.filter((currencyItem) => currencyItem.ordering === 0);

	let siteId = config.site_id ? config.site_id : 31235;
	let suggestedCurrency = getSuggestedCurrencyBasedOnSiteId(
		siteId,
		currencyArray
	);

	return (
		<div
			className='language_popup textLeft'
			id='languagePopup'
			style={{ display: "none" }}
			role='dialog'
			aria-modal='true'
			aria-labelledby='modal-modal-title'
		>
			<div className='pop_inner langPopupWrap' id='langPopupWrapper'>
				<div className='popUpHeader'>
					<ul className='mui-tabs__bar'>
						{languageList.length > 1 && (
							<li className='mui--is-active' id='event-panel-1'>
								<a
									data-mui-toggle='tab'
									data-mui-controls='panel-events-1'
									onClick={() => showCurrencyContent()}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											showCurrencyContent();
										}
									}}
									tabIndex='0'
								>
									{t("languages")}
								</a>
							</li>
						)}
						{location.pathname !== route.manageBookings ? (
							<li
								className={`${displayClass}`}
								id='event-panel-2'
							>
								<a
									data-mui-toggle='tab'
									data-mui-controls='panel-events-2'
									onClick={() => showLangContent()}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											showLangContent();
										}
									}}
									tabIndex='0'
								>
									{t("currency")}
								</a>
							</li>
						) : (
							""
						)}
					</ul>

					<a
						title='Close'
						href='javascript:void(0)'
						className='close'
						onClick={() => props.onCloseCart()}
						tabIndex='0'
					>
						<span className='hideTag'>Close</span>
					</a>
				</div>
				<div className='manage_popup lang_region'>
					{languageList.length > 1 && (
						<div
							className={`mui-tabs__pane mui--is-active`}
							id='panel-events-1'
						>
							<ul className='suggested'>
								{languageList.map((languageData, index) => {
									return (
										<li
											tabIndex='0'
											key={index}
											className={
												selectedLanguage ===
												languageData.abbrevation
													? "active"
													: ""
											}
											onClick={() =>
												setLanguage(
													languageData.abbrevation
												)
											}
											onKeyPress={(event) => {
												event.preventDefault();

												if (event.key === "Enter") {
													setLanguage(
														languageData.abbrevation
													);
												}
											}}
										>
											<strong>
												{languageData.title_translated
													? languageData.title_translated
													: languageData.title}
											</strong>
										</li>
									);
								})}
							</ul>
						</div>
					)}

					<div
						className={`mui-tabs__pane ${displayClass}`}
						id='panel-events-2'
					>
						<Autosuggest
							suggestions={suggestions}
							onSuggestionsFetchRequested={
								onSuggestionsFetchRequested
							}
							onSuggestionsClearRequested={
								onSuggestionsClearRequested
							}
							getSuggestionValue={getSuggestionValue}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onSuggestionSelected}
							inputProps={inputProps}
							renderInputComponent={renderInputComponent}
						/>
						<h4 style={{ display: "flex" }}>
							{t("suggested_currency")}
						</h4>
						<ul>
							{currencyArray.length > 0 &&
								suggestedCurrency.map((currencyData, index) => {
									return (
										<li
											tabIndex='0'
											onClick={() =>
												ChangeCurrency(
													currencyData.abbrevation,
													currencyData.value
												)
											}
											onKeyPress={(event) => {
												if (event.key === "Enter") {
													ChangeCurrency(
														currencyData.abbrevation,
														currencyData.value
													);
												}
											}}
											key={index}
											className={
												selectedCurrency ===
												currencyData.abbrevation
													? "active"
													: ""
											}
										>
											<strong>
												{currencyData.ctitle_lang
													? currencyData.ctitle_lang
													: currencyData.title}
											</strong>
											<span>
												{currencyData.abbrevation}
											</span>
										</li>
									);
								})}
						</ul>
						<h4 style={{ display: "flex" }}>{t("all_currency")}</h4>
						<ul>
							{currencyArray.length > 0 &&
								allCurrency.map((currencyData, index) => {
									return (
										<li
											tabIndex='0'
											onClick={() =>
												ChangeCurrency(
													currencyData.abbrevation,
													currencyData.value
												)
											}
											onKeyPress={(event) => {
												if (event.key === "Enter") {
													ChangeCurrency(
														currencyData.abbrevation,
														currencyData.value
													);
												}
											}}
											key={index}
											className={
												selectedCurrency ===
												currencyData.abbrevation
													? "active"
													: ""
											}
										>
											<strong>
												{currencyData.ctitle_lang
													? currencyData.ctitle_lang
													: currencyData.title}
											</strong>
											<span>
												{currencyData.abbrevation}
											</span>
										</li>
									);
								})}
						</ul>
					</div>
				</div>
			</div>
			<div
				className='closeCurrencyPopup'
				onClick={() => props.onCloseCart()}
				tabIndex='0'
			></div>
		</div>
	);
}
