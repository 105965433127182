import axios from 'axios';
import qs from 'qs';
import { baseUrl } from './constant';
import { LocalStorage } from './storage';
import { generateToken } from './utils';
const GET = 'GET';
const DELETE = 'DELETE';
const POST = 'POST';
const PUT = 'PUT';

const ACTION_HANDLERS = {
  [GET]: (url, data) => {
    let queryUrl = url;
    if (data !== undefined) {
      const query = qs.stringify(data);
      queryUrl = `${queryUrl}?${query}`;
    }
    return axios.get(baseUrl + queryUrl);
  },
  [DELETE]: (url, data) => axios.delete(baseUrl + url, { data }),
  [POST]: (url, data) => axios.post(baseUrl + url, data),
  [PUT]: (url, data) => axios.put(baseUrl + url, data),
};

export const setHeaders = (contentType, authenticate, authToken) => {
  if (authenticate) {
    const token = authToken;
    if (token) {
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }
  axios.defaults.headers.common.accessCode = generateToken();
  if (contentType) {
    axios.defaults.headers.post['Content-Type'] = contentType;
    axios.defaults.headers.post.Accept = 'application/json';
  } else {
    delete axios.defaults.headers.post['Content-Type'];
  }
};

export const ShowErrorAsToast = (error) => {
  if (window?.location?.pathname === "/cancelconfirmation" && (error?.code === "ERR_NETWORK" || error?.response?.status === 500)) {
    return Promise.reject("Network Error");      
  }
  if (error.response) {
    const value = error.response.data;
    if (Object.prototype.hasOwnProperty.call(value, 'errors') && value.message === undefined) {
      const { errors } = value;
      let messages = [];
      Object.keys(errors).forEach((x) => {
        Object.keys(errors).forEach((y) => {
          messages.push(errors[x][y]);
        });
      });
    } else if (value.message !== undefined) {
      // console.log(value.message)
    }
    if (error.response.status === 401 && error.response.data === "Unauthorized.") {
      throw {
        renewToken: true
      }
    }
  } else {
    // console.log("Something went wrong, please do try again.!")
  }
  return Promise.reject(error.response.data.message);
};

export const fetchUrl = (type, url, data, authToken, authenticate = true, fetchBaseResponse = false, contentType) => {
  setHeaders(contentType, authenticate, authToken);
  const handler = ACTION_HANDLERS[type.toUpperCase()];
  return (!fetchBaseResponse ? handler(url, data)
    .then(res => Promise.resolve(res.data))
    .catch(error => ShowErrorAsToast(error))
    : handler(url, data)
      .catch(error => ShowErrorAsToast(error))
  );
};

