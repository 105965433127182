import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { route } from "../utils/route";
import CancelConfirmationComponent from "../components/cancel/";

const CancelConfirmation = ({
	currencyList,
	authToken,
	searchPanelData,
	siteConfig,
	ip,
	bookingData,
}) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!location?.state?.id) {
			navigate(route.cancelConfirmation);
		}

		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}, 100); // Scroll to top of the page
	}, []);

	return (
		<CancelConfirmationComponent
			currencyList={currencyList}
			authToken={authToken}
			searchPanelData={searchPanelData}
			siteConfig={siteConfig}
			ip={ip}
			bookingData={location?.state?.bookingData}
		/>
	);
};

export default CancelConfirmation;
