import React from 'react'
import { BigAdultChild } from '../common/Buttons'
import { useTranslation } from "react-i18next"

const RoomUpdate = ({
    adults,
    children,
    setAdultCount,
    setChildCount,
    siteConfig,
}) => {
    const { t } = useTranslation()
  return (
    <>
        <div className="columnWrap alignItemsCenter marginB15">
            <div className="column30">{t("adults")}</div>
            <div className="column70">
                <BigAdultChild
                    defaultValue={adults}
                    type="adult"
                    minValue="1"
                    maxValue={siteConfig.max_adult}
                    roomNumber={1}
                    setcount={setAdultCount}
                />
            </div>
        </div>
        <div className="columnWrap alignItemsCenter">
            <div className="column30">{t("children")}</div>
            <div className="column70">
                <BigAdultChild
                    defaultValue={children}
                    type="children"
                    minValue="0"
                    maxValue={siteConfig.max_child}
                    roomNumber={1}
                    setcount={setChildCount}
                />
            </div>
        </div>
    </>
  )
}

export default RoomUpdate