import { useEffect } from "react";
import { getSiteContactDetails } from "../utils/utils";

export default function useFavicon({ siteId }) {
	const getFaviconElement = () => {
		return document.getElementById("favicon"); // get element by id
	};
	const faviconUrl = getSiteContactDetails(siteId)?.favicon;

	const fetchFaviconImage = async () => {
		try {
			const favicon = getFaviconElement(); // Accessing favicon element
			favicon.href = faviconUrl; // Update favicon href prop
		} catch (error) {
			console.log("Favicon error");
		}
	};

	useEffect(() => {
		fetchFaviconImage();
	}, []);

	return {  };
}
