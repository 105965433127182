import React, { useContext, useState } from "react";
import AppContext from "../../utils/appContext";
import Slider from "react-slick";
import Loader from "../common/Loader";

const SliderList = ({
	imageArray,
	videoArray,
	imageName,
	setOpen = () => false,
	isPlaying,
	setIsPlaying,
	fromPopUp
}) => {
	let context = useContext(AppContext);
	const [videoKey, setVideoKey] = useState(0);
	const [autoplay, setAutoPlay] = useState('false');
	// Function to re-render the video tag
	const reRenderVideo = () => {
		setVideoKey((prevKey) => prevKey + 1);
	};
	const settings = {
		initialSlide: context.activeSlide, // set slide no, which needs to open first
		lazyLoad: true,
		// onLazyLoad: slidesLoaded => {console.log('slideLoaded')},
		beforeChange: () => {
			setIsPlaying(true);
			setAutoPlay(true);
			reRenderVideo();
		},
	};
	const openFullImage = (index, mediaType) => {
		setOpen(true);
		context.setActiveSlide(index); // add index of slide no in context var
	};

	let combineArr = [...imageArray, ...videoArray];
	combineArr = combineArr.filter((item) => item !== undefined);

	const [mediaLoaded, setMediaLoaded] = useState(false);
	const handleMediaLoad = () => {
		setMediaLoaded(true);
	};
	const [width, setWidth] = useState(window.innerWidth);
	const isMobile = width <= 767;

	const donoting = () => {
		return false;
	}
	return (
		<Slider {...settings}>
			{combineArr?.map((item, index) => {
				let checkImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(item);
				let mediaType = "video";
				if (checkImage) {
					let props = index === 0 ? { tabIndex: 0 } : {};
					mediaType = "image";
					return (
						<div className='posRelative' key={index}>
							{!mediaLoaded && <Loader />}
							<span
								role='button'
								// aria-label="Enlarge Image"
								className={`dBlock fullImgModal ${
									index !== 0 ? "innerImage" : ""
								}`}
								onClick={() => openFullImage(index, mediaType)}
								onKeyPress={(event) => {
									event.preventDefault();
									if (event.key === "Enter") {
										openFullImage(index, mediaType);
									}
								}}
								{...props}
							>
								<img
									src={item}
									alt={`${imageName} ${index + 1}`}
									onLoad={handleMediaLoad}
									title='Enlarge Image'
									className={index !== 0 ? "innerImage" : ""}
								/>
							</span>
						</div>
					);
				} else {
					return (
						<div className='posRelative' key={index} tabIndex='-1'>
							{!mediaLoaded && <Loader />}
							<span
								className={`dBlock fullImgModal ${
									index !== 0 ? "innerImage" : ""
								}`}
								onClick={() => openFullImage(index, mediaType)}
								onKeyPress={(event) => {
									event.preventDefault();
									if (event.key === "Enter") {
										openFullImage(index, mediaType);
									}
								}}
							>
								<div style={{ width: "100%", height: "400px" }}>
									<video
										key={videoKey}
										style={{
											width: "100%",
											height: "100%",
										}}
										preload='auto'
										controlsList='nofullscreen nodownload noremoteplayback'
										autoPlay={autoplay}
										muted
										controls
										loop
										playsInline='playsinline'
										webkit-playsinline='true'
										x5-playsinline='true'
										tabIndex='-1'
										onLoadedData={handleMediaLoad}
										onClick={e => {fromPopUp === false ? e.target.pause() : donoting()}}
									>
										<source src={item} type='video/mp4' />
									</video>
								</div>
							</span>
						</div>
					);
				}
			})}
		</Slider>
	);
};
export default SliderList;
