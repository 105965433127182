import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import CommonModal from "../Modal/commonModal";
export default function PrivacyPolicy({ siteConfig }) {
	// console.log('props -> ' + JSON.stringify(props));
	const { t } = useTranslation();
	const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);
	String.prototype.stripSlashes = function () {
		return this.replace(/\\(.)/gm, "$1");
	};
	return (
		<>
			{/* <button
				className='customBtn customBtnTransparent w100'
				onClick={() => {
					setPrivacyPolicyModalOpen(true);
				}}
			>
				{t("Privacy Policy")}
			</button> */}
			<CommonModal
				popupClass='privacyPolicy commonModalPopup'
				title={t("privacy_policy")}
				open={privacyPolicyModalOpen}
				setOpen={setPrivacyPolicyModalOpen}
				width='800px'
				data={
					siteConfig?.privacy_policy &&
					parse(siteConfig?.privacy_policy?.stripSlashes())
				}
			/>
			{/* <CookieConsent
				location='bottom'
				buttonText={t("accept_close")}
				cookieName='CookieConsent'
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
				overlay
			>
				{t("we_use_cookies")}
				<span>
					<button
						className='customBtn privacy-policy'
						onClick={() => {
							setPrivacyPolicyModalOpen(true);
						}}
					>
						{t("Privacy Policy")}
					</button>
				</span>
			</CookieConsent> */}
		</>
	);
}
