export default function ReservationDetailsSummary({
	strTitle,
	strCRSConfirmationNo,
	crsConfirmationNo,
}) {
	return (
		<div className='columnWrap cartSidebarRow sidebarRoomList paddingTB20'>
			<div className='column100 pL0 pR0'>
				<label className='dBlock textUppercase'>{strTitle}</label>
			</div>
			{/* <div
				className='column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw stayReserSummry'
			>
				<div className='productName'>{strCRSConfirmationNo}</div>
				<span className='dInlineBlock'>{crsConfirmationNo}</span>
			</div> */}
		</div>
	);
}
