import React, { useState, useEffect } from "react";
// import "./theme1/styles/mainibe.css";
// import "./theme1/styles/responsive.css";
import "./locales/i18n";
import { BrowserRouter } from "react-router-dom";
import APIUtil from "./theme1/config/APIUtil";
import UrlConstants from "./theme1/config/UrlConstants";
import {
  getNextDate,
  getCurrentDate,
  getCommonRequest,
} from "./theme1/config/Utils";
import MbhRoutes from "./mbh/utils/routes";
import { useTranslation } from "react-i18next";
import Loader from "./mbh/components/common/Loader";
import PrivacyPolicy from "./mbh/components/common/PrivacyPolicy";
import Gtm from "./mbh/components/common/Social/Gtm";
import { getCurrencyHotelWise, renderAudioEyeCode, setOffsetChange, renderpingdomscript } from "./mbh/utils/utils";

function App() {
  let roomData =
    '[{"room":1,"adults":1,"childrens":[],"price":[],"room_info":{"room_id":0, "rate_id":0, "room_name": "", "rate_name":""}}]';
  let token = "";
  let [hotelList, setHotelList] = useState([]);
  let [currencyList, setcurrencyList] = useState([]);
  let [authToken, setauthToken] = useState("");
  let [searchPanelData, setSearchPanelData] = useState("");
  let [siteConfig, setSiteConfig] = useState("");
  let [ip, setip] = useState("");
  let ipAddress = "";
  const { t } = useTranslation();

  if (!sessionStorage.getItem("roomData")) {
    sessionStorage.setItem("roomData", roomData);
  }

  if (!sessionStorage.getItem("checkInDate")) {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let checkInDate = getCurrentDate();//formatDate(date);
    let checkoutDate = getNextDate();
    sessionStorage.setItem("checkInDate", checkInDate);
    sessionStorage.setItem("checkoutDate", checkoutDate);
  }

  useEffect(() => {
    window.addEventListener('load', () => {
      document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');
      document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
    });
  }, [])


  useEffect(() => {
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');
      document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
    });
  }, [])

  const getIpAddress = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        if (data?.ip) {
          ipAddress = data.ip;
          setip(data.ip)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getIpAddress()
    getAuth();
  }, []);

  useEffect(() => {
    if (authToken && (ip || ipAddress)) {
      getConfigData(ip || ipAddress);
    }
  }, [ip, authToken])

  let getAuth = async () => {
    let reqObj = getCommonRequest();
    reqObj.site_url = UrlConstants.siteUrl;
    reqObj.referer_page = UrlConstants.siteUrl;
    reqObj.username = "test";
    reqObj.password = "123456";
    APIUtil.postMethodwithoutauth(UrlConstants.authUrl, reqObj)
      .then(async (response) => {
        await setauthToken(response.data.token);
        token = response.data.token;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  let getConfigData = (ipAddress) => {
    let reqObj = getCommonRequest();
    reqObj.site_url = UrlConstants.siteUrl;
    reqObj.referer_page = UrlConstants.siteUrl;
    reqObj.user_ip = ipAddress;
    reqObj.ip = ipAddress;
    APIUtil.postMethod(UrlConstants.configUrl, reqObj, authToken)
      .then((response) => {
        localStorage.setItem("config", JSON.stringify(response.data.config));
        if (!localStorage.getItem("selectedCurrency")) {
          // if (response.data.config.user_currency_abbreviation !== undefined) {
          //   localStorage.setItem(
          //     "selectedCurrency",
          //     response.data.config.user_currency_abbreviation
          //   );
          // } else {
          localStorage.setItem("selectedCurrency", getCurrencyHotelWise(response.data.config.site_id));
          // }
        }
        setSiteConfig(response.data.config);
        getHotelList(response.data.config.site_id);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  let getHotelList = (siteId) => {
    let searchHotelObj = getCommonRequest();
    searchHotelObj.site_id = siteId;
    searchHotelObj.hotel_dropdown = "0";
    searchHotelObj.allow_region = "0";
    searchHotelObj.language = localStorage.getItem("i18nextLng") || "en";
    searchHotelObj.agent_id = "0";
    searchHotelObj.user_ip = ip;
    searchHotelObj.ip = ip;
    APIUtil.postMethod(UrlConstants.hotelListUrl, searchHotelObj, authToken)
      .then((hotelResponse) => {
        if (hotelResponse.data.response_type === "success") {
          if (hotelResponse?.data?.hotel?.[0]?.check_in_min_date) {
            setOffsetChange(hotelResponse.data.hotel[0].check_in_min_date); // set booking offset days
          }
          setHotelList(hotelResponse.data.hotel);
          if (hotelResponse.data.hotel && hotelResponse.data.hotel.length > 0) {
            sessionStorage.setItem("hotelId", hotelResponse.data.hotel[0].hotel_id);
            setSearchPanelData(hotelResponse.data);
          }
          getCurrencyList();
        } else {
          // console.log("Hotel List API Error");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  let getCurrencyList = () => {
    let currencyObject = getCommonRequest();
    currencyObject.platform = "web";
    currencyObject.referer_page = UrlConstants.siteUrl;
    currencyObject.user_ip = ip;
    currencyObject.ip = ip;
    currencyObject.default_language = localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en";
    APIUtil.postMethod(UrlConstants.currencyUrl, currencyObject, authToken)
      .then((currencyResponse) => {
        if (currencyResponse.data.response_type === "success") {
          setcurrencyList(currencyResponse.data.data);
          // setSpinner(false);
        } else {
          // console.log("Currency List API Error");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  document.documentElement.setAttribute(
    "lang",
    localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en"
  );

  // render 'Audio Eye' code
  useEffect(() => {
    siteConfig?.site_id && renderAudioEyeCode(siteConfig?.site_id)
  }, [siteConfig?.site_id])

  // render 'Ping dom' code
  useEffect(() => {
    siteConfig?.site_id && renderpingdomscript(siteConfig?.site_id)
  }, [siteConfig?.site_id])


  return (
    <div className='App' data-version={UrlConstants.BuildVersion}>
      {!searchPanelData && <Loader />}
      {siteConfig?.site_id && (
        <>
          <BrowserRouter>
            {(siteConfig.google_tag_head_code != 0 && siteConfig.google_tag_head_code != '') ? <Gtm siteConfig={siteConfig} /> : <></>}
            <MbhRoutes
              hotelList={hotelList}
              currencyList={currencyList}
              authToken={authToken}
              searchPanelData={searchPanelData}
              siteConfig={siteConfig}
              ip={ip}
            />
          </BrowserRouter>
          <PrivacyPolicy siteConfig={siteConfig} />
        </>
      )}
    </div>
  );
}

export default App;
