import React from "react";
import { useCancelReservationHook } from "../../hooks/useCancelReservationHook";
import Spinner from "../../../includes/spinner/Spinner";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/route";
import Loader from "../common/Loader";
import { currencyDisplay, getSiteContactDetails } from "../../utils/utils";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import { KEYS } from "../../utils/constant";

export default function CancelConfirmationComponent({
	authToken,
	siteConfig,
	searchPanelData,
	bookingData,
}) {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const { loading, setLoading, cancelReservationData } =
		useCancelReservationHook({
			authToken,
			siteConfig,
			searchPanelData,
			bookingData,
		});

	var adult_count = cancelReservationData?.adult_count;
	var adult_count_text =
		adult_count <= 1
			? `${adult_count} ${t("adult")}`
			: `${adult_count} ${t("adults")}`;
	var child_count = cancelReservationData?.child_count;
	var child_count_text =
		child_count <= 1
			? `${child_count} ${t("child")}`
			: `${child_count} ${t("children")}`;
	var currencySign = getSymbolFromCurrency(
		cancelReservationData?.currency_abbreviation
	);
	var cancellationChargesWithString =
		cancelReservationData?.cancellationCharges === 0
			? t("free")
			: currencySign +
			currencyDisplay(parseFloat(cancelReservationData?.cancellationCharges));
	var cancellationPolicy =
		cancelReservationData?.policy?.cancellation?.summary;

	SessionStorage.set("is_reservation", "");
	SessionStorage.set("is_modification", "");
	const hotelEmail = LocalStorage.get(KEYS.HOTEL_EMAIL) ? LocalStorage.get(KEYS.HOTEL_EMAIL) : getSiteContactDetails(siteConfig?.site_id).email;
	const hotelPhone = LocalStorage.get(KEYS.HOTEL_PHONE) ? LocalStorage.get(KEYS.HOTEL_PHONE) : getSiteContactDetails(siteConfig?.site_id).contactNo;

	return (
		<main className='mainContent cancelPage'>
			{loading && <Loader />}

			<section className='onlyMessageTopWrap paddingTB100 textCenter'>
				<div className='columnContainer'>
				    <div className='onlyMessageTop cancelConfirmOrder'>
						{(cancelReservationData?.original?.CannotBeCancelled || !cancelReservationData?.status) ? (
							<section className='onlyMessageTopWrap paddingTB100 textCenter'>
								<div className='columnContainer'>
										<p className="cancelConfirmOrderMsg">{t("we_apologise_reservation")}</p>
										<p className="enquiryPopupContact mB0">
											<span className="dBlock">{t("please_contact_us_by_phone_or_email")}:</span>
											<span className="dBlock cancelConfirmOrderPh"><a href={`tel: ${hotelPhone}`}>{hotelPhone}</a></span>
											<span className="dBlock cancelConfirmOrderEm"><a href={`mailto: ${hotelEmail}`}>{hotelEmail}</a></span>
										</p>
										<div className="modalBottomBtn paddingT40">
											<a
												className="customBtn customBtnDark"
												href='javascript:void(0);'
												onClick={() => navigate(route.roomList)}
											>{t("ok")}</a>
										</div>
								</div>
							</section>
						) : (
							<>
								<span className='dBlock textUppercase'>
									{t("booking_cancelled")}
								</span>
								<h1 className='m0 paddingT20 paddingB30'>
									{t("sorry_to_see_you_go").replace("[hotelname]", searchPanelData?.hotel?.[0]?.hotel_name)}
								</h1>
							</>
						)}
					</div>
				</div>
			</section>
			{(!cancelReservationData?.original?.CannotBeCancelled && cancelReservationData?.status) ?
			<section className='bookingInfoColumn paddingTB60'>
				<div className='columnContainer'>
					<div className='columnWrap justifyContentCenter'>
						<div className='column40'>
							{cancelReservationData?.cancellation_number ? (
								<ul>
									<li>
										<div className='columnWrap'>
											<div className='column100'>
												<span className='dBlock textUppercase'>
													{t("reservation_details")}
												</span>
											</div>
										</div>
										<div className='bookingDetailInfoWrap'>
											<div className='columnWrap'>
												<div className='column50'>
													{
														cancelReservationData?.room_name
													}
													<br />
													<span>
														{
															cancelReservationData?.rate_name?.replace(/\\'/g, "'")
														}
														<br />
														{adult_count_text}
														{child_count > 0
															? (", " + child_count_text)
															: ""}
													</span>
												</div>
												<div className='column50 booklastColumn50'>
													{currencySign}
													{currencyDisplay(
														cancelReservationData?.total_cost
													)}
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className='columnWrap'>
											<div className='column100'>
												<span className='dBlock textUppercase'>
													{t("cancellation_policy")}
												</span>
											</div>
										</div>
										<div className='columnWrap'>
											<div className='column100'>
												{cancellationPolicy}
											</div>
										</div>
									</li>
									<li>
										<div className='columnWrap'>
											<div className='column100'>
												<span className='dBlock textUppercase'>
													{t("charges")}
												</span>
											</div>
										</div>
										<div className='bookingDetailInfoWrap'>
											<div className='columnWrap'>
												<div className='column50'>
													{t("cancellation_fee")}
												</div>
												<div className='column50 booklastColumn50'>
													{
														cancellationChargesWithString
													}
												</div>
											</div>
										</div>
									</li>
									<li className='bookingInfoTotal'>
										<div className='columnWrap'>
											<div className='column50'>
												<span className='dBlock textUppercase'>
													{t("total")}
												</span>
											</div>
											<div className='column50 booklastColumn50'>
												{currencySign}
												{currencyDisplay(
													cancelReservationData?.cancellationCharges
												)}
											</div>
										</div>
									</li>
								</ul>
							) : (
								""
							)}
							<div className='backButton paddingT50 textCenter'>
								<a
									className='customBtn customBtnDark'
									href='javascript:void(0);'
									onClick={() => navigate(route.roomList)}
								>
									{t("return_home")}
								</a>
							</div>
						</div>
					</div>
				</div>
			</section> : ""}
		</main>
	);
}
