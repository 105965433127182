import React from 'react'

const Checkbox = ({
    id = "",
    text = "",
    rest,
    isChecked,
    error
}) => {
  return (
    <>
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          {...rest}
        />
        <label for={id}>
            {text}
        </label>
        {error && <p className='errorMessage'>{error}</p>}
    </>
  )
}

export default Checkbox