import React, { useEffect, useState } from 'react'
import LanguageIcon from "./../../../../styles/images/language-icon.svg"
import LanguagePopup from './languagePopup';
import {langPopupReleaseTabFocusWithinSelection, langPopupTrapTabFocusWithinSelection } from '../../../../utils/utils';

const Language = ({ currencyList }) => {
	const [showLangPopUp, setLangPopUp] = useState("none");
	const [selectedCurrency, setSelectedCurrency] = useState(
		localStorage.getItem("selectedCurrency")
	);
	const [selectedLanguage, setSelectedLanguage] = useState(
		localStorage.getItem("lang")
	);

	const showLanguageIcon = () => {
		setLangPopUp(!showLangPopUp);
		document.getElementById("languagePopup").style.display = "none";
		langPopupReleaseTabFocusWithinSelection();
	};

	const displayLang = () => {
		document.getElementById("languagePopup").style.display = "block";
		langPopupTrapTabFocusWithinSelection();
	};

	const handleKeyDown = (event) => {
		if(event.key === 'Enter'){
			displayLang();
		}
	}

	return (
		<div className='column15 languageGlobal textRight'>
			<div className='languageIcon dInlineBlock'>
				<img
					id='languageBtn'
					src={LanguageIcon}
					alt='Language'
					onClick={() => displayLang()}
					onKeyDown={handleKeyDown}
					width='24'
					height='24'
					tabindex='0'
				/>
			</div>
			<LanguagePopup
				currencyList={currencyList}
				display={showLangPopUp}
				onCloseCart={() => showLanguageIcon()}
				changeLanguage={(value) => setSelectedCurrency(value)}
				changeUserLang={(value) => setSelectedLanguage(value)}
			/>
		</div>
	);
}

export default Language