import React, { useContext, useState ,useMemo} from "react";
import {
  capitalizeStr,
  dayDiff,
  isTaxInclusiveInCartSingleItem,
  setTaxInclusiveInSession
} from "../../utils/utils";
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import { currencyDisplay } from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import AddtoCart from "./../common/Buttons/addtoCart";
import PolicyModal from "../common/Modal/policyModal";
import ViewDescription from "./viewDescription";
import ViewMoreLess from "./viewMoreLess";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/appContext";
import {SITE_ID_LIST_HASH} from "../../utils/utils";

const RateDetails = ({ index, rate, room, setIsCartShown, hotelData }) => {
  const { t } = useTranslation();
  const context = useContext(AppContext)
  const [showMore, setShowMore] = useState(false);
  const [openPolicy, setOpenPolicy] = useState(false);
  let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let occKey = "";
  let totalPrice = 0;
  let averagePrice = 0;

  if(currentRoom) {
	let singleRoom = cartItems.find(item => item.room == currentRoom)
    occKey = singleRoom?.adults+ "-" + singleRoom?.childrens?.length + "i0";
    if (rate?.datewiserates && occKey && rate?.datewiserates?.hasOwnProperty(occKey)) {
      for (const [key, value] of Object.entries(rate?.datewiserates[occKey])) {
        totalPrice += value;
      }
    }
  }

  averagePrice = totalPrice / dayDiff();

  return (
		<div className='singleRoomOffers'>
			{room?.room_name && index === 0 ? (
				<h3>{t("select_offer_for")} {t("room")}{" "}{LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM)}</h3>
			) : ""}
			<div className={showMore === true ? "roomRatesInfo arrowUp" : "roomRatesInfo"}>
				<div className='columnWrap'>
					<div className='column100'>
						<h3 onClick={() => setShowMore(!showMore)}>
							{rate?.rate_name ? capitalizeStr(rate.rate_name).replace(/\\'/g, "'") : ""}
						</h3>
						<div className='roomPriceMobile'>
						<div>
						<span>{t("average_nightly_rate")}</span>
						{" "}
						<span className='roomPrice'>
							{LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) ?
							getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY) +" "}
							{rate?.occupancies_price?.[0]?.price && currencyDisplay(averagePrice)}
						</span>
					</div>
						</div>
					</div>
					<div className='column30 singleRoomRatesInfoUl'>
						<ViewDescription
							showMore={showMore}
							rateShortDesc={rate?.rate_short_desc}
						/>
					</div>
					<div className='column20'>
						<span className='roomPrice dBlock'>
							{LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) ?
							getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY) +" "}
							{rate?.occupancies_price?.[0]?.price && currencyDisplay(averagePrice)}
						</span>
						{t("average_nightly_rate")}
						{" "}
						<span>
						   ({SITE_ID_LIST_HASH.mbh?.includes(context.configData?.site_id?.toString())?t("mbh_total_price_helper_text"):hotelData?.showinclusivetext === 1 ? `${t("inclusive_of_vat_sm")}` : t("excl_taxes_and_fees")})
						</span>
					</div>
					<div className='column20'>
						<span className='roomPrice dBlock'>
							{LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) ? 
							getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY) +" "}
							{totalPrice && currencyDisplay(totalPrice)}
						</span>
						{t(dayDiff() > 1 ? "total_for_multiple_night" : "total_for_1_night").replace("[numberofnight]", dayDiff())}{" "}
						{SITE_ID_LIST_HASH.mbh?.includes(context.configData?.site_id?.toString())?`(${t("mbh_total_price_helper_text")})`:isTaxInclusiveInCartSingleItem(rate) === "exclusive_of_vat" && (<span class='excl_taxes dBlock'>({t("excl_taxes_and_fees")})</span>)}
					</div>
					<div className='column30'>
						<AddtoCart
							room_id={room.room_id}
							room_name={room.room_name}
							rate_id={rate.rate_id}
							rate_name={rate.rate_name}
							is_tax_inclusive={setTaxInclusiveInSession(rate)}
							tax_status={isTaxInclusiveInCartSingleItem(rate)}
							price={rate.datewiserates}
							setIsCartShown={setIsCartShown}
							section={"room_detail"}
							rate_obj={rate}
							tax_obj={rate.tax}
						/>
					</div>
					<div className='column100'>
						<div className='roomViewLinks'>
							<ViewMoreLess
								showMore={showMore}
								setShowMore={setShowMore}
								rateShortDesc={rate?.rate_short_desc}
							/>
							<a
								className='contentModal'
								tabIndex='0'
								href='javascript:void(0);'
								onClick={() => setOpenPolicy(true)}
							>
								{openPolicy && context.policyLoading ? t("policy_loading") : t("view_policies")}
							</a>
						</div>
					</div>
				</div>
				<PolicyModal
					openPolicy={openPolicy}
					setOpenPolicy={setOpenPolicy}
					rateId={rate.rate_id}
					roomId={room.room_id}
				  	roomRateTaxInfo={rate?.rate_tax_info}
				  	policyCodes={rate?.policy_code_data}
				/>
			</div>
		</div>
  );
};

export default RateDetails;
