import { useContext, useEffect, useState } from "react";
import {
  getModifyReservationRequest,
  getSaveReservationRequest,
  getSendEmailRequest,
} from "../utils/requestData";
import AppContext from "../utils/appContext";
import { saveReservation, modifyReservation, sendReservationEmail } from "../services/confirmation";
import { useNavigate } from "react-router-dom";
import { route } from "../utils/route";
import { SessionStorage } from "../utils/storage";
import { KEYS } from "../utils/constant";
import { useAuth } from "./useAuth";
import { getThirdPartyPolicy } from "../utils/utils";
import { fetchUrl } from '../utils/fetchUrl';
import { apiList } from "../utils/apiUrl";

export const useReservation = ({
  bookingData,
  checkoutData,
  siteConfig,
  authToken,
}) => {
  const context = useContext(AppContext);
  let navigate = useNavigate();
  let isReservation = SessionStorage.getJSON(KEYS.IS_RESERVATION);
  const [loading4, setLoading4] = useState(false);
  const [bookingDetails, setBookingDetails] = useState("")

  const { getAuth } = useAuth()
  let retryAttempted = false;
  let retryAttempted2 = false;
  let retryAttempted3 = false;

  const modifyReservationDetails = async (values) => {
		let requestData = getModifyReservationRequest(values);
		requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
		let payload = {
			...requestData,
			customer_id: bookingData?.customer_id,
			uid: "",
			payment_method: {
				...requestData.payment_method,
				card_type: checkoutData?.hotel?.cards.find(
					(x) => x.card_name === values?.cardType
				)?.card_id,
				hotel_currency: checkoutData?.hotel?.abbrevation,
			},
		};
		try {
			await setLoading4(true);
			await modifyReservation(
				payload,
				context.bookingToken || context.authToken
			).then((res) => {
				if (res.data.status === 1 && res.data) {
					context.setConfirmationData(res.data);
					sendConfirmationEmailOnCheckoutSuccess(res.data, true); // send email after successful booking
					navigate(route.confirmation);
				} else {
					navigate(route.declineBooking);
				}
			});
		} catch (error) {
			if (error.renewToken && !retryAttempted) {
				setBookingDetails(values);
				getAuth("doBooking");
				retryAttempted = true;
			} else {
				navigate(route.declineBooking);
			}
		} finally {
			setLoading4(false);
		}
  };

  const sendConfirmationEmailOnCheckoutSuccess = async (confirmationData, modify = false) => {
		let requestData = getSendEmailRequest({ siteConfig, confirmationData });
		requestData.toemail = ''; // Remove existing email id and send empty string 
		requestData.modify_res = modify;
    try {
      await setLoading4(true);
      await sendReservationEmail(requestData, context.bookingToken || context.authToken).then((res) => {
        setLoading4(false);
      });
    } catch (error) {
      if (error.renewToken && !retryAttempted3) {
			 getAuth(undefined, "reservationToken")
			setTimeout(() => {
				sendReservationEmail(requestData, context.bookingToken || context.authToken).then((res) => {
					setLoading4(false);
				  })
			.catch((err) =>{
				retryAttempted3 = true
			  });
			 }, 400);
			 
      }
    } finally {
      setLoading4(false);
    }
  };

  const doBooking = async (values) => {
    if (isReservation && SessionStorage.get("is_modification")) {
      modifyReservationDetails(values);
    } else {
		let requestData = getSaveReservationRequest(values);
		requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
		let payload = {
			...requestData,
			payment_method: {
				...requestData.payment_method,
				card_type: checkoutData?.hotel?.cards.find(
					(x) => x.card_name === values?.cardType
				)?.card_id,
			},
			hotel_currency: checkoutData?.hotel?.abbrevation,
		};
		try {
			await setLoading4(true);
			await saveReservation(
				payload,
				context.bookingToken || context.authToken
			).then((res) => {
				context.setConfirmationData(res.data);
				sendConfirmationEmailOnCheckoutSuccess(res.data, false); // send email after successful booking
				navigate(route.confirmation);
			});
		} catch (error) {
			if (error.renewToken && !retryAttempted2) {
				setBookingDetails(values);
				getAuth("doBooking");
				retryAttempted2 = true;
			} else {
				navigate(route.declineBooking);
			}
		} finally {
			setLoading4(false);
		}
	}
  };

  useEffect(() => {
    if (context?.reservationToken && context?.confirmationData) {
      sendConfirmationEmailOnCheckoutSuccess(context.confirmationData, false)
    }
  }, [context.reservationToken])

  useEffect(() => {
    if (context.bookingToken) {
      doBooking(bookingDetails)
    }
  }, [context.bookingToken])

  return { doBooking, loading4 };
};
