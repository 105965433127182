import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr"; // Required for moment's string translation
import {
	setTaxInclusiveInBookingData,
	currencyDisplay,
	capitalizeStr,
} from "../../../utils/utils";
import YourStayBookedRoomAddons from "./YourStayBookedRoomAddons";
import { useContext, useState } from "react";
import AppContext from "../../../utils/appContext";

export default function YourStayBookedRoomDetails({
	roomNo,
	item,
	currencySymbol,
	childAgeDisplayArray,
	dailyPrices,
	taxDetails,
	setViewBookedRoomDetails,
	viewBookedRoomDetails,
	confirmationData,
}) {
	const context = useContext(AppContext);
	// console.log('context.confirmationData -> ' + JSON.stringify(context.confirmationData));
	const { t } = useTranslation();
	const [viewPriceBreakDown, setViewPriceBreakDown] = useState(false);
	const [viewTaxesBreakDown, setViewTaxesBreakDown] = useState(false);
	let totalTaxAmount = 0;

	if (taxDetails.hasOwnProperty("hotel") && Array.isArray(taxDetails.hotel)) {
		for (const item of taxDetails.hotel) {
			totalTaxAmount += item.tax_amounts;
		}
	}

	function TaxDetailsView() {
		return (
			<div className='column100 cartPriceBreak pL0 PR0'>
				<p
					className='m0'
					onClick={() => {
						setViewTaxesBreakDown(!viewTaxesBreakDown);
					}}
				>
					{t("taxes_fees_breakdown")}:
				</p>
				<ul>
					{taxDetails.hotel.map((item, index) => (
						<li class='dFlex justifyContentSpBtw' key={index}>
							<span class='tax-title'>{item.tax_title}</span>
							<span class='tax-amounts'>
								{currencySymbol +
									currencyDisplay(item.tax_amounts)}
							</span>
						</li>
					))}
				</ul>
			</div>
		);
	}

	let startDate = moment(confirmationData?.date_in);
	let endDate = moment(confirmationData?.date_out);
	let totalNight = endDate.diff(startDate, "days");

	let totalAdults =
		item?.adult <= 1
			? `${item?.adult} ${t("adult")}`
			: `${item?.adult} ${t("adults")}`;

	// let totalChildren =
	// 	item?.child > 0
	// 		? item?.child <= 1
	// 			? `${item?.child} ${t("child")}`
	// 			: `${item?.child} ${t("children")}` + item?.child > 0
	// 			? " ( " + childAgeDisplayArray.join(", ") + " )"
	// 			: ""
	// 		: "";

	let totalChildren =
		item?.child > 0 && (item?.child === 1
			? `${item?.child} ${t("child")}`
			: `${item?.child} ${t("children")}`
			);
				

	var allDaysPrices = [];

	Object.keys(dailyPrices).map((key, index) => {
		allDaysPrices.push(dailyPrices[key]["price_without_tax"]);
	});

	return (
		<div className='roomWrapper addonRoomInfo addonRoomInfoConfirm'>
			<label
				className={
					viewBookedRoomDetails
						? "dBlock textUppercase arrowUp"
						: "dBlock textUppercase"
				}
				onClick={() => {
					setViewBookedRoomDetails(!viewBookedRoomDetails);
				}}
				tabIndex='0'
			>
				{t("room")} {roomNo}
			</label>
			{viewBookedRoomDetails && (
				<div className='roomDetails'>
					<div className='column100 pL0 pR0 dFlex justifyContentSpBtw paddingT10'>
						<div className='productName'>
							{t("reservation_number")}
						</div>
						<span className='dInlineBlock'>
							{item?.ConfirmationNumber}
						</span>
					</div>
					<div className='column100 pL0 pR0 dFlex justifyContentSpBtw paddingT10'>
						<div className='productName'>
							{totalAdults}
							{item?.child > 0 ? ", " : ""}
							{totalChildren}
						</div>
					</div>
					<div className='column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw confirmPageProdName'>
						<div className='productName'>
							{item?.roomName} | {item?.rateName ? capitalizeStr(item.rateName).replace(/\\'/g, "'") : ""}
						</div>
					</div>
					{viewPriceBreakDown && (
						<div className='column100 cartPriceBreak pL0 PR0'>
							<p className='m0'>{t("stay_breakdown")}:</p>
							<ul>
								{Object.keys(dailyPrices).map((key, index) => {
									return (
										<li
											key={index}
											className='dFlex justifyContentSpBtw'
										>
											<day className='cartDate'>
												{moment(key).format(
													"ddd DD MMM YYYY"
												)}
											</day>
											<div className='perDayPrice'>
												{currencySymbol}
												{currencyDisplay(
													parseFloat(
														dailyPrices[key][
															"price_without_tax"
														]
													)
												)}
											</div>
										</li>
									);
								})}
							</ul>
						</div>
					)}
					<div
						className='column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw'
						tabIndex='0'
						onClick={() =>
							setViewPriceBreakDown(!viewPriceBreakDown)
						}
					>
						<div className='productName'>
							{t("total_stay_for") + " "}
							{totalNight}{" "}
							{totalNight > 1 ? t("nights") : t("night")}
							{t(setTaxInclusiveInBookingData(taxDetails)) !==
								"" &&
								", " +
									t(setTaxInclusiveInBookingData(taxDetails))}
							:
						</div>
						<div
							className={
								viewPriceBreakDown
									? "cartTotalPrice arrowUp"
									: "cartTotalPrice"
							}
						>
							{currencySymbol}
							{currencyDisplay(
								allDaysPrices.reduce((accumulator, item) => {
									return accumulator + item * 1;
								}, 0)
							)}
						</div>
					</div>

					{
						// check if tax amount total is more than zero
						totalTaxAmount > 0 &&
							viewTaxesBreakDown &&
							TaxDetailsView()
					}
					{
						// check if tax amount total is more than zero
						totalTaxAmount > 0 && (
							<div
								className={`column100 cartPriceBreak pL0 PR0 ${
									viewTaxesBreakDown ? "arrowUp" : "arrowDown"
								}`}
								onClick={() => {
									setViewTaxesBreakDown(!viewTaxesBreakDown);
								}}
							>
								<div className='dFlex justifyContentSpBtw'>
									<span>{t("total_taxes_fees")}:</span>
									<span>
										{currencySymbol +
											currencyDisplay(totalTaxAmount)}
									</span>
								</div>
							</div>
						)
					}

					{item?.addons_data && (
						<YourStayBookedRoomAddons
							addonsData={item?.addons_data}
							currencySymbol={currencySymbol}
							strEnhancements={t("enhancements")}
							strTotalEnhancement={t("total_enhancement")}
						/>
					)}
				</div>
			)}
		</div>
	);
}
