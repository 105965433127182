import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { addSpaceAfterComma } from "../../utils/utils";

export default function AddressSuggestion(props) {
  let data = [{ formattedAddress: props.t("choose_address") }, ...props?.suggestion];
  const [value, setValue] = React.useState(data[0])

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      value={value}
      onChange={(event, values) => {
        setValue(values)
        props.addInitialAddress(values)
      }}
      options={data}
      autoHighlight
      getOptionLabel={(option) => addSpaceAfterComma(option.formattedAddress)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {addSpaceAfterComma(option.formattedAddress)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          onBlur={() => {
            if (!value) setValue(data[0])
          }
          }
        />
      )}
    />
  );
}
