import React, { useState, useEffect, useContext } from "react";
import {
	fetchRoomListData,
	fetchOfferListData,
	fetchAvailabilityData,
} from "../services/roomList";
import {
	getNoAvailabilityRequest,
	getRoomListRequest,
} from "../utils/requestData";
import AppContext from "../utils/appContext";
import { DEFAULT_CURRENCY, KEYS } from "../utils/constant";
import { LocalStorage, SessionStorage } from "../utils/storage";
import { useErrorBoundary } from "react-error-boundary";
import { useSearchParams, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";
import { getThirdPartyPolicy, notShowCrossSellSiteId } from "../utils/utils";
import { route } from "../utils/route";

export const useRoomListHook = ({
	// authToken,
	searchPanelData,
	loader = true,
}) => {
	const [searchParams] = useSearchParams()
	const location = useLocation()
	let roomCode = searchParams.get("roomcode")
	let rateCode = searchParams.get("ratecode")
	const context = useContext(AppContext);
	const authToken = context.authToken
	let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
	const [loading, setLoading] = useState(loader);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [roomList, setRoomList] = useState(null);
	const [offerList, setOfferList] = useState(null);
	const [hotelData, setHotelData] = useState(null);
	const [viewHideRoomOffer, setViewHideRoomOffer] = useState(searchParams.get("view") === "ratetype" ? true : false);
	const [availability, setAvailability] = useState(0);
	const [availabilityData, setAvailabilityData] = useState([]);
	const [deepLinkNoData, setDeepLinkNoData] = useState(false);
	const [partialSearch, setPartialSearch] = useState(false);
	const [lastSearchedData, setLastSearchedData] = useState(0);
	const [inquireList, setInquireList] = useState([]);
	let retryAttempted = false;
	let retryAttempted2 = false;
	let retryAttempted3 = false;
	let retryAttempted4 = false;

	useEffect(() => {
		setViewHideRoomOffer(searchParams.get("view") === "ratetype" ? true : false)
	}, [searchParams?.get("view")])

	const { getAuth } = useAuth()
	const { showBoundary } = useErrorBoundary();

	const getOfferList = async () => {
		if (!context?.offerData?.length) {
			let requestData = getRoomListRequest({
				source_page: "offer_page",
				currencyData: context.currencyData,
				roomCode: deepLinkNoData ? "" : roomCode,
				rateCode: deepLinkNoData ? "" : rateCode
			});
			requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
			try {
				await setLoading(true);
				return await fetchOfferListData(
					requestData,
					authToken,
					context
				).then((res) => {
					setInquireList(res.data.enquiry_rooms)
					context.setInquireData(res.data.enquiry_rooms)
					setOfferList(res.data.rates);
					context.setOfferData(res.data.rates);
					setHotelData(res.data.hotel);
					context.setHotelData(res.data.hotel);
					if (roomCode || rateCode) {
						if (res?.data?.rates?.length) {
							setInquireList(requestData.directRoomCodes ? [] : res?.data?.enquiry_rooms)
							context.setInquireData(requestData.directRoomCodes ? [] : res?.data?.enquiry_rooms)
						}
						if (deepLinkNoData && !res?.data?.rates?.length) {
							setAvailability(1)
						} else if (!res?.data?.rates?.length) {
							setDeepLinkNoData(true)
						}
					} else {
						setAvailability(res.data.noavailability || res?.data?.rates?.length === 0 ? 1 : 0);
					}
					context.setAddedCode(res.data.hotel.valid_code === false && res.data.hotel.code_used ? true : false)
					return res.data.rates;
				});
			} catch (error) {
				// Show error boundary
				if (error.renewToken && !retryAttempted4) {
					getAuth().then(() => {
						retryAttempted4 = true;
					})
				} else {
					showBoundary(error);
				}
			} finally {
				setLoading(false);
			}
		} else {
			return context.offerData;
		}
	};

	const getRoomList = async (partial_search_msg_clean, loadOffer = undefined) => {
		context.setOverlay(false);
		document.body.classList.remove('bodyScrollHidden');
		document.body.classList.remove('bodyHiddenMobile');
		if (loadOffer) {
			let requestData = getRoomListRequest({
				source_page: "offer_page",
				currencyData: context.currencyData,
				roomCode: deepLinkNoData ? "" : roomCode,
				rateCode: deepLinkNoData ? "" : rateCode
			});
			requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
			try {
				await setLoading(true);
				await fetchOfferListData(requestData, authToken).then((res) => {
					setInquireList(res.data.enquiry_rooms)
					context.setInquireData(res.data.enquiry_rooms)
					setOfferList(res.data.rates);
					context.setOfferData(res.data.rates);
					setHotelData(res.data.hotel);
					context.setHotelData(res.data.hotel);
					if (roomCode || rateCode) {
						if (res.data.rates?.length) {
							setInquireList([])
							context.setInquireData([])
						}
						if (deepLinkNoData && !res?.data?.rates?.length) {
							setAvailability(1)
						} else if (!res?.data?.rates?.length) {
							setDeepLinkNoData(true)
						} else if (res?.data?.rates?.length) (
							setDeepLinkNoData(false)
						)
					} else {
						setAvailability(res.data.noavailability || res?.data?.rates?.length === 0 ? 1 : 0);
					}
					context.setAddedCode(res.data.hotel.valid_code === false && res.data.hotel.code_used ? true : false)
					if (res.data.hotel?.partial_search) {
						if (res.data.hotel.occ_unavailable.length > 0) {
							let cartView = SessionStorage.getJSON(KEYS.ROOM_DATA);
							if (cartView.length > 1) {
								res.data.hotel.occ_unavailable.map(unavilableRoom => {
									let adultchildun = unavilableRoom.split("i")
									adultchildun = adultchildun[0].split("-")
									let unadult = adultchildun[0]
									let unchild = adultchildun[1]
									cartView.map((cartValue, cartIndex) => {
										if (cartValue.adults == unadult && cartValue.childrens.length == unchild) {
											cartView.splice(cartIndex, 1);
										}
									});
								})
								cartView.map((cartroom, index) => {
									cartView[index].room = index + 1
								})
							}
							SessionStorage.setJSON(KEYS.ROOM_DATA, cartView);
						}
						let requestedCount = requestData.party[requestData.party.length - 1]
						setLastSearchedData(requestedCount.adults + requestedCount.child)
						setPartialSearch(true)
						window.scrollTo({
							top: 0,
							behavior: "smooth",
						});
					} else if (!partial_search_msg_clean && !res.data.hotel?.partial_search) {
						setPartialSearch(false)
						setLastSearchedData(0)
					}
				});
			} catch (error) {
				if (error.renewToken && !retryAttempted) {
					// getAuth().then(() => {
					// 	retryAttempted = true;
					// })
				} else {
					showBoundary(error);
				}
			} finally {
				setLoading(false);
			}
		} else {
			let requestData = getRoomListRequest({
				currencyData: context.currencyData,
				roomCode: deepLinkNoData ? "" : roomCode,
				rateCode: deepLinkNoData ? "" : rateCode
			});
			requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data
			try {
				await setLoading(true);
				await fetchRoomListData(requestData, authToken, context).then(async (res) => {
					context.setRoomListingPriceCheck(
						res.data.hotel.roomlisting_price_check
					);
					const roomList = res?.data?.rooms || [];
					const sortedArray = roomList.filter((obj) => obj.min_price > 0).sort((a, b) => a.min_price - b.min_price);
					const priceSortedArray = sortedArray.map(x => {
						return {
							...x,
							price: x?.price?.sort((a, b) => a.min_price - b.min_price)
						}
					})
					const zeroPriceArray = roomList.filter((obj) => !obj.min_price);
					const roomDataList = priceSortedArray.concat(zeroPriceArray);
					setRoomList(roomDataList)
					context.setRoomData(roomDataList);
					setHotelData(res.data.hotel);
					context.setHotelData(res.data.hotel);
					if (roomCode || rateCode) {
						if (deepLinkNoData && !sortedArray?.length) {
							setAvailability(1)
						} else if (!sortedArray?.length) {
							setDeepLinkNoData(true)
						}
					} else {
						setAvailability(res.data.noavailability || roomDataList?.length === 0 ? 1 : 0);
					}
					context.setAddedCode(res.data.hotel.valid_code === false && res.data.hotel.code_used ? true : false)
					if (res.data.hotel?.partial_search) {
						if (res.data.hotel.occ_unavailable.length > 0) {
							let cartView = SessionStorage.getJSON(KEYS.ROOM_DATA);
							if (cartView.length > 1) {
								res.data.hotel.occ_unavailable.map(unavilableRoom => {
									let adultchildun = unavilableRoom.split("i")
									adultchildun = adultchildun[0].split("-")
									let unadult = adultchildun[0]
									let unchild = adultchildun[1]
									cartView.map((cartValue, cartIndex) => {
										if (cartValue.adults == unadult && cartValue.childrens.length == unchild) {
											cartView.splice(cartIndex, 1);
										}
									});
								})
								cartView.map((cartroom, index) => {
									cartView[index].room = index + 1
								})
							}
							SessionStorage.setJSON(KEYS.ROOM_DATA, cartView);
						}
						let requestedCount = requestData.party[requestData.party.length - 1]
						setLastSearchedData(requestedCount.adults + requestedCount.child)
						setPartialSearch(true)
						window.scrollTo({
							top: 0,
							behavior: "smooth",
						});
					} else if (!partial_search_msg_clean && !res.data.hotel?.partial_search) {
						setPartialSearch(false)
						setLastSearchedData(0)
					}
					LocalStorage.set(KEYS.SELECTED_CURRENCY, context?.currencyData || DEFAULT_CURRENCY);
					LocalStorage.set(KEYS.HOTEL_NAME, res?.data?.hotel?.hotel_name || "-");
					LocalStorage.set(KEYS.HOTEL_EMAIL, res?.data?.hotel?.email || "-");
					LocalStorage.set(KEYS.HOTEL_PHONE, res?.data?.hotel?.phone || "-");
				});
			} catch (error) {
				if (error.renewToken && !retryAttempted2) {
					getAuth().then(() => {
						retryAttempted2 = true;
					})
				} else {
					showBoundary(error);
				}
				return
			} finally {
				setLoading(false);
			}
		}
	};

	const getNoAvailability = async () => {
		let requestData = getNoAvailabilityRequest();
		try {
			await setLoading2(true);
			await fetchAvailabilityData(requestData, authToken).then((res) => {
				const keysToFilter = ['31236', '31237', '31235', '10203099', '55633'];
				const filteredData = {};
				for (const key of keysToFilter) {
					if (res.data[key]) {
						filteredData[key] = res.data[key];
					}
				}

				// add 'site id' in object
				for (var parentKey in filteredData) {
					if (filteredData.hasOwnProperty(parentKey) && typeof filteredData[parentKey] === 'object') {
						for (var childKey in filteredData[parentKey]) {
							if (filteredData[parentKey].hasOwnProperty(childKey) && typeof filteredData[parentKey][childKey] === 'object') {
								filteredData[parentKey][childKey]['siteID'] = parentKey;

								// Stop the loop after the first child
								break;
							}
						}
					}
				}
				// add 'site id' in object

				const result = Object.values(filteredData).flatMap((obj) =>
					Object.values(obj)
				);
				setAvailabilityData(result);
			});
		} catch (error) {
			if (error.renewToken && !retryAttempted3) {
				getAuth().then(() => {
					retryAttempted3 = true;
				})
			} else {
				showBoundary(error);
			}
		} finally {
			setLoading2(false);
		}
	};

	const getRoomListForDeepLink = async () => {
		try {
			await setLoading3(true)
			await getRoomList();
		} finally {
			setLoading3(false)
		}
	}

	const getOfferListForDeepLink = async () => {
		try {
			await setLoading3(true)
			await getOfferList();
		} finally {
			setLoading3(false)
		}
	}

	useEffect(() => {
		let cartItems = SessionStorage.getJSON(KEYS.ROOM_DATA);
		if (context.roomData) {
			let roomList = context.roomData;
			if (roomList.length > 0) {
				cartItems.length > 0 && cartItems.map((item, index) => {
					if (item.room_info.room_id > 0) {
						let specific_room = roomList.filter(function (el) {
							return el.room_id == item.room_info.room_id;
						});
						let occKey = String(item.adults) + '-' + String(item.childrens.length) + 'i0';
						if (specific_room[0] !== undefined) {
							let specific_rate = specific_room[0].price.filter(function (el) {
								return el.rate_id == item.room_info.rate_id;
							});
							if (specific_rate[0] !== undefined && specific_rate[0].datewiserates !== undefined && specific_rate[0].datewiserates != '') {
								cartItems[index].price = specific_rate[0].datewiserates[occKey];
							}
							if (specific_rate?.[0]?.tax !== undefined) {
								cartItems[index].room_info.tax_obj = specific_rate?.[0]?.tax
								if (!cartItems[index].room_info.rate_obj) {
									cartItems[index].room_info.rate_obj = {};
								}
								cartItems[index].room_info.rate_obj.tax = specific_rate?.[0]?.tax;
							}

						}
					}
				});
				SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems)
			}
		}
	}, [context.roomData])

	useEffect(() => {
		if (deepLinkNoData && searchParams.get("view") === "ratetype") {
			getOfferListForDeepLink()
		}
		if (deepLinkNoData && searchParams.get("view") === "roomtype" && notShowCrossSellSiteId.includes(context?.configData?.site_id)) {
			getRoomListForDeepLink()
			getOfferListForDeepLink()
		}
	}, [deepLinkNoData])

	useEffect(() => {
		if (authToken && searchPanelData && deepLinkNoData &&
			context?.configData?.site_id && !notShowCrossSellSiteId.includes(context?.configData?.site_id) && (location.pathname === route.roomList || location.pathname.split("/")[1] === "index")) {
			getRoomListForDeepLink()
			getOfferListForDeepLink()
		}
	}, [deepLinkNoData]);

	useEffect(() => {
		if (availability === 1 && authToken &&
			context?.configData?.site_id && !notShowCrossSellSiteId.includes(context?.configData?.site_id)) {
			getNoAvailability();
		} else {
			setAvailabilityData([])
		}
	}, [availability, authToken, context?.currencyData]);

	useEffect(() => {
		if (authToken && searchPanelData && context?.currencyData, context?.languageData) {
			if (location.pathname === route.roomList || location.pathname.split("/")[1] === "index") {
				getRoomList();
				getRoomList("", true)
			}
		}
	}, [authToken, searchPanelData, context?.currencyData, context?.languageData]);

	useEffect(() => {
		if (authToken && searchPanelData && partialSearch && (location.pathname === route.roomList || location.pathname.split("/")[1] === "index")) {
			getRoomList("partial_search_msg_clean")
		}
	}, [partialSearch])

	return {
		loading,
		loading2,
		roomList,
		offerList,
		viewHideRoomOffer,
		hotelData,
		currentRoom,
		availability,
		availabilityData,
		deepLinkNoData,
		partialSearch,
		lastSearchedData,
		inquireList,
		loading3,
		setViewHideRoomOffer,
		setRoomList,
		setOfferList,
		getOfferList,
		getRoomList,
		getNoAvailability,
		setLoading,
		setInquireList
	};
};
