import React from 'react';
import ClaridgesLoader from "../../../styles/images/Claridges_LogoAnim.gif";
import BerkleyLoader from "../../../styles/images/TheBerkeley_LogoAnim.gif";
import ConnaughtLoader from "../../../styles/images/TheConnaught_LogoAnim.gif";
import MaybourneBeverlyHillsLoader from "../../../styles/images/MBH_LogoAnim.gif";
import MaybourneRivieraLoader from "../../../styles/images/MBR_LogoAnim.gif";
import EmoryLoader from "../../../styles/images/TheEmory_LogoAnim-new.gif";
import { SITE_ID_LIST } from '../../../utils/constant';

const Loader = () => {
  const url = window.location.href;
  const logoMap = {
    claridges: ClaridgesLoader,
    [SITE_ID_LIST[0]]: ClaridgesLoader,
    theberkeley: BerkleyLoader,
    "the-berkeley": BerkleyLoader,
    [SITE_ID_LIST[1]]: BerkleyLoader,
    theconnaught: ConnaughtLoader,
    "the-connaught": ConnaughtLoader,
    "the-connaugt-uat": ConnaughtLoader,
    [SITE_ID_LIST[2]]: ConnaughtLoader,
    maybourneriviera: MaybourneRivieraLoader,
    "mbr-uat": MaybourneRivieraLoader,
    [SITE_ID_LIST[6]]: MaybourneRivieraLoader,
    maybournebeverlyhills: MaybourneBeverlyHillsLoader,
    "mbh-uat": MaybourneBeverlyHillsLoader,
    [SITE_ID_LIST[7]]: MaybourneBeverlyHillsLoader,
    emory: EmoryLoader,
    [SITE_ID_LIST[8]]: EmoryLoader,
  };

  const defaultLogo = ClaridgesLoader;
  const logoSrc = Object.entries(logoMap).find(([key]) => url.includes(key))?.[1] || defaultLogo;

  return (
    <div 
      style={{ 
        // height: "100vh", 
        backgroundImage: `url(${logoSrc})`, 
        backgroundColor: "#ffffff",
        backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        backgroundPosition: "center center",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "fixed",
        zIndex: 9999,
      }}
    />
  );
};

export default Loader;
