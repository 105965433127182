import { useState, useContext } from "react";
import AppContext from "../utils/appContext";
import { getReservationRequest } from "../utils/requestData";
import { fetchReservationData } from "../services/manageBooking";
import { useTranslation } from "react-i18next";
import { SessionStorage } from "../utils/storage";
import { KEYS } from "../utils/constant";
import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useAuth } from "./useAuth";

export const useManageBooking = ({
	clearErrors = () => false,
	reset = () => false,
}) => {
	const { t } = useTranslation();
	let context = useContext(AppContext);
	const { showBoundary } = useErrorBoundary();
	const [loading, setLoading] = useState(false);
	const [bookingData, setBookingData] = useState();
	const [invalidMsg, setInvalidMsg] = useState();
	const [bookingDetails, setBookingDetails] = useState("")
	const { getAuth } = useAuth()
	let retryAttempted = false;

	const getYourReservation = async (values) => {
		let requestData = getReservationRequest();
		requestData.reservation_id = values.reservation_id;
		requestData.email = values.email;
		try {
			await setLoading(true);
			await fetchReservationData(requestData, context.authToken).then(
				(res) => {
					if (res.response_type === "error") {
						setInvalidMsg(t("invalid_id_or_email"));
						setTimeout(() => {
							setInvalidMsg();
						}, 5000);
					} else if (res?.data) {
						setBookingData(res.data);
						context.setIsBookingData(true);
						clearErrors();
						reset();
						SessionStorage.setJSON(KEYS.IS_RESERVATION, {
							group_reservation_id:
								res?.data?.group_reservation_id,
							customer_res_id: res?.data?.customer_res_id,
							email: values.email,
							reservation_id: res?.data?.ConfirmationNumber,
						});
						// const element = document.getElementById(
						// 	"viewReservationPageId"
						// );
						window.scrollTo({ top: 0, behavior: "smooth" });
					}
				}
			);
		} catch (error) {
			if (error.renewToken && !retryAttempted) {
				getAuth()
				setBookingDetails(values)
				retryAttempted = true;
			} else {
				showBoundary(error);		
			}
		} finally {
			setLoading(false);
		}
		
	};

	useEffect(() => {
		setLoading(true);
		if (context.isBookingData && !bookingData && context.authToken) {
			let reservationData = SessionStorage.getJSON(KEYS.IS_RESERVATION);
			if(reservationData!='' && reservationData!=undefined){
				const data = {
					reservation_id: reservationData.reservation_id,
					email: reservationData.email,
				};
				getYourReservation(data);
			}else{
				setLoading(false);
			}
		}
		if (!context.isBookingData) {
			setLoading(false);
		}
	}, [context.isBookingData, context.authToken]);

	useEffect(() => {
		if (context.authToken && bookingDetails) {
		  getYourReservation(bookingDetails)
		}
	}, [context.authToken])

	return {
		loading,
		bookingData,
		invalidMsg,
		getYourReservation,
	};
};
