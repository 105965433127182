import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KEYS } from "../../utils/constant";
import { LocalStorage } from "../../utils/storage";
import AppContext from "../../utils/appContext";
import { Navigate } from "react-router-dom";
import { route } from "../../utils/route";
import { getSiteContactDetails } from "../../utils/utils";

export default function ErrorFallback({ error, resetErrorBoundary }) {
	const { t } = useTranslation();
	let context = useContext(AppContext);	
	let configData = LocalStorage.getJSON(KEYS.CONFIG) || []; // get config data from local storage
	const site_id = configData.site_id ? configData.site_id : false; // get 'site id' from config data

	const hotelEmail = LocalStorage.get(KEYS.HOTEL_EMAIL)
		? LocalStorage.get(KEYS.HOTEL_EMAIL)
		: getSiteContactDetails(site_id).email;
	const hotelPhone = LocalStorage.get(KEYS.HOTEL_PHONE)
		? LocalStorage.get(KEYS.HOTEL_PHONE)
		: getSiteContactDetails(site_id).contactNo;
	const callToAction = () => {
		resetErrorBoundary();
		backToHome();
	};

	const backToHome = () => {
		Navigate(route.roomList);
	};

	useEffect(() => {
		context.setIsErrorTriggered(true); // set var as true, once this error fallback component loads
	}, []);

	return (
		<main className='mainContent declinePage textCenter'>
			<div className='columnContainer'>
				<div className='declineMessageWrap dFlex alignItemsCenter flexWrap justifyContentCenter'>
					<div className='declineMessage PageTitleWrap3'>
						<span className='dBlock textUppercase'>
							{t("internal_error")}
						</span>
						<h1 className='m0 paddingT20 paddingB30'>
							{t("something_went_wrong")}
						</h1>
						<a
							className='customBtn customBtnDark addToStayBtn'
							href='/'
							onClick={callToAction}
						>
							{t("return_home")}
						</a>
					</div>
				</div>
				<div className='pageContactInfo paddingT20 paddingB40'>
					{t("if_problem_persists")} {t("email_us")}
					<a href={`mailto:${hotelEmail}`}>{hotelEmail}</a>{" "}
					{t("call_us")}
					<a href={`tel:${hotelPhone}`}>{hotelPhone}</a>
					{/* <pre style={{ color: "red" }}>{error.message}</pre> */}
					{/* <button onClick={resetErrorBoundary}>Try again</button> */}
				</div>
			</div>
		</main>
	);
}
