import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CommonModal from "../common/Modal/commonModal";
import ConfirmationEmailForm from "./confirmationEmailForm";
import PrintPreviewPopup from "./printPopup/PrintPreviewPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr"; // Required for moment's string translation
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/route";
import ReservationDetailsSummary from "./yourStay/YourStayReservationSummary";
import YourStayCheckInOut from "./yourStay/YourStayCheckInOut";
import YourStayBookingTotal from "./yourStay/YourStayBookingTotal";
import YourStayBookedRoomDetails from "./yourStay/YourStayBookedRoomDetails";

export default function BookingDetails({
	authToken,
	siteConfig,
	currencySymbol,
	hotelCheckInTime,
	hotelCheckOutTime,
	crsConfirmationNo,
	confirmationData
}) {
	let navigate = useNavigate();
	const { t } = useTranslation();
	const [emailModalOpen, setEmailModalOpen] = useState(false);
	const [printModalOpen, setPrintModalOpen] = useState(false);
	const [viewBookedRoomDetails, setViewBookedRoomDetails] = useState(true);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const dailyPrices = confirmationData?.room_stay[0]?.daily_prices;
	const taxDetails = confirmationData?.room_stay[0]?.tax_details;

	// Change Day & Month Sting dynamically for moment lib
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	moment.locale(language);

	let dateFormat = (language === "fr") ? "ddd DD MMM YYYY" : "ddd, DD MMM YYYY";

	return (
		<>
			<div className='cartRoomInfoSidebar'>
				<div className='cartSidebarTop cartSidebarTopConfrmation posRelative'>
					<h2 className='m0 paddingTB25 textUppercase'>
						{t("your_stay")}
					</h2>
					<div className='sidebarOverflowHidden'>
						<div
							className={
								viewBookedRoomDetails
									? "sidebarRoomListWrap paddingB15 bookedRoomView"
									: "sidebarRoomListWrap paddingB15"
							}
						>
							<YourStayCheckInOut
								strCheckIn={t("check_in")}
								strCheckOut={t("check_out")}
								strAfter={t("after")}
								strBefore={t("before")}
								checkInDate={moment(
									confirmationData?.date_in
								).format(`${dateFormat}`)}
								checkOutDate={moment(
									confirmationData?.date_out
								).format(`${dateFormat}`)}
								hotelCheckInTime={hotelCheckInTime}
								hotelCheckOutTime={hotelCheckOutTime}
							/>

							<ReservationDetailsSummary
								strTitle={t("reservation_details")}
								strCRSConfirmationNo={t(
									"crs_confirmation_number"
								)}
								crsConfirmationNo={crsConfirmationNo}
							/>

							<div className='columnWrap cartSidebarRow sidebarRoomList sidebarRoomListConfirm paddingTB20'>
								{"room_stay" in confirmationData &&
									confirmationData?.room_stay.length >= 1 &&
									confirmationData?.room_stay.map(
										(item, index) => {
											return (
												<YourStayBookedRoomDetails
													roomNo={index + 1}
													item={item}
													currencySymbol={
														currencySymbol
													}
													dailyPrices={
														item.daily_prices
													}
													taxDetails={
														item.tax_details
													}
													setViewBookedRoomDetails={
														setViewBookedRoomDetails
													}
													viewBookedRoomDetails={
														viewBookedRoomDetails
													}
													confirmationData={
														confirmationData
													}
												/>
											);
										}
									)}
							</div>
						</div>
					</div>
				</div>
				<div className='cartSidebarBottom cartSidebarBottomConfirmation'>
					<YourStayBookingTotal
						strBookingTotal={t("booking_Total")}
						currencySymbol={currencySymbol}
						totalCost={confirmationData.total_cost}
					/>
					<div className='cartSidebarBtn'>
						<button
							className='customBtn customBtnDark w100'
							onClick={() =>
								window.open(route.manageBookings, "_blank")
							}
						>
							{t("modify_Reservation")}
						</button>
						<button
							className='customBtn customBtnTransparent w100 marginTB10'
							onClick={() => {
								setEmailModalOpen(true);
							}}
						>
							{t("share_Via_Email")}
						</button>
						<CommonModal
							popupClass='emailModalPopup commonModalPopup'
							open={emailModalOpen}
							setOpen={setEmailModalOpen}
							title={t("share_Via_Email")}
							width='460px'
							data={
								<ConfirmationEmailForm
									authToken={authToken}
									siteConfig={siteConfig}
									confirmationData={confirmationData}
									setEmailModalOpen={setEmailModalOpen}
								/>
							}
						/>
						<button
							className='customBtn customBtnTransparent w100'
							onClick={() => {
								setPrintModalOpen(true);
							}}
						>
							{t("print")}
						</button>
						<CommonModal
							popupClass='printModalPopup commonModalPopup'
							open={printModalOpen}
							setOpen={setPrintModalOpen}
							width='700px'
							data={
								<PrintPreviewPopup
									authToken={authToken}
									siteConfig={siteConfig}
									ref={componentRef}
									confirmationData={confirmationData}
									currencySymbol={currencySymbol}
									hotelCheckInTime={hotelCheckInTime}
									hotelCheckOutTime={hotelCheckOutTime}
									setPrintModalOpen={setPrintModalOpen}
								/>
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
