import ClaridgesLogoImage from "../../../styles/images/claridges-logo-colored.png";
import BerkeleyLogoImage from "../../../styles/images/berkeley-logo.svg";
import ConnaughtLogoImage from "../../../styles/images/connaught-logo.svg";
import MaybourneLogoImage from "../../../styles/images/maybourne-beverly-hills-logo.svg";
import RivieraLogoImage from "../../../styles/images/riviera-logo.svg";
import EmoryLogoImage from "../../../styles/images/emory-logo.svg";
import { SITE_ID_LIST } from "../../../utils/constant";

const siteLogos = {
	[SITE_ID_LIST[0]]: ClaridgesLogoImage,
	[SITE_ID_LIST[1]]: BerkeleyLogoImage,
	[SITE_ID_LIST[2]]: ConnaughtLogoImage,
	[SITE_ID_LIST[3]]: MaybourneLogoImage,
	[SITE_ID_LIST[4]]: RivieraLogoImage,
	[SITE_ID_LIST[5]]: EmoryLogoImage,
	[SITE_ID_LIST[6]]: RivieraLogoImage,
	[SITE_ID_LIST[7]]: MaybourneLogoImage,
	[SITE_ID_LIST[8]]: EmoryLogoImage
};

const PrintLogo = ({ siteId, hotelName }) => {
	const imageSource = siteLogos[siteId] || "";
	return (
		<div className='column100 brandLogo textCenter'>
			<a className='dInlineBlock' href='/'>
				<img
					className='desktopLogo'
					src={imageSource}
					alt={`${hotelName}`}
					width='200'
				/>
			</a>
		</div>
	);
};

export default PrintLogo;
