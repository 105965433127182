import React from "react";
import parse from "html-react-parser";
import { capitalizeStr } from "../../utils/utils";
import { EXT_ICON_URL } from "../../utils/constant";

export default function SingleRoomDescription({ roomDetails }) {
  return (
    <div className='columnContainerFull'>
      <div className='columnWrap justifyContentCenter'>
        <div className='column60'>
          <h3>
            {roomDetails?.room_name ? capitalizeStr(roomDetails.room_name) : ""}
          </h3>
          {roomDetails.room_desc
            ? parse(
                roomDetails.room_desc.replace(
                  new RegExp("/UserFiles", "g"),
                  EXT_ICON_URL
                )
              )
            : ""}
        </div>
      </div>
    </div>
  );
}
