import { useState, useEffect, useContext } from "react";
import AppContext from "../utils/appContext";
import { useNavigate } from "react-router-dom";
import { route } from "../utils/route";
import { useErrorBoundary } from "react-error-boundary";
// import DummyData from '../utils/ansDummyData'

export const useConfirmation = ({ authToken, searchPanelData }) => {
	let navigate = useNavigate();
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [confirmationData, setConfirmationData] = useState(null);
	const { showBoundary } = useErrorBoundary();

	// fetch confirmation data from appContext
	const fetchConfirmationDetailsFromContext = async () => {
		try {
			await setLoading(true);

			if (Object.keys(context.confirmationData).length > 0) {
				// If confirmationData found
				setConfirmationData(context.confirmationData);
			} else {
				// if 'confirmationData' not found, send user to 'room listing' Page
				navigate(route.roomList);

				// setConfirmationData(DummyData.confirmationDataMultiDates);
				// setConfirmationData(DummyData.confirmationDataMultiRooms);
			}
		} catch (error) {
			// Show error boundary
			showBoundary(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (context.authToken && searchPanelData) {
			fetchConfirmationDetailsFromContext();
		}
	}, [context.authToken, searchPanelData]);

	return {
		loading,
		setLoading,
		confirmationData,
	};
};
