import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SliderList from '../../room-list/sliderList';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
};

const CommonModal = ({
    open,
    setOpen,
    videoArray,
    imageArray,
    isPlaying,
    setIsPlaying,
    imageName,
}) => {

  useEffect(() => {
    if (open) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [open])

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="imageSliderPopup"
        role="dialog"
			  aria-modal="true"
    >
        <Box sx={style}>
            <div id="modal-modal-description" className="modalImgPopup popupCloseIcon">
              <a aria-label='Close Button' onClick={() => handleClose()} className="closeIcon dInlineBlock" href="javascript:void(0);">Close</a>
              <SliderList
                imageArray={imageArray}
                videoArray={videoArray}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                imageName={imageName}
                fromPopUp={true}
              />
            </div>
        </Box>
    </Modal>
  )
}

export default CommonModal