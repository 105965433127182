export const apiList = {
	hotelList: { method: "post", url: "getsingleroom" },
	offerList: { method: "post", url: "getofferpage" },
	getPolicy: { method: "post", url: "fetchpolicy" },
	checkout: { method: "post", url: "checkout" },
	advanceAddon: { method: "post", url: "advanceaddon" },
	country: { method: "post", url: "getCountry" },
	confirmation: { method: "post", url: "fetchbookingdetails" },
	manageBooking: { method: "post", url: "managebooking" },
	savereservation: { method: "post", url: "savereservation" },
	cancelreservation: { method: "post", url: "cancelreservation" },
	modifyReservation: { method: "post", url: "modifyreservation" },
	seoMetaTags: { method: "post", url: "hotelmetadetail" },
	getToken: { method: "post", url: "authenticate" },
	getcrosssellsite: { method: "post", url: "getcrosssellsite" },
	siteMaintenance: { method: "post", url: "sitedowntime" },
	sendreservationemail: { method: "post", url: "sendreservationemail" },
	cancelReservationEmail: { method: "post", url: "sendcancellationemail" },
	guestGroupMessage: { method: "post", url: "messageclosedusergroup" }
};