import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import usePolicy from '../../../hooks/usePolicy';
// import ReactHtmlParser from "react-html-parser"
import Spinner from '../../../../includes/spinner/Spinner';
import { useTranslation } from "react-i18next"
import Loader from '../Loader';
import AppContext from "../../../utils/appContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    maxWidth: 785,
    minWidth: 785,
    minHeight: 275,
    p: 0,
    bgcolor: "#fff",
    textAlign: "left",
};

const PolicyModal = ({
	openPolicy,
	setOpenPolicy,
	rateId,
	roomId,
	roomRateTaxInfo,
	policyCodes
}) => {
	const { t } = useTranslation();
	const context = useContext(AppContext);
	const {
		policyData,
		loading,
		setPolicyData,
		setViewPolicyModal,
		viewPolicyModal,
	} = usePolicy({
		rateId,
		roomId,
		openPolicy,
		roomRateTaxInfo,
		policyCodes
	});

	useEffect(() => {
		context.setPolicyLoading(loading ? true : false);
	}, [loading]);

	useEffect(() => {
		if (openPolicy) {
			document.body.classList.add("bodyScrollHidden");
		} else {
			document.body.classList.remove("bodyScrollHidden");
		}
		return () => {
			document.body.classList.remove("bodyScrollHidden");
		};
	}, [openPolicy]);

	const handleClosePolicy = () => {
		setOpenPolicy(false);
		setViewPolicyModal(false);
		setPolicyData();
	};

	return (
		<>
			{/* {loading && <Spinner />} */}
			<Modal
				open={viewPolicyModal}
				onClose={handleClosePolicy}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='policyModalPopup'
				role="dialog"
			    aria-modal="true"
			>
				<Box sx={style}>
					<div className='white-popup-block'>
						<Typography
							id='modal-modal-title'
							variant='h6'
							component='h2'
						>
							{t("policies")}
							<button
								aria-label='Close Button'
								type='button'
								className='mfp-close'
								onClick={() => handleClosePolicy()}
							>
								Close
							</button>
						</Typography>
						{/* {ReactHtmlParser(policyData)} */}
						<div dangerouslySetInnerHTML={{ __html: policyData }} />
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default PolicyModal