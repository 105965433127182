export const baseUrl = process.env.REACT_APP_API_SERVER;
export const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const KEYS = {
	ROOM_DATA: "roomData",
	CHECK_IN_DATE: "checkInDate",
	CHECKOUT_DATE: "checkoutDate",
	HOTEL_ID: "hotelId",
	HOTEL_NAME: "hotelName",
	HOTEL_EMAIL: "hotelEmail",
	HOTEL_PHONE: "hotelPhone",
	CART_ITEMS: "cartItems",
	MODIFY_FLAG: "modifyFlag",
	HOTEL_CURRENCY: "hotelCurrency",
	BOOKING_CODE: "bookingcode",
	OFFER_PAGE_DATA: "offerPageData",
	SELECTED_FILTER: "selectedFilter",
	CATEGORY_FILTER: "categoryFilter",
	COMPARE_ROOM_ITEMS: "compareRoomItems",
	SEARCH_CODE: "searchcode",
	CONFIG: "config",
	SELECTED_CURRENCY: "selectedCurrency",
	I18_NEXT_LNG: "i18nextLng",
	LANG: "lang",
	CURRENT_SELECTED_ROOM: "currentselectedroom",
	IS_RESERVATION: "is_reservation",
	IS_TAX_INCLUSIVE: "isTaxInclusive",
	SITE_ID: "site_id",
	ROOM_CODE: "roomCode",
	RATE_CODE: "rateCode",
	IS_MODIFICATION: "is_modification"
};

export const DEFAULT_LANG = "en"
export const DEFAULT_CURRENCY = "GBP"
export const PREFIX = ["Mr.", "Mrs.", "Miss", "Ms."];
export const NUMBER_REGEX = /[^\d\/]|^[\/]*$/g
export const EXT_ICON_URL = process.env.REACT_APP_EXT_ICON_URL
export const SENDRESEMAIL = baseUrl + "sendreservationemail"
export const SENDCANCELLATIONEMAIL = baseUrl + "sendcancellationemail";
export const DEFAULT_COUNTRY_CODES = ["GB", "US", "DE", "ES", "CA", "FR"];
export const CC_FIPS = "UK"

export const SITE_ID_LIST = [
	31235, // Claridges
	31236, // The Berkley
	31237, // The Connaught
	10203058, // The Maybourne Beverly Hills
	10203059, // The Maybourne Riviera
	10203099, // The Emory
	55597, // The Maybourne Rivera
	55598, // The Maybourne Beverly Hills 
	55633 // The Emory
]

export const MBR_WEB_LINKS = {
	fr: 'https://www.maybourneriviera.com/fr/',
	en: 'https://www.maybourneriviera.com/'
}
