import React from "react";
import { useTranslation } from "react-i18next";
import {
	currencyDisplay,
	ConvertTime24HourTo12HoursWithMinutes,
} from "../../../utils/utils";
import parse from "html-react-parser";
import ConfirmPrintPopupPolicies from "./ConfirmPrintPopupPolicies";
import ConfirmPrintPopupDateWiseAddons from "./ConfirmPrintPopupDateWiseAddons";

export default function ConfirmPrintPopupRoomBasedStayDetails({
	room_stay,
	currencySymbol,
	confirmationData,
}) {
	const { t } = useTranslation();
	let hotelCheckInTimeIn24Hrs = confirmationData?.hotel_details?.check_in
		? confirmationData?.hotel_details?.check_in
		: "15:00";
	let hotelCheckInTime12Hrs = ConvertTime24HourTo12HoursWithMinutes(
		hotelCheckInTimeIn24Hrs
	);
	let hotelCheckOutTimeIn24Hrs = confirmationData?.hotel_details?.check_out
		? confirmationData?.hotel_details?.check_out
		: "12:00";
	let hotelCheckOutTime12Hrs = ConvertTime24HourTo12HoursWithMinutes(
		hotelCheckOutTimeIn24Hrs
	);


	return (
		<>
			<li key='room-based-stay-details'>
				<ul className='roomBasedStayDetails'>
					{room_stay.map((item, index) => {
						return (
							<>
								<li key={index}>
									<div className='columnWrap'>
										<div className='column100'>
											<span className='dBlock textUppercase'>
												{t("stay_details")}
												{room_stay.length > 1
													? (" - " + t("room") + " " +
													  parseInt(index + 1))
													: ""}
											</span>
										</div>
									</div>
									<div className='bookingDetailInfoWrap'>
										<div className='columnWrap'>
											<div className='column50'>
												<span>
													{t("reservation_number")}
												</span>
											</div>
											<div className='column50'>
												{item?.ConfirmationNumber ||
													"-"}
											</div>
										</div>
										<div className='columnWrap'>
											<div className='column50'>
												<span>{t("room_type")}</span>
											</div>
											<div className='column50'>
												{item?.roomName || "-"}
											</div>
										</div>
										<div className='columnWrap'>
											<div className='column50'>
												<span>{t("rate_type")}</span>
											</div>
											<div className='column50'>
												{item?.rateName.replace(/\\'/g, "'") || "-"}
											</div>
										</div>

										<div className='columnWrap'>
											<div className='column50'>
												<span>{t("nightly_rate")}</span>
											</div>
											<div className='column50'>
												{currencySymbol}
												{currencyDisplay(
													item?.price_per_room_without_tax ||
														0
												)}
											</div>
										</div>

										{confirmationData?.rate_plan_details[
											item.rateId
										]?.rate_tax_info && (
											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("roomrateincludes")}
													</span>
												</div>
												<div className='column50'>
													{
														confirmationData
															?.rate_plan_details[
															item.rateId
														]?.rate_tax_info
													}
												</div>
											</div>
										)}

										{item?.addons_data && (
											<ConfirmPrintPopupDateWiseAddons
												enhancements_str={t(
													"your_enhancement"
												)}
												total_enhancements={t(
													"total_enhancements"
												)}
												currencySymbol={currencySymbol}
												addons_data={item?.addons_data}
											/>
										)}

										{/* <div className='columnWrap'>
										<div className='column50'>
											<span>{t("stay_total")}</span>
										</div>
										<div className='column50'>
											{currencySymbol}
											{currencyDisplay(
												item?.total_cost || 0
											)}
										</div>
									</div> */}
									</div>
								</li>
								<li>
									<div className='bookingDetailInfoWrap'>
										<div className='columnWrap enhancementsPrintPreviewPopupLabel'>
											<div className='column100'>
												<span className='dBlock textUppercase'>
													{t("important_information")}
												</span>
											</div>
										</div>

										<div className='columnWrap enhancementsPrintRateDesc emailDescript popupPolicyPrintPop'>
											<div className='column100'>
												<span className='dBlock'>
													{t("rate_description")}
												</span>
												{parse(item?.rateRuleDesc) ||
													"-"}
											</div>
										</div>

										{item?.policies_data &&
											item?.deposit_policy && (
												<ConfirmPrintPopupPolicies
													deposit_policy={
														item?.deposit_policy
													}
													policies_data={
														item?.policies_data
													}
												/>
											)}

										<div className='columnWrap printPopuCheckIn'>
											<div className='column100'>
												<span className='dBlock'>
													{t("hotel_details")}
												</span>
											</div>
											<div className='column100'>
												{t("check_in_time")}:{" "}
												{hotelCheckInTimeIn24Hrs}
											</div>
										</div>
										<div className='columnWrap printPopuCheckOut'>
											<div className='column100'>
												{t("check_out_time")}:{" "}
												{hotelCheckOutTimeIn24Hrs ===
												"12:00"
													? hotelCheckOutTimeIn24Hrs +
													  " " +
													  t(
															"mid_day_check_inout_time"
													  )
													: hotelCheckOutTimeIn24Hrs}
											</div>
										</div>
									</div>
								</li>
							</>
						);
					})}
				</ul>
			</li>
		</>
	);
}
