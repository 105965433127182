import React, { useContext, useState, useEffect } from 'react'
import Input from '../common/Input/inputField'
import { useCountry } from '../../hooks/useCountry'
import AddressSuggestion from './addressSuggestion'
import Spinner from '../../../includes/spinner/Spinner'
import AppContext from '../../utils/appContext'
import { useTranslation } from "react-i18next"
import Loader from '../common/Loader'
import { replaceEmoji } from '../../utils/utils'
// import Select from "react-select"

const BillingDetails = ({
  activeTab,
  errors,
  register,
  handleTabClick,
  stopClose,
  watch,
  setError,
  setValue,
  clearErrors,
  bookingData,
  ref1
}) => {
  const { t } = useTranslation()
  let context = useContext(AppContext)
  const { countryData } = useCountry({})
  let [lookupAddress, setLookupAddress] = useState([])
  let [addressSuggestion, setAddressSuggestion] = useState(false)
  let [loading, setLoading] = useState(false)
  let [noResultMessage, setNoResultMessage] = useState("")
  let [countryCode, setCountryCode] = useState()

  const getAddressSuggestion = (e) => {
    if (watch("country") && watch("zipCode")) {
      e.preventDefault()
      setNoResultMessage("")
      setValue("addressLine1", "")
      setValue("addressLine2", "")
      setValue("city", "")
      setLookupAddress([])
      setAddressSuggestion(false)
      setLoading(true)
      fetch("https://idmp.gb.co.uk/idm-core-rest-globalservices/17a/authentication/authenticateUser?username=pegs@maybourne.com&password=Pegasus@5326")
        .then((res) => res.json())
        .then((authResponse) => {
          const authenticationToken = authResponse.authenticationToken
          const payload = {
            customerReference: "Test",
            profileGuid: "90C3BA3B-491D-4119-B5B6-0B8B624EEFAA",
            configurationId: "1",
            requestData: {
              address: {
                countryCode: countryCode,
                freeFormatAddress: watch("zipCode")
              },
              filters: null,
              options: {
                addressEnvelopeFormat: "A4P",
                offset: "0",
                maxReturn: "50",
                addressSearchLevel: "PREMISE",
                casing: "MIXED",
                transliteration: "INPUT"
              },
              additionalData: {
                item: [
                  {
                    key: "GEOCODE",
                    value: "yes"
                  }
                ]
              }
            }
          }
          const endPoint = "https://idmp.gb.co.uk/idm-core-rest-globalservices/17a/gbg/executeCapture"
          const header = {
            authenticationToken: authenticationToken,
            username: "pegs@maybourne.com",
            Accept: "application/json",
            "Content-Type": "application/json"
          }

          fetch(endPoint, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: header
          })
            .then(response => response.json())
            .then(res => {
              if (res?.profileResponseDetails?.[0]?.captureResponse?.response?.[0]) {
                res.profileResponseDetails[0].captureResponse.response[0].address.map(
                  (item, index) => {
                    res.profileResponseDetails[0].captureResponse.response[0].address[index].
                      formattedAddress = item.formattedAddress.replace(/(^[,\s]+)|([,\s]+$)/g, "");
                  }
                );
                setLookupAddress(res.profileResponseDetails[0].captureResponse.response[0].address)
                setNoResultMessage("")
                setAddressSuggestion(true)
                setLoading(false);
              } else {
                setNoResultMessage(t("no_address_found"))
                setAddressSuggestion(false)
                setLoading(false)
              }
            })
        }).catch(error => {
          setLoading(false)
          // console.log(error, t("save_reservation_error"))
        })
    } else {
      if (!watch("zipCode")) {
        setError('zipCode', { type: 'custom', message: t("zip_code_required") })
      }
    }
  }

  let addInitialAddress = value => {
    if (value) {
      let addressLine2 = "";
      let alternateAddressline1 = "";
      if (value.organisation) {
        alternateAddressline1 = value.organisation;
        if (value.buildingNumber) {
          addressLine2 = value.buildingNumber;
        }
      } else if (value.buildingNumber) {
        alternateAddressline1 = value.buildingNumber;
      }
      let street = "";
      if (value.street) {
        street = value.street;
      }
      if (value.subStreet) {
        if (alternateAddressline1 != "") {
          alternateAddressline1 = `${alternateAddressline1}, ${value.subStreet}`;
        } else {
          alternateAddressline1 = value.subStreet;
        }
      }
      if (street != "") {
        if (value.subLocality) {
          if (alternateAddressline1 != "") {
            alternateAddressline1 = `${alternateAddressline1}, ${street}`;
          } else {
            alternateAddressline1 = street;
          }
          if (addressLine2 != "") {
            addressLine2 = `${addressLine2}, ${value.subLocality}`;
          } else {
            addressLine2 = value.subLocality;
          }
        } else {
          if (addressLine2 != "") {
            addressLine2 = `${addressLine2}, ${street}`;
          } else {
            addressLine2 = street;
          }
        }
      } else if (value.subLocality) {
        if (alternateAddressline1 != "") {
          alternateAddressline1 = `${alternateAddressline1}, ${value.subLocality}`;
        } else {
          alternateAddressline1 = value.subLocality;
        }
      }
      let addressLine1 = value.organisation ? value.organisation : alternateAddressline1
      if (value.locality) {
        if (addressLine2 !== undefined && addressLine2 != "") {
          addressLine2 = `${addressLine2}, ${value.locality}`;
        } else {
          addressLine2 = value.locality;
        }
      }
      if (process.env.REACT_APP_CHECKOUT_V2?.split(',').includes(context?.configData?.site_id?.toString())) {
        setValue("addressLine1", `${addressLine1},${addressLine2}`)
      } else {
        setValue("addressLine1", addressLine1)
      }
      setValue("addressLine2", addressLine2 || "")
      setValue("city", value.town || "")
      clearErrors("addressLine1")
      clearErrors("city")
    }
  }

  const countryId = bookingData?.country_id || context?.configData?.user_country_id

  useEffect(() => {
    if (countryId) {
      setValue("country", countryId)
      if (countryData?.length) {
        setCountryCode(countryData?.find(code => code.country_id === countryId).cc_iso_3)
      }
    } else {

      if (countryData?.length) {
        setValue("country", countryData[0].country_id)
      }
    }
  }, [countryId, countryData])

  useEffect(() => {
    if (context.selectedPhone) {
      setValue("country", context.selectedPhone.country_id)
      if (countryData?.length) {
        setCountryCode(countryData?.find(code => code.country_id === context.selectedPhone.country_id).cc_iso_3)
      }
    }
  }, [context.selectedPhone])

  const scrollToTop = () => {
    handleTabClick(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const targetTagName = e.target.tagName.toLowerCase();
      if (targetTagName !== "input") {
        scrollToTop();
      }
    }
  };

  return (
    <div
      className="checkoutTab checkoutTabShow"
      tabIndex="0"
      onClick={scrollToTop}
      ref={ref1}
      onKeyPress={handleKeyPress}
    >
      {loading && <Loader />}
      <div className='columnWrap'>
        <label className='column100 marginB15 subTitles'>{t("billing_details")}</label>
        <div className="column50 marginB25">
          {/* <Select
                      defaultValue={PREFIX[0]}
                      options={PREFIX}
                      placeholder="Select Prefix"
                      {...rest}
                      onChange={(value) => setValue(labelText, value.value)}
                      isDisabled={isDisabled}
                    /> */}
          {/* <label className="hideTag" for="selectCountry">Country</label> */}
          <select
            autoComplete={`new-country` + Math.random().toString(36).substring(7)}
            title="Country"
            id="selectCountry"
            {...register("country", {
              onChange: (event) => {
                const selectedIndex = event.target.selectedIndex
                const selectedOption = event.target.options[selectedIndex]
                setValue("country", selectedOption.value)
                setCountryCode(selectedOption.getAttribute("data-cc-iso-3"))
              }
            })}
          >
            <option value="">{t("country")}<sup>*</sup></option>
            {countryData?.map(code =>
              <option
                selected={code.country_id === countryId}
                value={code.country_id}
                data-cc-iso-3={code.cc_iso_3}
              >{code.country_name}
              </option>
            )}
          </select>
          {errors.country?.message &&
            <p className='errorMessage pL-1'>{errors.country.message}</p>
          }
        </div>
        <div className="column50 marginB25">
          <div className="postalZipCode dFlex flexWrap">
            <Input
              title={t("postal_zip_code")}
              id="postalCode"
              maxLength='8'
              rest={register("zipCode", {
                onChange: (event) => {
                  setValue("zipCode", replaceEmoji(event.target.value))
                },
              })}
            />
            <span className="label" for="postalCode">{t("postal_zip_code") + "*"}</span>
            <a
              className="customBtn customBtnDark"
              href="javascript:void(0);"
              onClick={(e) => getAddressSuggestion(e)}
              style={{ textWrap: "nowrap" }}
            >
              {t("look_up")}
            </a>
          </div>
          {errors.zipCode?.message && <p className='errorMessage pL-1'>{errors.zipCode.message}</p>}
          {noResultMessage && <p className='errorMessage pL-1'>{noResultMessage}</p>}
        </div>
        {addressSuggestion ?
          <div className="column100" style={{ paddingBottom: "1rem" }}>
            <AddressSuggestion
              suggestion={lookupAddress}
              addInitialAddress={value => addInitialAddress(value)}
              t={t}
            />
          </div>
          : ""}
        <div className="column50 marginB25">
          <Input
            title={t("address_1")}
            id="add2"
            maxLength='50'
            rest={register("addressLine1", {
              onChange: (event) => {
                setValue("addressLine2", replaceEmoji(event.target.value))
              }
            })}
          />
          <span className="label" for="add1">{t("address_1") + "*"}</span>
          {errors?.zipCode?.message || errors?.addressLine1?.message
            ? <p className='errorMessage pL-1'>{t("billing_details_required")}</p> : ""}
        </div>
        <div className="column50 marginB25">
          <Input
            title={t("city")}
            id="city1"
            required
            maxLength='20'
            rest={register("city", {
              onChange: (event) => {
                setValue("city", replaceEmoji(event.target.value))
              },
            })}
          />
          <span className="label" for="city1">{t("city") + "*"}</span>
          {errors.city?.message &&
            <p className='errorMessage pL-1'>{t("city_required")}</p>
          }
        </div>
      </div>
      <div className='columnWrap additionalReq'>
        <div className='column100'>
          <label
            for="addiReq"
            className='dBlock marginB15 subTitles'
          >
            {t("additional_requests")}
          </label>
          <textarea
            id="addiReq"
            placeholder={t("additional_request_placeholder")}
            {...register("additional", {
              onChange: (event) => {
                setValue(
                  "additional",
                  replaceEmoji(event.target.value)
                );
              },
            })}
          ></textarea>
        </div>
      </div>

    </div>
  )
}

export default BillingDetails