import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { route } from "../utils/route";
import { fetchSiteMaintenance } from "../services/maintenance";
import { getSiteMaintenanceRequest } from "../utils/requestData";
// import DummyData from "../utils/ansDummyData";
import { SessionStorage } from "../utils/storage";
import { KEYS } from "../utils/constant";
import moment from "moment";
// import { useAuth } from "./useAuth";

export const useMaintenance = ({ authToken, siteConfig, searchPanelData }) => {
	let navigate = useNavigate();
	const location = useLocation();
	const [maintenanceMsg, setMaintenanceMsg] = useState(null);
	// let retryAttempted = false;
    // const { getAuth } = useAuth()
	// fetch confirmation data from appContext
	const checkSiteMaintenanceStatus = async () => {
		let siteId = siteConfig?.site_id;
		let hotelId = SessionStorage.get(KEYS.HOTEL_ID);
		let lang = siteConfig?.default_language;
		let requestData = getSiteMaintenanceRequest({ siteId, hotelId, lang });
		let siteIdWithLangAsKey = siteId + "_0_" + lang;
		try {
			await fetchSiteMaintenance(requestData, authToken).then((res) => {
				let currentTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
				let siteMaintenanceStartTime = res.data[siteIdWithLangAsKey].startDate;
				let siteMaintenanceEndTime = res.data[siteIdWithLangAsKey].endDate;
				if (siteMaintenanceStartTime !== undefined && siteMaintenanceEndTime !== undefined &&
					currentTime >= siteMaintenanceStartTime && currentTime <= siteMaintenanceEndTime) {
					setMaintenanceMsg(res.data[siteIdWithLangAsKey].message);
					navigate(route.maintenance);
				} else {
					let currentPath = location.pathname;
					if (currentPath === "/maintenance") { // navigate user to listing page if already on Maintenance Page
						navigate(route.roomList);
					}
				}
			});
		} catch (error) {
			// if (error.renewToken && !retryAttempted) {
			// 	getAuth().then(() => {
			// 		checkSiteMaintenanceStatus()
			// 		retryAttempted = true;
			// 	})
			// }
		} finally {
			// console.log("trigger final step ");
		}
	};
	useEffect(() => {
		if (authToken && searchPanelData) {
			// Initial API call
			checkSiteMaintenanceStatus();
			// Schedule the API call every 5 minutes
			const intervalId = setInterval(checkSiteMaintenanceStatus, 5 * 60 * 1000);
			// Clean up the interval when the component unmounts
			return () => clearInterval(intervalId);
		}
	}, [searchPanelData]);
	return {
		maintenanceMsg,
	};
};
