import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmPrintPopupPolicies({ deposit_policy, policies_data }) {
	const { t } = useTranslation();
	let paymentPolicy = deposit_policy || "-";
	let amendmentPolicy = policies_data?.amendment?.summary || "-";
	let cancellationPolicy = policies_data?.cancellation?.summary || "-";

	return (
		<>
			{policies_data?.samemodifycancel ? (
				<div className='columnWrap popupPolicyPrintPop'>
					<div className='column100'>
						<span className='dBlock'>
							{t("amendment_policy")}
						</span>
						{cancellationPolicy}
					</div>
				</div>
			) : (
				<>
					<div className='columnWrap popupPolicyPrintPop'>
						<div className='column100'>
							<span className='dBlock'>
								{t("amendment_policy_str")}
							</span>
							{amendmentPolicy}
						</div>
					</div>
					<div className='columnWrap popupPolicyPrintPop'>
						<div className='column100'>
							<span className='dBlock'>
								{t("cancellation_policy")}
							</span>
							{cancellationPolicy}
						</div>
					</div>
				</>
			)}
			<div className='columnWrap popupPolicyPrintPop'>
				<div className='column100'>
					<span className='dBlock'>
						{t("payment_policy")}
					</span>
					{paymentPolicy}
				</div>
			</div>
		</>
	);
}
