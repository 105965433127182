import React, { useContext, useState, useEffect } from 'react'
import Input from '../common/Input'
import { useCountry } from '../../hooks/useCountry'
import AddressSuggestion from './addressSuggestion'
import Spinner from '../../../includes/spinner/Spinner'
import AppContext from '../../utils/appContext'
import { useTranslation } from "react-i18next"
import Loader from '../common/Loader'
import { replaceEmoji } from '../../utils/utils'
// import Select from "react-select"

const BillingDetails = ({
    activeTab,
    errors,
    register,
    handleTabClick,
    stopClose,
    watch,
    setError,
    setValue,
    clearErrors,
    bookingData,
    ref1
}) => {
    const { t } = useTranslation()
    let context = useContext(AppContext)
    const { countryData } = useCountry({})
    let [lookupAddress, setLookupAddress] = useState([])
    let [addressSuggestion, setAddressSuggestion] = useState(false)
    let [loading, setLoading] = useState(false)
    let [noResultMessage, setNoResultMessage] = useState("")
    let [countryCode, setCountryCode] = useState()

    const getAddressSuggestion = (e) => {
      if (watch("country") && watch("zipCode")) {
        e.preventDefault()
        setNoResultMessage("")
        setValue("addressLine1", "")
        setValue("addressLine2", "")
        setValue("city", "")
        setLookupAddress([])
        setAddressSuggestion(false)
        setLoading(true)
        fetch("https://idmp.gb.co.uk/idm-core-rest-globalservices/17a/authentication/authenticateUser?username=pegs@maybourne.com&password=Pegasus@5326")
        .then((res) => res.json())
        .then((authResponse) => {
            const authenticationToken = authResponse.authenticationToken
            const payload = {
                customerReference: "Test",
                profileGuid: "90C3BA3B-491D-4119-B5B6-0B8B624EEFAA",
                configurationId: "1",
                requestData: {
                    address: {
                        countryCode: countryCode,
                        freeFormatAddress: watch("zipCode")
                    },
                    filters: null,
                    options: {
                        addressEnvelopeFormat: "A4P",
                        offset: "0",
                        maxReturn: "50",
                        addressSearchLevel: "PREMISE",
                        casing: "MIXED",
                        transliteration: "INPUT"
                    },
                    additionalData: {
                        item: [
                            {
                                key: "GEOCODE",
                                value: "yes"
                            }
                        ]
                    }
                }
            }
            const endPoint = "https://idmp.gb.co.uk/idm-core-rest-globalservices/17a/gbg/executeCapture"
            const header = {
                authenticationToken: authenticationToken,
                username: "pegs@maybourne.com",
                Accept: "application/json",
                "Content-Type": "application/json"
            }

            fetch(endPoint, {
              method: "POST",
              body: JSON.stringify(payload),
              headers: header
            })
            .then(response => response.json())
            .then(res => {
              if(res?.profileResponseDetails?.[0]?.captureResponse?.response?.[0]) {
                  res.profileResponseDetails[0].captureResponse.response[0].address.map(
                    (item, index) => {
                      res.profileResponseDetails[0].captureResponse.response[0].address[index].
                      formattedAddress = item.formattedAddress.replace(/(^[,\s]+)|([,\s]+$)/g, "");
                    }
                  );
                  setLookupAddress(res.profileResponseDetails[0].captureResponse.response[0].address)
                  setNoResultMessage("")
                  setAddressSuggestion(true)
                  setLoading(false);
                } else {
                  setNoResultMessage(t("no_address_found"))
                  setAddressSuggestion(false)
                  setLoading(false)
                }
              })
            }).catch(error => {
              setLoading(false)
              // console.log(error, t("save_reservation_error"))
            })
      } else {
        if (!watch("zipCode")) {
          setError('zipCode', { type: 'custom', message: t("zip_code_required") })
        }
      }
    }

  let addInitialAddress = value => {
      if (value) {
        let addressLine2 = "";
        let alternateAddressline1 = "";
        if (value.organisation) {
          alternateAddressline1 = value.organisation;
          if (value.buildingNumber) {
            addressLine2 = value.buildingNumber;
          }
        } else if (value.buildingNumber) {
          alternateAddressline1 = value.buildingNumber;
        }    
        let street = "";
        if (value.street) {
          street = value.street;
        }
        if (value.subStreet) {
          if (alternateAddressline1 != "") {
            alternateAddressline1 = `${alternateAddressline1}, ${value.subStreet}`;
          } else {
            alternateAddressline1 = value.subStreet;
          }
        }
        if (street != "") {
          if (value.subLocality) {
            if (alternateAddressline1 != "") {
              alternateAddressline1 = `${alternateAddressline1}, ${street}`;
            } else {
              alternateAddressline1 = street;
            }
            if (addressLine2 != "") {
              addressLine2 = `${addressLine2}, ${value.subLocality}`;
            } else {
              addressLine2 = value.subLocality;
            }
          } else {
            if (addressLine2 != "") {
              addressLine2 = `${addressLine2}, ${street}`;
            } else {
              addressLine2 = street;
            }
          }
        } else if (value.subLocality) {
          if (alternateAddressline1 != "") {
              alternateAddressline1 = `${alternateAddressline1}, ${value.subLocality}`;
          } else {
            alternateAddressline1 = value.subLocality;
          }
        }
        let addressLine1 = value.organisation ? value.organisation : alternateAddressline1
        if (value.locality) {
          if (addressLine2 !== undefined && addressLine2 != "") {
            addressLine2 = `${addressLine2}, ${value.locality}`;
          } else {
            addressLine2 = value.locality;
          }
        }
        setValue("addressLine1", addressLine1)
        setValue("addressLine2", addressLine2)
        setValue("city", value.town)
        clearErrors("addressLine1")
        clearErrors("city")
      }
  }

  const countryId = bookingData?.country_id || context?.configData?.user_country_id

  useEffect(() => {
    if (countryId) {
      setValue("country", countryId)
      if (countryData?.length) {
        setCountryCode(countryData?.find(code => code.country_id === countryId).cc_iso_3)
      }
    }else{
      
      if (countryData?.length) {
        setValue("country", countryData[0].country_id)
      }
    }
  }, [countryId, countryData])

  useEffect(() => {
    if (context.selectedPhone) {
      setValue("country", context.selectedPhone.country_id)
      if (countryData?.length) {
        setCountryCode(countryData?.find(code => code.country_id === context.selectedPhone.country_id).cc_iso_3)
      }
    }
  }, [context.selectedPhone])
  
  const scrollToTop = () => {
    handleTabClick(1);
  };
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const targetTagName = e.target.tagName.toLowerCase();
      if (targetTagName !== "input") {
        scrollToTop();
      }
    }
  };

  return (
    <div 
      className={`checkoutTab ${activeTab === 1 ? 'checkoutTabShow' : ''}`}
      tabIndex="0"
      onClick={scrollToTop}
      ref={ref1}
      onKeyPress={handleKeyPress}
    >
        {loading && <Loader />}
        <h2 className="textUppercase">{t("billing_details")}</h2>
        <div className="checkoutTabDetails" onClick={(e) => stopClose(e)}>
            <div className="columnWrap">
                <div className="column50 marginB25">
                    {/* <Select
                      defaultValue={PREFIX[0]}
                      options={PREFIX}
                      placeholder="Select Prefix"
                      {...rest}
                      onChange={(value) => setValue(labelText, value.value)}
                      isDisabled={isDisabled}
                    /> */}
                    {/* <label className="hideTag" for="selectCountry">Country</label> */}
                    <select
                      autoComplete={`new-country`+Math.random().toString(36).substring(7)}
                      title="Country"
                      id="selectCountry"
                      {...register("country", {
                        onChange: (event) => {
                            const selectedIndex = event.target.selectedIndex
                            const selectedOption = event.target.options[selectedIndex]
                            setValue("country", selectedOption.value)
                            setCountryCode(selectedOption.getAttribute("data-cc-iso-3"))
                        }
                      })}
                    >
                      <option value="">Select Country</option>
                        {countryData?.map(code => 
                            <option
                              selected={code.country_id === countryId}
                              value={code.country_id}
                              data-cc-iso-3={code.cc_iso_3}
                            >{code.country_name}
                            </option>
                        )}
                    </select>
                    {errors.country?.message &&
                        <p className='errorMessage'>{errors.country.message}</p>
                    }
                </div>
                <div className="column50 marginB25">
                  <div className="postalZipCode dFlex flexWrap">
                    {/* <label className="hideTag" for="postalCode">{t("postal_zip_code")}</label> */}
                      <Input
                          title={t("postal_zip_code")}
                          id="postalCode"
                          placeholder={t("postal_zip_code")}
                          required
                          maxLength='8'
                          rest={register("zipCode",{
                            onChange: (event) => {
                              setValue("zipCode", replaceEmoji(event.target.value))
                            },
                          })}
                      />
                    <a
                        className="customBtn customBtnDark"
                        href="javascript:void(0);"
                        onClick={(e) => getAddressSuggestion(e)}
                    >
                      {t("lookup")}
                    </a>
                  </div>
                  {errors.zipCode?.message && <p className='errorMessage'>{errors.zipCode.message}</p> }
                  {noResultMessage && <p className='errorMessage'>{noResultMessage}</p> }
                </div>
                {addressSuggestion ?
                  <div className="column100 marginB25">
                    <div className="columnWrap">
                      <div className="column50">
                        <AddressSuggestion
                          suggestion={lookupAddress}
                          addInitialAddress={value => addInitialAddress(value)}
                          t={t}
                        />
                      </div>
                    </div>
                  </div> : ""}
                <div className="column50 marginB25">
                  {/* <label className="hideTag" for="add1">{t("address_1")}</label> */}
                  <Input
                    title={t("address_1")}
                    id="add1"
                    placeholder={t("address_1")}
                    required
                    maxLength='50'
                    rest={register("addressLine1", {
                      onChange: (event) => {
                        setValue("addressLine1", replaceEmoji(event.target.value))
                      }
                    })}
                    error={errors.addressLine1?.message}
                  />
                </div>
                <div className="column50 marginB25">
                  {/* <label className="hideTag" for="add2">{t("address_2")}</label> */}
                    <Input
                      title={t("address_2")}
                      id="add2"
                      placeholder={t("address_2")}
                      maxLength='50'
                      rest={register("addressLine2", {
                        onChange: (event) => {
                          setValue("addressLine2", replaceEmoji(event.target.value))
                        }
                      })}
                    />
                </div>
                <div className="column50 marginB25">
                  {/* <label className="hideTag" for="city1">{t("city")}</label> */}
                    <Input
                      title={t("city")}
                      id="city1"
                      placeholder={t("city")}
                      required
                      maxLength='20'
                      rest={register("city",{
                        onChange: (event) => {
                          setValue("city", replaceEmoji(event.target.value))
                        },
                      })}
                      error={errors.city?.message}
                    />
                </div>
            </div>
        </div>
        {errors?.zipCode?.message || errors?.addressLine1?.message || errors?.city?.message 
          ? <p className='errorMessage'>{t("billing_details_required")}</p> : ""}
    </div>
  )
}

export default BillingDetails