import { currencyDisplay } from "../../../utils/utils";

export default function YourStayBookingTotal({ strBookingTotal, currencySymbol, totalCost }) {
	return (
		<>
			<div className='columnWrap sidebarCartTotal paddingTB20 alignItemsCenter'>
				<div className='column60 pL0'>
					<label className='textUppercase'>{strBookingTotal}</label>
				</div>
				<div className='column40 textRight pR0'>
					<span className='dBlock sidebarCartPrice'>
						{currencySymbol + currencyDisplay(totalCost)}
					</span>
				</div>
			</div>
		</>
	);
}
