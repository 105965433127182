import React, { useState, useRef, useEffect, useContext } from "react";
import { useConfirmation } from "../../hooks/useConfirmation";
import DiscoverHotel from "./discoverHotel";
import StayDetails from "./stayDetails";
import CommonModal from "../common/Modal/commonModal";
import ConfirmationEmailForm from "./confirmationEmailForm";
import { useTranslation } from "react-i18next";
import { route } from "../../utils/route";
import Loader from "../common/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import PrintPreviewPopup from "./printPopup/PrintPreviewPopup";
import { SITE_ID_LIST } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import moment from "moment";
import AppContext from "../../utils/appContext";

export default function ConfirmationComponent({
	authToken,
	siteConfig,
	searchPanelData,
}) {
	const { loading, confirmationData } = useConfirmation({ authToken, searchPanelData });
	const { t } = useTranslation();
	const [emailModalOpen, setEmailModalOpen] = useState(false);
	const [printModalOpen, setPrintModalOpen] = useState(false);
	const componentRef = useRef();
	const crsConfirmationNo = confirmationData?.room_stay[0]?.ConfirmationNumber;
	let hotelCheckInTime = confirmationData?.hotel_details?.check_in || "15:00";
	let hotelCheckOutTime = confirmationData?.hotel_details?.check_out || "12:00";
	let currencySymbol = getSymbolFromCurrency(confirmationData?.currsign);
	const context = useContext(AppContext);
	let roomData = '[{"room":1,"adults":1,"childrens":[],"price":[],"room_info":{"room_id":0, "rate_id":0, "room_name": "", "rate_name":""}}]';
	sessionStorage.setItem("roomData", roomData);
	SessionStorage.set("is_reservation", "");
	SessionStorage.set("is_modification", "");

	useEffect(() => {
		if (confirmationData != null && siteConfig.google_tag_head_code != 0 && siteConfig.google_tag_head_code != '') {
			let checkindate = confirmationData?.date_in
			let checkoutdate = confirmationData?.date_out
			let checkin = moment(checkindate, "YYYY-MM-DD");
			let checkout = moment(checkoutdate, "YYYY-MM-DD");
			let todydate = moment(new Date(), "YYYY-MM-DD");
			let totalNights = checkout.diff(checkin, "days");
			let totalbookingwindow = checkin.diff(todydate, "days");
			let itemsArray = [];
			let totalGuest = 0;
			let itemIndex = 0;
			confirmationData.room_stay?.map(item => {
				totalGuest = totalGuest + parseInt(item.adult) + parseInt(item.child)
				let skuId = item.roomId + "_" + item.rateId + "-" + item.adult + "_" + item.child;

				let itemfind = itemsArray.some(itemsArray => itemsArray.index === skuId)

				if (!itemfind) {
					let roomItem = {
						"item_id": confirmationData?.get_room_type_details[item.roomId].roomcode,
						"item_name": item.roomName,
						"item_ratecode": confirmationData?.rate_plan_details[item.rateId].ratecode,
						"affiliation": confirmationData?.hotel_details?.hotel_name,
						"coupon": confirmationData?.code,
						"currency": confirmationData?.currsign,
						"discount": 0,
						"index": skuId,
						"item_brand": confirmationData?.hotel_details?.hotel_name,
						"item_numberofadults": item.adult,
						"item_numberofchildren": item.child,
						"item_variant": item.rateName,
						"price": item.price_per_room_without_tax,
						"quantity": totalNights,
						"item_category": "Room"
					}
					itemsArray.push(roomItem);
					item.addons_data && Object.entries(item.addons_data).forEach(([addonkey, addondetail]) => {
						let addonItem = {
							"item_id": addonkey,
							"item_name": addondetail.title,
							"affiliation": confirmationData?.hotel_details?.hotel_name,
							"coupon": "",
							"currency": confirmationData?.currsign,
							"discount": 0,
							"index": addonkey,
							"price": addondetail.addon_rate,
							"quantity": addondetail.quantity,
							"item_category": "Add-on"
						}
						itemsArray.push(addonItem);
					});
					itemIndex++;
				} else {
					itemsArray.map((itemkey, itemsdata) => {
						let skuId = item.roomId + "_" + item.rateId + "-" + item.adult + "_" + item.child;
						if (itemsdata.index == skuId) {
							itemsArray[itemkey].quantity = parseInt(itemsArray[itemkey].quantity) + totalNights;
						}
					});
				}
			});
			window.dataLayer = window.dataLayer || [];
			const tagManagerArgs = {
				"event": "purchase",
				"ecommerce": {
					"transaction_id": crsConfirmationNo,
					"affiliation": confirmationData?.hotel_details?.hotel_name,
					"value": confirmationData?.total_cost,
					"tax": "",
					"shipping": 0,
					"currency": confirmationData?.currsign,
					"coupon": confirmationData?.code,
					"item_check_in": confirmationData?.date_in,
					"item_check_out": confirmationData?.date_out,
					"item_hotel_ID": confirmationData?.hotel_details?.hotel_id,
					"los": totalNights,
					"item_totalnumberofrooms": itemIndex,
					"item_numberofguests": totalGuest,
					"language": confirmationData?.booking_lang,
					"item_bookingwindow": totalbookingwindow,
					"items": itemsArray
				},
			};
			window.dataLayer.push(tagManagerArgs);
		}

		if (confirmationData && context?.hotelData?.wihp_mapping_id) {
			if (siteConfig?.allow_wihp_tracking_code === "1") {
				window._wic = window._wic || [];
				window._wic.push(['_setHotel', context?.hotelData?.wihp_mapping_id]);
				window._wic.push(['_setRef', crsConfirmationNo]);
				window._wic.push(['_setAmount', confirmationData?.total_cost]);
				window._wic.push(['_setCurrency', confirmationData?.currsign]);
				window._wic.push(['_setIDBE', '3']);
				window._wic.push(['_setCheckin', confirmationData?.date_in]);
				window._wic.push(['_setCheckout', confirmationData?.date_out]);
				window._wic.push(['_setDateFormat', 'YYYY-MM-DD']);
				const wh = document.createElement('script');
				wh.type = 'text/javascript';
				wh.async = true;
				wh.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'p.relay-t.io/conv.js';
				const s = document.getElementsByTagName('script')[0];
				s.parentNode.insertBefore(wh, s);
			}

			if (siteConfig?.["allow_google-wihp_tracking_code"] === "1") {
				const inputString = context.hotelData?.wihp_mapping_detail;
				const conversionIdMatch = inputString.match(/"google_wihp_conversion_id";s:\d+:"([^"]+)"/);
				const conversionLabelMatch = inputString.match(/"google_wihp_conversion_label";s:\d+:"([^"]+)"/);
				const conversionId = conversionIdMatch ? conversionIdMatch[1] : null;
				const conversionLabel = conversionLabelMatch ? conversionLabelMatch[1] : null;
				let checkindate = confirmationData?.date_in
				let checkoutdate = confirmationData?.date_out
				let checkin = moment(checkindate, "YYYY-MM-DD");
				let checkout = moment(checkoutdate, "YYYY-MM-DD");
				let totalNights = checkout.diff(checkin, "days");
				const script = document.createElement('script');
				script.src = `https://www.googletagmanager.com/gtag/js?id=AW-${conversionId}`;
				script.async = true;
				script.onload = () => {
				  const gtagScript = document.createElement('script');
				  gtagScript.type = 'text/javascript';
				  script.innerHTML = `
				  gtag('event', 'purchase', {
					'send_to': [
					  'AW-${conversionId}/${conversionLabel}',
					  'HA-75'
					],
					'transaction_id': '${crsConfirmationNo}',
					'value': ${confirmationData?.total_cost},
					'currency': '${confirmationData?.currsign}',
					'items': [{
					  'id': '${context?.hotelData?.wihp_mapping_id}',
					  'start_date': '${confirmationData?.date_in}',
					  'end_date': '${confirmationData?.date_out}'
					}]
				  });
				`;
				  document.head.appendChild(gtagScript);
				};
				document.head.appendChild(script);

				window.uetq.push('event',
					'my_hotel_event_action', {
					'hct_total_price': confirmationData?.total_cost,
					'hct_base_price': confirmationData?.total_cost,
					'currency': confirmationData?.currsign,
					'hct_checkin_date': confirmationData?.date_in,
					'hct_checkout_date': confirmationData?.date_out,
					'hct_length_of_stay': totalNights,
					'hct_partner_hotel_id': context?.hotelData?.wihp_mapping_id,
					'hct_booking_xref': crsConfirmationNo
				});
			}
		}
	}, [confirmationData])
	
	return (
		<main className='mainContent confirmationPageFinal'>
			{loading && <Loader />}

			{confirmationData ? (
				<div className='columnContainerFull'>
					{confirmationData?.response_type}
					<div className='columnWrap'>
						<div className='column70 paddingTB50'>
							<div className='pageTitleWrapConfirmation pageTitleWrap2 paddingB30 marginB30'>
								<span className='dBlock textUppercase'>
									{t("good_news")}
								</span>
								<h1 className='m0'>
									{t("your_booking_confirmed") +
										", " +
										confirmationData?.title + " " +confirmationData?.last_name +
										". " +
										t("we_inform_shortly")}
									.
									{/*  Display additional information for 'the Emory'  */}
									{(siteConfig?.site_id === SITE_ID_LIST[5] || siteConfig?.site_id === SITE_ID_LIST[8])
										&& <em className="dBlock">
											{t("your_emory_assistance")}.
										</em>
									}
								</h1>
								
							</div>

							<div className='confirmationPageBtnMobile'>
								<div className='cartSidebarBtn'>
									<button
										className='customBtn customBtnDark w100'
										onClick={() =>
											window.open(
												route.manageBookings,
												"_blank"
											)
										}
									>
										{t("modify_Reservation")}
									</button>
									<button
										className='customBtn customBtnTransparent w100 marginTB10'
										onClick={() => {
											setEmailModalOpen(true);
										}}
									>
										{t("share_Via_Email")}
									</button>
									<CommonModal
										popupClass='emailModalPopup commonModalPopup'
										open={emailModalOpen}
										setOpen={setEmailModalOpen}
										title={t("share_Via_Email")}
										width='460px'
										data={
											<ConfirmationEmailForm
												authToken={authToken}
												siteConfig={siteConfig}
												confirmationData={
													confirmationData
												}
												setEmailModalOpen={
													setEmailModalOpen
												}
											/>
										}
									/>
									<button
										className='customBtn customBtnTransparent w100'
										onClick={() => {
											setPrintModalOpen(true);
										}}
									>
										{t("print")}
									</button>
									<CommonModal
										popupClass='printModalPopup commonModalPopup'
										open={printModalOpen}
										setOpen={setPrintModalOpen}
										width='800px'
										data={
											<PrintPreviewPopup
												authToken={authToken}
												siteConfig={siteConfig}
												ref={componentRef}
												confirmationData={
													confirmationData
												}
												currencySymbol={currencySymbol}
												hotelCheckInTime={
													hotelCheckInTime
												}
												hotelCheckOutTime={
													hotelCheckOutTime
												}
												setPrintModalOpen={
													setPrintModalOpen
												}
											/>
										}
									/>
								</div>
							</div>

							<DiscoverHotel
								confirmationData={confirmationData}
								addon_list={
									confirmationData.post_res_addon_list
								}
							/>
						</div>
						<StayDetails
							authToken={authToken}
							siteConfig={siteConfig}
							setPrintModalOpen={setPrintModalOpen}
							currencySymbol={currencySymbol}
							hotelCheckInTime={hotelCheckInTime}
							hotelCheckOutTime={hotelCheckOutTime}
							crsConfirmationNo={crsConfirmationNo}
							confirmationData={confirmationData}
						/>
					</div>
				</div>
			) : (
				""
			)}
		</main>
	);
}
