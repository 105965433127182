export default function YourStayCheckInOut({
	strCheckIn,
	strCheckOut,
	strAfter,
	strBefore,
	checkInDate,
	checkOutDate,
	hotelCheckInTime,
	hotelCheckOutTime,
}) {
	return (
		<div className='columnWrap cartSidebarRow sidebarDate paddingB20'>
			<div className='column40 pL0'>
				<label className='dBlock textUppercase'>{strCheckIn}</label>
				<span className='dBlock checkInDate bookingDate'>
					{/* {confirmationData?.date_in} */}
					{checkInDate}
				</span>
				<span className='dBlock timeInBtw'>
					{strAfter} {hotelCheckInTime}
				</span>
			</div>
			<div className='column40 pL0'>
				<label className='dBlock textUppercase'>{strCheckOut}</label>
				<span className='dBlock checkOutDate bookingDate'>
					{/* {confirmationData?.date_out} */}
					{checkOutDate}
				</span>
				<span className='dBlock timeInBtw'>
					{strBefore} {hotelCheckOutTime}
				</span>
			</div>
			<div className='column20 textRight pR0'></div>
		</div>
	);
}
