import React, { useState, useContext } from "react";
import { imageUrl, KEYS } from "../../utils/constant";
import { SessionStorage } from "../../utils/storage";
import CommonModal from "../common/Modal";
import SliderList from "./sliderList";
import AppContext from "../../utils/appContext";
import imagePopupIcon from "../../styles/images/full-screen.png";

const RoomImages = ({ roomImage, roomDetails }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [open, setOpen] = useState(false);

	const context = useContext(AppContext);

	let imageArray = [];
	let videoArray = [];
	roomImage?.map((item, index) => {
		let checkImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(
			item.thumb_img_name
		);
		if (checkImage) {
			let path = `${imageUrl}/hotel_images/${SessionStorage.get(
				KEYS.HOTEL_ID
			)}`;
			imageArray[index] = `${path}/${item.thumb_img_name}`;
		} else {
			videoArray[index] = item.thumb_img_name;
		}
	});

	let isRoomExist = -1;
	let compareItems = JSON.parse(SessionStorage.get(KEYS.COMPARE_ROOM_ITEMS));
	if (compareItems.length > 0 && roomDetails !== null) {
		isRoomExist = compareItems.findIndex(
			(filterItem) => filterItem.room_id === roomDetails.room_id
		);
	}

	const compareRoomAdd = (roomDetail) => {
		if (compareItems.length > 3) {
			return false;
		}
		if (compareItems.length === 3) {
			setTimeout(() => {
				let element= document.getElementById(document?.getElementById('userAlertNotification')?.getAttribute('data-ispopupvisible')?"compareTitlePop":"compareTitle");
				element?.scrollIntoView();
			}, 100);
		}
		if (compareItems.length > 0) {
			let isItemExist = compareItems.findIndex(
				(filterItem) => filterItem.room_id === roomDetail.room_id
			);
			if (isItemExist >= 0) {
				//Do Nothing
			} else {
				let imagePath = "";

				for (var i = 0; i < roomDetail.room_images.length; i++) {
					let checkImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(
						roomDetail.room_images[i].thumb_img_name
					);
					if (checkImage) {
						imagePath = roomDetail.room_images[i].thumb_img_name;
						break;
					}
				}

				let data = {
					room_id: roomDetail.room_id,
					room_name: roomDetail.room_name,
					room_image: imagePath ? imagePath : "",
					keyhighlight: roomDetail.key_highlights,
				};
				compareItems.push(data);
			}
			sessionStorage.setItem(
				"compareRoomItems",
				JSON.stringify(compareItems)
			);
			context.setCompareRoomsData(compareItems);
		} else {
			compareItems = [];
			let imagePath = "";

			for (var i = 0; i < roomDetail.room_images.length; i++) {
				let checkImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(
					roomDetail.room_images[i].thumb_img_name
				);
				if (checkImage) {
					imagePath = roomDetail.room_images[i].thumb_img_name;
					break;
				}
			}
			let cartData = {
				room_id: roomDetail.room_id,
				room_name: roomDetail.room_name,
				room_image: imagePath ? imagePath : "",
				keyhighlight: roomDetail.key_highlights,
			};
			compareItems.push(cartData);
			sessionStorage.setItem(
				"compareRoomItems",
				JSON.stringify(compareItems)
			);
			context.setCompareRoomsData(JSON.stringify(compareItems));
		}
	};

	const compareRoomRemove = (roomDetail) => {
		if (compareItems.length > 0) {
			let isItemExist = compareItems.findIndex(
				(filterItem) => filterItem.room_id === roomDetail.room_id
			);
			if (isItemExist >= 0) {
				compareItems.splice(isItemExist, 1);
			}

			if (compareItems.length <= 0) {
				sessionStorage.removeItem("compareRoomItems");
			}
			sessionStorage.setItem(
				"compareRoomItems",
				JSON.stringify(compareItems)
			);
			context.setCompareRoomsData(JSON.stringify(compareItems));
		}
	};
	
	return (
		<>
			<div className='column50 posRelative'>
				<div className='roomListImages posRelative'>
					<img
						aria-hidden='true'
						className='fullImgPopup'
						src={imagePopupIcon}
						alt='Enlarge Image'
					/>
					<small className='openImgFullView'>
						Click any image to open in full screen
					</small>
					<SliderList
						imageArray={imageArray}
						videoArray={videoArray}
						imageName={roomDetails?.room_name}
						setOpen={setOpen}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						minHeight='340px'
						fromPopUp={false}
					/>
					<CommonModal
						open={open}
						setOpen={setOpen}
						imageArray={imageArray}
						videoArray={videoArray}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						imageName={roomDetails?.room_name}
					/>
				</div>
				{context.compareRooms ? (
					isRoomExist < 0 ? (
						<span
							className='addinCompareList'
							onClick={() => compareRoomAdd(roomDetails)}
							tabIndex='0'
						></span>
					) : (
						<span
							className='addinCompareList addRoomComp'
							onClick={() => compareRoomRemove(roomDetails)}
							tabIndex='0'
						></span>
					)
				) : (
					<></>
				)}
			</div>
		</>
	);
};
export default RoomImages;
