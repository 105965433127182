import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import AppContext from "../../utils/appContext";
import Moment from "moment";
import { MAX_DATE, getMinDate } from "../../utils/utils";

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE
);

const DateRangeBooking = ({
  checkInDate,
  checkOutDate,
  onChangeDate,
  openGuests
}) => {
  const { t } = useTranslation()
  let context = useContext(AppContext);
  const [value, setValue] = useState([dayjs(checkInDate), dayjs(checkOutDate)])
  const [continueText, setContinueText] = useState(false)

  let language = localStorage.getItem("i18nextLng") || "en";

  useEffect(() => {
    setValue([dayjs(checkInDate), dayjs(checkOutDate)])
  }, [checkInDate])

  return (
    <>
      <div class="cartSidebarWrap">
        <div class="cartSidebar dateRangeSideBar">
          <div class="cartRoomInfoSidebar">
            <div class="cartSidebarTop posRelative">
              <div class="columnWrap cartSidebarRow sidebarRoomList">
                <div class="cartSidebarRow sidebarDate w100">
                  <div class="columnWrap">
                    <div class="column50 pL0">
                      <small class="dBlock textUppercase">{t("check_in")}</small>
                      <span class="dBlock checkInDate bookingDate">
                        {value?.[0] && Moment(value[0]).format("ddd, DD MMM YYYY")}
                      </span>
                    </div>
                    <div class="column50 pL0">
                      <small class="dBlock textUppercase">{t("check_out")}</small>
                      <span class="dBlock checkOutDate bookingDate">
                        {value?.[1] && Moment(value[1]).format("ddd, DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sidebarDateWrap paddingB25">
                <div class="cartSidebarRow sidebarDate">
                  <LocalizationProvider adapterLocale={language} dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                      disablePast={true}
                      minDate={getMinDate(context?.hotelData?.check_in_min_date)}
                      maxDate={MAX_DATE}
                      displayStaticWrapperAs="desktop"
                      dayOfWeekFormatter={days => {
                        return days.toUpperCase()
                      }}
                      label="date range"
                      value={value}
                      inputFormat="YYYY-MM-DD"
                      onChange={newValue => {
                        setValue(newValue)
                        let newFormat = []
                        let issamelastdate = false;
                        if (newValue[0] != null) {
                          issamelastdate = Moment(newValue[0].$d).isSame(MAX_DATE.$d);
                          if (issamelastdate === true) {
                            newFormat[0] = dayjs(Moment(newValue[0].$d).subtract(1, "days").format(
                              "yyyy-MM-DD"
                            ));
                          } else {
                            newFormat[0] = dayjs(Moment(newValue[0].$d).format(
                              "yyyy-MM-DD"
                            ));
                          }

                        }
                        if (newValue[1] != null) {
                          newFormat[1] = dayjs(Moment(newValue[1].$d).format(
                            "yyyy-MM-DD"
                          ));
                        } else if (newValue[1] === null) {
                          newFormat[1] = dayjs(Moment(newValue[0].$d)
                            .add(1, "days")
                            .format("yyyy-MM-DD"));

                        }

                        if (issamelastdate === true) {
                          newFormat[1] = dayjs(Moment(newValue[0].$d).format("yyyy-MM-DD"));
                        }
                        setValue(newFormat)
                        setContinueText(true)
                        onChangeDate(newFormat)
                      }}
                      allowSameDateSelection={false}
                      renderDay={(day, selectedDates, pickersDayProps) => (
                        <PickersDay {...pickersDayProps} />
                      )}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} />
                          <Box sx={{ mx: 2 }}> to </Box>
                          <TextField {...endProps} />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div class="cartSidebarBottom">
              <div class="cartSidebarBtn">
                <button
                  class="customBtn customBtnDark w100"
                  onClick={() => openGuests()}
                >
                  {continueText ? t("continue") : t("roomnguest")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateRangeBooking;
