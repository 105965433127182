import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { route } from "../utils/route";
import RoomDetailsComponent from "../components/room-details";

const RoomDetail = ({
  hotelList,
  currencyList,
  authToken,
  searchPanelData,
  siteConfig,
  ip,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.id) {
      navigate(route.roomList);
    }
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <RoomDetailsComponent
      hotelList={hotelList}
      currencyList={currencyList}
      authToken={authToken}
      searchPanelData={searchPanelData}
      siteConfig={siteConfig}
      ip={ip}
      room_id={location?.state?.id}
    />
  );
};

export default RoomDetail;
