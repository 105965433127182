import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const FourZeroFourComponent = ({ hotelEmail, hotelPhone, callToAction }) => {
  const { t } = useTranslation();

  return (
    <main className='mainContent declinePage textCenter'>
      <div className='columnContainer'>
        <div className='declineMessageWrap dFlex alignItemsCenter flexWrap justifyContentCenter'>
          <div className='declineMessage PageTitleWrap3'>
            <span className='dBlock textUppercase'>{t("error_404")}</span>
            <h1 className='m0 paddingT20 paddingB30'>
              {parse(t("unable_to_locate_page"))}
            </h1>
            <a
              className='customBtn customBtnDark addToStayBtn'
              href='javascript:void(0);'
              onClick={callToAction}
            >
              {t("return_home")}
            </a>
          </div>
        </div>
        <div className='pageContactInfo paddingT20 paddingB40'>
          {t("if_problem_persists")} {t("email_us")}
          <a href={`mailto:${hotelEmail}`}>{hotelEmail}</a> {t("call_us")}
          <a href={`tel:${hotelPhone}`}>{hotelPhone}</a>
        </div>
      </div>
    </main>
  );
};

export default FourZeroFourComponent;
