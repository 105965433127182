import { useTranslation } from "react-i18next";
import { currencyDisplay } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { route } from "../../utils/route";
import {useContext, useMemo} from "react";
import AppContext from "../../utils/appContext";
import {SITE_ID_LIST_HASH} from "../../utils/utils";

export default function TaxesFeesBreakdown({
	setViewTaxesFeesBreakdown,
	viewTaxesFeesBreakdown,
	currencySign,
	totalRoomAmount,
	totalTaxAmount,
	tax_obj,
}) {
	const { t } = useTranslation();
	const location = useLocation();
	const context = useContext(AppContext);

	const TaxList = (tax_obj) => {
		const taxElements = [];
		let totalTaxAmount = 0; // Initialize the total tax amount

		for (const key in tax_obj) {
			if (tax_obj.hasOwnProperty(key)) {
				const element = tax_obj[key];
				const title = element.title;
				const taxAmount = location.pathname !== route.checkout ? element.tax_amount_user_currency : element.tax_amount;
				const taxInclusive = element.tax_inclusive;
				totalTaxAmount += taxAmount; // Add the tax amount to the total

				taxElements.push(
					<li
						className='dFlex justifyContentSpBtw'
						key={key}
						data-tax-inclusive={taxInclusive}
					>
						<span>{title}</span>
						<div>{currencySign + currencyDisplay(taxAmount)}</div>
					</li>
				);
			}
		}
		return <div>{taxElements}</div>;
	};

	return (
		<div
			className={`column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw ${
				viewTaxesFeesBreakdown
					? "viewTaxesBreakdown"
					: "hideTaxesBreakdown"
			}`}
		>
			{viewTaxesFeesBreakdown && (
				<div className='visibleClass column100 cartPriceBreak'>
					<p
						className='m0'
						onClick={() =>
							setViewTaxesFeesBreakdown(!viewTaxesFeesBreakdown)
						}
					>
						{t("taxes_fees_breakdown")}:
					</p>
					<ul className='wrapperTaxesFeesBreakdown optionEnhance'>
						{TaxList(tax_obj)}
					</ul>
				</div>
			)}
			<div class="productName">
				{t("total_taxes_fees")}:
				{/* className='column100'
				style={{ cursor: "pointer" }}
				onClick={() =>
					setViewTaxesFeesBreakdown(!viewTaxesFeesBreakdown)
				} */}
			</div>
			<div class="cartTotalPrice"
				onClick={() =>
					setViewTaxesFeesBreakdown(!viewTaxesFeesBreakdown)
				}
			>
				{/* {viewTaxesFeesBreakdown ? t("hide") : t("view")}{" "} */}
				{currencySign + currencyDisplay(totalTaxAmount)}
			</div>
			{SITE_ID_LIST_HASH.mbh?.includes(context.configData?.site_id?.toString())&&<div className="marginT10">* {t("remove_info")}</div>}
		</div>
	);
}
