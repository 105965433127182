/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import AppContext from "../../utils/appContext";
import BookingDetails from "./bookingDetails";
import { useTranslation } from "react-i18next";
import WithHeader from "../common/LayoutWrapper/Header/withHeader";
import MobileLogo from "../common/LayoutWrapper/Header/mobileLogo";

export default function StayDetails({
	authToken,
	siteConfig,
	currencySymbol,
	hotelCheckInTime,
	hotelCheckOutTime,
	crsConfirmationNo,
	confirmationData,
}) {
	const context = useContext(AppContext);
	const { t } = useTranslation();

	function bodyToggleClass(){
		var bodyElement = document.body;
		bodyElement.classList.remove('bodyHiddenMobile');
	}

	return (
		<aside
			className={`column30 cartSidebarWrap pR0 stickySidebar ${
				context.yourStay ? "cartToggle" : ""
			}`}
			id='mainCart'
		>
			<div className='mobileCartLogo textCenter'>
        <MobileLogo siteId={siteConfig?.site_id} />
			</div>
			<div
				className='viewStayBtn textUppercase posRelative'
				onClick={() => { 
					context.setYourStay(false);
					bodyToggleClass();
				}}
			>
				{t("view_your_stay")}
			</div>

			<div className='cartSidebar'>
				<BookingDetails
					authToken={authToken}
					siteConfig={siteConfig}
					currencySymbol={currencySymbol}
					hotelCheckInTime={hotelCheckInTime}
					hotelCheckOutTime={hotelCheckOutTime}
					crsConfirmationNo={crsConfirmationNo}
					confirmationData={confirmationData}
				/>
			</div>
		</aside>
	);
}
