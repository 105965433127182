import React, { useContext, useState } from "react";
import { DEFAULT_LANG, KEYS } from "../../../utils/constant";
import { LocalStorage, SessionStorage } from "../../../utils/storage";
import AppContext from "../../../utils/appContext";
import { useTranslation } from "react-i18next"
import { changeTabindexToMinusOne, trapTabFocusWithinSelection } from "../../../utils/utils";
import { useLocation } from "react-router-dom";



const useAddtoCart = props => {
  const { t } = useTranslation()
	const context = useContext(AppContext)
	const location = useLocation();
  let addtocarttext = t("add_to_stay");

  let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
	let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
	
  if(currentRoom !== ''){

    let occKey = "";
    cartItems.length > 0 &&
      cartItems.map((item, index) => {
        if (currentRoom == index + 1) {
          occKey = item.adults + "-" + item.childrens.length + "i0";
          
          if( item.room_info.rate_id === props.rate_id && props.room_id === item.room_info.room_id){
            
            addtocarttext = t("your_room");
          }
           
        }
      });
	}
	

  const addtoCart = () => {
		var bodyElement = document.body;
		bodyElement.classList.add('bodyHiddenMobile');
		context.setOverlay(true);
		let occKey = "";
		cartItems.length > 0 &&
			cartItems.map((item, index) => {
				if (currentRoom == index + 1) {
					occKey = item.adults + "-" + item.childrens.length + "i0";
					item.room_info.rate_id = props.rate_id;
					item.room_info.rate_name = props.rate_name;
					item.room_info.room_id = props.room_id;
					item.room_info.room_name = props.room_name;
					item.room_info.tax_status = props.tax_status;
					item.room_info.rate_obj = props.rate_obj;
					item.room_info.tax_obj = props.tax_obj;
					item.price = props.price[occKey];
					cartItems[index].room_info = item.room_info;
				}
			});
		SessionStorage.setJSON("roomData", cartItems);
		SessionStorage.set("isTaxInclusive", props.is_tax_inclusive); // Set 'isTaxInclusive' in Session Storage
		context.setCartData(cartItems);
		if (props.section === "room_detail") {
			props.setIsCartShown((current) => !current); // Open cart section once room added through 'Add to Stay' button
	  }
		addtocarttext = t("your_room");
		context.setYourStay(true);
		document.getElementById("cartEditDate").focus();
    	trapTabFocusWithinSelection();
    	const roomListDivElement = document.getElementById("roomList");
	  	const footerDivElement = document.getElementById("mainFooter");
	  	let currentPath = location.pathname;

		changeTabindexToMinusOne(roomListDivElement);
		changeTabindexToMinusOne(footerDivElement);
  };


  return (
		<a
			tabIndex='0'
			className={`customBtn addToStayBtn ${
				addtocarttext === t("your_room")
					? "customBtnTransparent"
					: "customBtnDark"
			}`}
			href='javascript:void(0);'
			onClick={() => addtoCart()}
		>
			{addtocarttext}
		</a>
  );
};

export default useAddtoCart;
