import { apiList } from "../utils/apiUrl";
import { fetchUrl } from "../utils/fetchUrl";

export const fetchPolicy = (data, authToken) => {
  return new Promise((resolve, reject) => {
    fetchUrl(apiList.getPolicy.method, apiList.getPolicy.url, data, authToken).then((res) => {
      resolve(res);
    }).catch((err) => reject(err));
  })
}
