import { useState, useEffect, useContext } from "react";
import { DEFAULT_CURRENCY, KEYS } from "../utils/constant";
import { LocalStorage } from "../utils/storage";
import { fetchRoomListData } from "../services/roomList";
import { getRoomListRequest } from "../utils/requestData";
import AppContext from "../utils/appContext";
import { useErrorBoundary } from "react-error-boundary";
import { useAuth } from "./useAuth";
import { getThirdPartyPolicy } from "../utils/utils";
export const useRoomDetailsHook = ({ authToken, searchPanelData, room_id }) => {
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [roomDetails, setRoomDetails] = useState(null);
	const [hotelData, setHotelData] = useState(null);
	const { showBoundary } = useErrorBoundary();
    const { getAuth } = useAuth()
	let retryAttempted = false
	const getRoomDetails = async () => {
		let requestData = getRoomListRequest({
			currencyData: context.currencyData,
		});
		if (room_id) {
			requestData.room_id = room_id;
			requestData.source_page = "room_details";
		}		
		requestData.third_party_policy = getThirdPartyPolicy(); // append third party policy data in request data

		try {
			await setLoading(true);
			await fetchRoomListData(requestData, context.authToken).then((res) => {
				const roomList = res?.data?.rooms || [];
				const sortedArray = roomList?.filter((obj) => obj.min_price > 0)?.sort((a, b) => a.min_price - b.min_price);
				const priceSortedArray = sortedArray?.map(x => {
					return {
						...x,
						price: x?.price?.sort((a, b) => a.min_price - b.min_price)
					}
				})
				setRoomDetails(sortedArray?.length ? priceSortedArray[0] : roomList[0]);
				setHotelData(res.data.hotel);
				context.setRoomData(roomList);
				context.setHotelData(res.data.hotel);
				LocalStorage.set(
					KEYS.SELECTED_CURRENCY,
					context?.currencyData || DEFAULT_CURRENCY
				); // Update current currency data
			});
		} catch (error) {
			// Show error boundary
			if (error.renewToken && !retryAttempted) {
				getAuth().then(() => {
					retryAttempted = true;
				})
			} else {
				showBoundary(error);		
			}
		} finally {
			setLoading(false);
		}
	};

	// send API call, if 'currency' OR 'language' OR 'authToken' (from context data) OR 'searchPanelData' is changed
	useEffect(() => {
		if (
			context?.currencyData &&
			context?.languageData &&
			context?.authToken &&
			searchPanelData
		) {
			getRoomDetails();
		}
	}, [
		context.currencyData,
		context.languageData,
		context.authToken,
		searchPanelData,
	]);

	// useEffect(() => {
	//   if (context.authToken && searchPanelData) {
	//     getRoomDetails();
	//   }
	// }, [context.authToken, searchPanelData]);

	return {
		loading,
		setLoading,
		roomDetails,
		hotelData,
	};
};
