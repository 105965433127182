import React, { useState, useEffect, useContext } from "react";
import { getPolicyRequest } from "../utils/requestData";
import AppContext from "../utils/appContext";
import { fetchPolicy } from "../services/policy";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { useAuth } from "./useAuth";
import { getPolicyCodeData } from "../utils/utils";

const usePolicy = ({ roomId, rateId, openPolicy, roomRateTaxInfo, policyCodes }) => {
	const { t } = useTranslation();
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [policyData, setPolicyData] = useState();
	const [viewPolicyModal, setViewPolicyModal] = useState(false);
	const { showBoundary } = useErrorBoundary();
	const { getAuth } = useAuth();
	let retryAttempted = false;

	const getPolicy = async () => {
		const token = context.authToken;
		const requestData = getPolicyRequest();
		requestData.room_id = roomId;
		requestData.rate_id = rateId;

		// append third_party_policy params
		let config = localStorage.getItem("config")
			? JSON.parse(localStorage.getItem("config"))
			: [];
		let thirdPartyPolicy = config.third_party_policy
			? config.third_party_policy
			: [];
		let roomRates = context.roomData[0]?.price;

		if (
			context?.hotelData?.channel_id === "RVNG" &&
			context?.hotelData?.roomlisting_price_check === "API" &&
			config.third_party_policy === 1
		) {
			console.log('policyCode -> ' + JSON.stringify(policyCodes));
			requestData.third_party_policy = thirdPartyPolicy;
			requestData.roomlisting_price_check = context?.hotelData?.roomlisting_price_check;
			requestData.policy_codes = policyCodes; // getPolicyCodeData(rateId, roomRates);
		}		
		// append third_party_policy params

		try {
			await setLoading(true);
			await fetchPolicy(requestData, context.authToken).then(
				({ data }) => {
					let policyMsg = "";
					if (roomRateTaxInfo) {
						policyMsg += `<h3>${t(
							"taxes_and_fees"
						)}</h3><p>${roomRateTaxInfo}</p>`;
					}
					if (data.samemodifycancel) {
						policyMsg += `<h3>${t("amendment_policy")}</h3><p>${
							data.cancellation.summary
						}</p>`;
					} else {
						policyMsg += `<h3>${t("modification_policy")}</h3><p>${
							data.amendment.summary
						}</p>`;
						policyMsg += `<h3>${t("cancellation_policy")}</h3><p>${
							data.cancellation.summary
						}</p>`;
					}
					if (data.payment.summary) {
						policyMsg += `<h3>${t("payment_policy")}</h3><p>${
							data.payment.summary
						}</p>`;
					}
					setPolicyData(policyMsg);
					setViewPolicyModal(true);
					// console.log("data -> " + JSON.stringify(data));
				}
			);
		} catch (error) {
			if (error.renewToken && !retryAttempted) {
				getAuth().then(() => {
					retryAttempted = true;
				});
			} else {
				showBoundary(error);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (context.authToken && openPolicy) {
			getPolicy();
		}
	}, [context.authToken, openPolicy]);

	return {
		policyData,
		loading,
		viewPolicyModal,
		setPolicyData,
		setViewPolicyModal,
	};
};

export default usePolicy;
