import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { capitalizeStr, currencyDisplay } from "../../../utils/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PrintLogo from "./PrintLogo";
import ConfirmPrintPopupRoomBasedStayDetails from "./ConfirmPrintPopupRoomBasedStayDetails";

const ComponentToPrint = React.forwardRef((props, ref) => {
	//  ref -  https://codesandbox.io/s/tailwind-react-sandbox-forked-q1tdg?fontsize=14&hidenavigation=1&module=/src/App.js&theme=dark&file=/components/resume.js:1872-1875

	// Change Day & Month Sting dynamically for moment lib
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	moment.locale(language);

	let dateFormat = language === "fr" ? "ddd DD MMM YYYY" : "ddd, DD MMM YYYY";

	const { t } = useTranslation();
	let arrivalDate = props?.confirmationData?.date_in
		? moment(props?.confirmationData.date_in).format(`${dateFormat}`)
		: "-";
	let departureDate = props?.confirmationData?.date_out
		? moment(props?.confirmationData.date_out).format(`${dateFormat}`)
		: "- ";
	let stayTotalNights =
		moment(departureDate).diff(moment(arrivalDate), "days") || "-";
	let totalNightsWithStr =
		stayTotalNights <= 1
			? stayTotalNights + " " + capitalizeStr(t("night"))
			: stayTotalNights + " " + t("nights");
	let overallTotalCost = props?.confirmationData?.total_cost
		? props?.currencySymbol +
		  currencyDisplay(props?.confirmationData?.total_cost)
		: "-";

	let hotelName = props?.confirmationData?.hotel_details?.hotel_name
		? props?.confirmationData?.hotel_details?.hotel_name
		: "";

	return (
		<div style={{ display: "none" }}>
			<div
				className='printWrapper'
				style={{ background: "#F6F6F6" }}
				ref={ref}
			>
				<div className='bookingConfirmationDetails'>
					<section className='bookingInfoColumn'>
						<ul>
							<li key='logo'>
								<div className='columnWrap'>
									<div className='column100'>
										<span className='dBlock'>
											{props?.confirmationData
												?.site_id && (
												<PrintLogo
													siteId={
														props?.confirmationData
															?.site_id
													}
													hotelName={hotelName}
												/>
											)}
										</span>
									</div>
								</div>
							</li>
							<li className='onlyMessageTop PageTitleWrap3 textCenter'>
								<h2 className='m0 paddingT10'>
									{t("your_reservation")}
								</h2>
							</li>
							<li key='reservation-details'>
								<div className='columnWrap'>
									<div className='column100'>
										<span className='dBlock textUppercase'>
											{t("stay_overview")}
										</span>
									</div>
								</div>
								<div className='bookingDetailInfoWrap'>
									{props?.confirmationData && (
										<>
											<div className='columnWrap'>
												<div className='column50'>
													<span>{t("name")}</span>
												</div>
												<div className='column50'>
													{props?.confirmationData
														?.customer_name || "-"}
												</div>
											</div>

											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("email_address")}
													</span>
												</div>
												<div className='column50'>
													{props?.confirmationData
														?.email || "-"}
												</div>
											</div>

											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("arrival_date")}
													</span>
												</div>
												<div className='column50'>
													{arrivalDate}
												</div>
											</div>

											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("departure_date")}
													</span>
												</div>
												<div className='column50'>
													{departureDate}
												</div>
											</div>

											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("total_nights")}
													</span>
												</div>
												<div className='column50'>
													{totalNightsWithStr}
												</div>
											</div>

											<div className='columnWrap'>
												<div className='column50'>
													<span>
														{t("overall_total")}
													</span>
												</div>
												<div className='column50'>
													{overallTotalCost}
												</div>
											</div>
										</>
									)}
								</div>
							</li>

							<ConfirmPrintPopupRoomBasedStayDetails
								room_stay={props?.confirmationData?.room_stay}
								currencySymbol={props?.currencySymbol}
								confirmationData={props?.confirmationData}
								hotelCheckInTime={props?.hotelCheckInTime}
								hotelCheckOutTime={props?.hotelCheckOutTime}
							/>

							<li className='bookingInfoTotal' key='total'>
								<div className='columnWrap'>
									<div className='column50'>
										<span className='dBlock textUppercase'>
											{t("booking_Total")}
										</span>
									</div>
									<div className='column50 booklastColumn50'>
										{getSymbolFromCurrency(
											props?.confirmationData
												?.hotel_details
												?.currency_abbreviation
										)}
										{currencyDisplay(
											props?.confirmationData?.total_cost
										)}
									</div>
								</div>
							</li>
						</ul>
					</section>
				</div>
			</div>
		</div>
	);
});

export default ComponentToPrint;
