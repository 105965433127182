import React, { useContext, useMemo, useState } from "react";
import AppContext from "../../../utils/appContext";
import { disableScroll, enableScroll } from "../../../utils/utils";

// import ButtonLoader from "../Loader/buttonLoader";

export const PrimaryButton = ({
  title = "Button",
  type = "button",
  onClick = () => false,
  disabled = false,
  loading = false,
  className = ""
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      className={className}
    >
      {/* {loading && <ButtonLoader />} */}
      {title}
    </button>
  );
};

export const IconGroup = ({
  defaultValue,
  type,
  minValue,
  maxValue,
  max_occupancy,
  roomNumber,
  addRooms,
  removeRooms,
  settempRoomData,
  tempRoomData,
  setchangedAdultChild,
  changedAdultChild,
  isDisabled = false
}) => {
  const context = useContext(AppContext);
  const [roomCount, setRoomCount] = useState(defaultValue);
  let adult = 0;
  let children = 0;

  const increaseCount = () => {
    if (parseInt(roomCount) >= maxValue) {
      return false;
    }
    if(tempRoomData?.[roomNumber-1]?.adults+tempRoomData?.[roomNumber-1]?.childrens>max_occupancy){
     return false
    }
    setRoomCount(parseInt(roomCount) + 1);
    if (type == "room") {
      context.setnumberOfRoom(parseInt(roomCount) + 1);
      addRooms();
    }
    if (type == "adult") {
      adult = parseInt(roomCount) + 1;
    }

    if (type == "children") {
      children = parseInt(roomCount) + 1;
    }
    let roomdata = tempRoomData;
    if (roomdata && roomdata.length > 0) {
      roomdata.map(function(element, index) {
        if (element.room == roomNumber) {
          if (adult != 0) {
            roomdata[index].adults = adult;
          }else if(roomdata[index].adults == 0){
            roomdata[index].adults = 1;
          }
          if (type == "children") {
            roomdata[index].childrens = children;
          }
        }
      });

      let isObjectPresent = roomdata.find((o) => o.room === roomNumber);
      if(isObjectPresent === undefined){
        if(adult == 0){
          adult = 1;
        }
        let roomObj = {
          "adults": adult,
          "childrens": children,
          "room": roomNumber
        }
        roomdata.push(roomObj);
        
      }
      settempRoomData(roomdata);
      setchangedAdultChild(changedAdultChild+1)
    }
    enableScroll()
  };

  const decreaseCount = () => {
    if (parseInt(roomCount) <= minValue) {
      return false;
    }
    setRoomCount(parseInt(roomCount) - 1);
    if (type == "room") {
      context.setnumberOfRoom(parseInt(roomCount) - 1);
      removeRooms();
    }
    if (type == "adult") {
      adult = parseInt(roomCount) - 1;
      if(adult < 1){
        adult = 1;
      }
    }

    if (type == "children") {
      children = parseInt(roomCount) - 1;
    }
    let roomdata = tempRoomData;
    if (roomdata && roomdata.length > 0) {
      roomdata.map(function(element, index) {
        if (element.room == roomNumber) {
          if (adult != 0) {
            roomdata[index].adults = adult;
          }
          if (type == "children") {
            roomdata[index].childrens = children;
          }
        }
      });
      let isObjectPresent = roomdata.find((o) => o.room === roomNumber);
      if(isObjectPresent === undefined){
        let roomObj = {
          "adults": adult,
          "childrens": children,
          "room": roomNumber
        }
      
        roomdata.push(roomObj);
        
      }
     
      settempRoomData(roomdata);
      setchangedAdultChild(changedAdultChild+1)
    }
    enableScroll()
  };

  return (
    <div class="roomQuantity qtyBoxSizeSmall textRight">
      <span
        className={`qtyDecrease minusLargeIcon dInlineBlock posRelative ${(Number(minValue) === Number(roomCount) || isDisabled) ? "minusPlusDisabled" : ""}`}
        onClick={() => isDisabled ? false : decreaseCount()}
        onKeyUp={(event) => {
          disableScroll()
          if (event.keyCode !== 40 && event.keyCode !== 13 || isDisabled) {
            return;
          }
          decreaseCount();
        }}
        role={(Number(minValue) === Number(roomCount) || isDisabled) ? "" : "button"}
        tabIndex={(Number(minValue) === Number(roomCount) || isDisabled) ? "-1" : "0"}
        aria-label={`Remove ${type === "adult" ? "an" : "a"} ${type === "children" ? "Child" : type }`}
      ></span>
      <input
        name={type + roomNumber}
        id={type + roomNumber}
        className="qtyValue dInlineBlock"
        type="text"
        value={roomCount}
        size="5"
        readonly="readonly"
        tabIndex="0"
      />
      <span
        className={`qtyIncrease plusLargeIcon dInlineBlock posRelative ${((Number(maxValue) === Number(roomCount)) || isDisabled ||tempRoomData?.[roomNumber-1]?.adults+tempRoomData?.[roomNumber-1]?.childrens>max_occupancy) ? "minusPlusDisabled" : ""}`}
        onClick={() => isDisabled ? false : increaseCount()}
        onKeyDown={(event) => {
          disableScroll()
          if (event.keyCode !== 38 && event.keyCode !== 13 || isDisabled) {
            return;
          }
          increaseCount();
        }}
        role={((Number(maxValue) === Number(roomCount)) || isDisabled) ? "" : "button"}
        tabIndex={((Number(maxValue) === Number(roomCount)) || isDisabled) ? "-1" : "0"}
        aria-label={`Add ${type === "adult" ? "an" : "a"} ${type === "children" ? "Child" : type }`}
      ></span>
    </div>
  );
};

export const BigAdultChild = ({
  defaultValue,
  type,
  minValue,
  maxValue,
  maxOccupancy,
  roomNumber,
  setcount,
  occupancyCount
}) => {
  const [bigroomCount, setbigRoomCount] = React.useState(defaultValue);

  const increaseCount = () => {
    if (parseInt(bigroomCount) >= maxValue) {
      return false;
    }

    if(occupancyCount>maxOccupancy) return false
    setbigRoomCount(parseInt(bigroomCount) + 1);
    setcount(parseInt(bigroomCount) + 1);
  };
  const decreaseCount = () => {
    if (parseInt(bigroomCount) <= minValue) {
      return false;
    }
    setbigRoomCount(parseInt(bigroomCount) - 1);
    setcount(parseInt(bigroomCount) - 1);
  };

  return (
    <div className="roomQuantity textCenter">
      <span
        className={`qtyDecrease minusLargeIcon dInlineBlock posRelative ${(Number(minValue) === Number(bigroomCount)) ? "minusPlusDisabled" : ""}`}
        onClick={() => decreaseCount()}
        onKeyUp={(event) => {
          if (event.keyCode !== 40 && event.keyCode !== 13) {
            return;
          }
          decreaseCount();
        }}
        role={(Number(minValue) === Number(bigroomCount)) ? "" : "button"}
        tabIndex={(Number(minValue) === Number(bigroomCount)) ? "-1" : "0"}
        aria-label= {`Remove ${type === "adult" ? "an" : "a"} ${type === "children" ? "Child" : type }`}
      ></span>
      <input
        name={(type + roomNumber) || "counter"}
        id={(type + roomNumber) || "counter"}
        className="qtyValue dInlineBlock"
        type="text"
        value={bigroomCount}
        size="5"
        readonly="readonly"
        tabIndex="0"
      />
      <span
        className={`qtyIncrease plusLargeIcon dInlineBlock posRelative ${(Number(maxValue) === Number(bigroomCount)||occupancyCount>maxOccupancy) ? "minusPlusDisabled" : ""}`}
        onClick={() => increaseCount()}
        onKeyDown={(event) => {
          if (event.keyCode !== 38 && event.keyCode !== 13) {
            return;
          }
          increaseCount();
        }}
        role={(Number(maxValue) === Number(bigroomCount)) ? "" : "button"}
        tabIndex={(Number(maxValue) === Number(bigroomCount)) ? "-1" : "0"}
        aria-label={`Add ${type === "adult" ? "an" : "a"} ${type === "children" ? "Child" : type }`}
      ></span>
    </div>
  );
};
