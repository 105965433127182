import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useContext, useRef as useRef1, useRef as useRef2 } from 'react';
import { useCheckout } from '../../hooks/useCheckout'
import Cart from '../cart'
import BillingDetails from './billingDetails'
import ContactDetails from './contactDetails'
import PaymentDetails from './paymentDetails'
import { useReservation } from '../../hooks/useReservation'
import { useForm } from "react-hook-form"
import { useRoomListHook } from "../../hooks/useRoomListHook"
import { useTranslation } from "react-i18next"
import moment from "moment"
import * as yup from "yup"
import { useManageBooking } from "../../hooks/useManageBooking"
import { useEffect } from "react"
import LockIcon from "../../../mbh/styles/images/lock-icon.png";
import Loader from "../common/Loader"
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import AppContext from "../../utils/appContext";
import { useNavigate, useLocation } from "react-router-dom";
import { route } from "../../utils/route";
import { isInView } from "../../utils/utils"

const { object, string, array, ref, boolean } = yup

const defaultValues = {
  prefix: undefined,
  firstName: undefined,
  lastName: undefined,
  phoneCode: undefined,
  phoneNumber: undefined,
  email: undefined,
  confirmEmail: undefined,
  notStayRoom: false,
  optIn: false,
  iatano: undefined,
  guest: [{
    firstName: undefined,
    lastName: undefined,
    arrivalTime: undefined,
    stayInRoom: false,
    additional: undefined,
    addonData: []
  }],
  country: undefined,
  city: undefined,
  zipCode: undefined,
  addressLine1: undefined,
  addressLine2: undefined,
  nameOnCard: undefined,
  cardNumber: undefined,
  cardType: undefined,
  expiryDate: undefined,
  cvv: undefined,
  termsCheckbox: false,
  policyCheckbox: false
}

const Checkout = ({
  searchPanelData,
  siteConfig,
  authToken
}) => {
  const { t } = useTranslation()

  const checkoutSchema = object({
    prefix: string().required(t("title_required")),
    firstName: string().required(t("first_name_required")),
    lastName: string().required(t("last_name_required")),
    phoneNumber: string().required(t("phone_number_required")).min(5, t("invalid_phone")).max(15, t("invalid_phone")),
    phoneCode: string().required(t("country_code_required")),
    email: string().required(t("email_required"))
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        t("invalid_email")
      ),
    confirmEmail: string().required(t("confirm_email_required"))
      .oneOf([ref("email"), null], t("match_email"))
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        t("confirm_email_invalid")
      ),
    guest: array().of(
      object().shape({
        prefix: string().required(t("title_required")),
        firstName: string().required(t("first_name_required")),
        lastName: string().required(t("last_name_required")),
        arrivalTime: string().matches(
          /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$|^$/,
          t('valid_estimated_arrival')
        ),
      })
    ).min(1, t("guest_details_required")).required(t("guest_details_required")),
    country: string().required(t("country_required")),
    city: string().required(t("city_required")),
    zipCode: string().required(t("zip_code_required")),
    addressLine1: string().required(t("address_1_required")),
    nameOnCard: string().required(t("card_name_required")),
    cardNumber: string().min(13, t("invalid_card")).max(19, t("invalid_card")).required(t("card_number_required")),
    cardType: string().required(t("card_type_required")),
    expiryDate: string()
      .required(t("expiry_date_required"))
      .matches(
        /^(0[1-9]|1[0-2])(\/\d{0,2})?$/,
        t("invalid_expiry_date")
      )
      .test("expiry-date", t("valid_expiry_date"), function (
        value
      ) {
        if (!value) return false
        const [month, year] = value.split("/");
        const formattedMonth = month.padStart(2, "0");
        const currentDate = moment().startOf("month");
        const expiryDate = moment(`${formattedMonth}/01/20${year}`, "MM/DD/YYYY", true);
        return expiryDate.isSameOrAfter(currentDate)
      }),
    cvv: string().min(3, t("invalid_cvv")).max(4, t("invalid_cvv")).required(t("cvv_required")),
    termsCheckbox: boolean().oneOf([true], t("terms_checkbox_required")),
    policyCheckbox: boolean().oneOf([true], t("policy_checkbox_required"))
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    control,
    trigger,
    resetField,
    formState: { errors = {} },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(checkoutSchema),
  })

  const { loading, loading2, checkoutData, addonData } = useCheckout({
    searchPanelData
  })

  const { loading: loading3, getRoomList, hotelData } = useRoomListHook({
    authToken,
    loader: false
  })

  const { loading: loading5, bookingData } = useManageBooking({})

  const { doBooking, loading4 } = useReservation({
    bookingData,
    checkoutData,
    siteConfig,
    authToken,
  });

  let context = useContext(AppContext);
  let ref1 = useRef1(null)
  let ref2 = useRef2(null)
  const navigate = useNavigate();

  if (checkoutData !== null && checkoutData.hotel.noavailability === 1) {
    navigate(route.roomList);
    context.setcheckoutnoavailabilityerror(1)
  }

  const [activeTab, setActiveTab] = useState(0)
  // const [activeTabGuest, setActiveTabGuest] = useState()

  useEffect(() => {
    if (bookingData) {
      setValue("prefix", bookingData?.title)
      setValue("firstName", bookingData?.first_name)
      setValue("lastName", bookingData?.last_name)
      setValue("phoneNumber", bookingData?.phone)
      setValue("email", bookingData?.email)
      setValue("confirmEmail", bookingData?.email)
      if (bookingData?.guest_details?.[0]?.iamstay === 1) {
        setValue("notStayRoom", false)
        setValue("guest.0.stayInRoom", true)
      }
      setValue("guest.0.prefix", bookingData?.guest_details?.[0]?.title)
      setValue("guest.0.firstName", bookingData?.guest_details?.[0]?.firstName)
      setValue("guest.0.lastName", bookingData?.guest_details?.[0]?.lastName)
      setValue("addressLine1", bookingData?.address)
      setValue("addressLine2", bookingData?.address2)
      setValue("city", bookingData?.city)
      setValue("zipCode", bookingData?.zip)
    }
  }, [bookingData])

  const handleTabClick = async (index) => {
    var footer = document.getElementById("mainFooter");
    var mainCart = document.getElementById("mainCart");
    var mainContDiv = document.getElementsByClassName("mainContent");
    mainContDiv = mainContDiv[0];
    if (mainCart) {
      var visible = isInView(footer);
      if (visible) {
        mainCart.classList.add("stickySidebar");
        mainContDiv.classList.remove("stickySidebarFooter");
      }
    }
    let requiredContactFields = ["prefix", "firstName", "lastName", "phoneCode", "phoneNumber", "email", "confirmEmail", "guest"]
    let requiredBillingFields = ["country", "city", "zipCode", "addressLine1"]
    if (index !== 0 && (requiredContactFields.some(x => !watch(x)) || requiredContactFields.some(field => {
      if (field === 'guest') {
        const guestArray = watch(field)
        return !guestArray || !guestArray.length || guestArray.some(guest => !guest.firstName || !guest.lastName || !guest.prefix)
      }
      return errors[field]?.message
    }))) {
      const errorMessages = {
        prefix: t("title_required"),
        firstName: t("first_name_required"),
        lastName: t("last_name_required"),
        phoneCode: t("country_code_required"),
        phoneNumber: t("phone_number_required"),
        email: t("email_required"),
        confirmEmail: t("confirm_email_required")
      }
      Object.entries(errorMessages).forEach(([field, message]) => {
        if (!watch(field)) {
          setError(field, { type: "custom", message })
        }
      })
      watch("guest")?.forEach((guest, guestIndex) => {
        if (!guest.prefix) {
          setError(`guest[${guestIndex}].prefix`, { type: "custom", message: t("title_required") })
        }
        if (!guest.firstName) {
          setError(`guest[${guestIndex}].firstName`, { type: "custom", message: t("first_name_required") })
        }
        if (!guest.lastName) {
          setError(`guest[${guestIndex}].lastName`, { type: "custom", message: t("last_name_required") })
        }
        // if (!guest.arrivalTime) {
        //   setError(`guest[${guestIndex}].arrivalTime`, { type: "custom", message: t("estimated_time_required") })
        // }
      })
      if (index === 2 && requiredBillingFields.some(x => !watch(x))) {
        const errorMessages2 = {
          country: t("country_required"),
          city: t("city_required"),
          zipCode: t("zip_code_required"),
          addressLine1: t("address_1_required"),
        }
        Object.entries(errorMessages2).forEach(([field, message]) => {
          if (!watch(field)) {
            setError(field, { type: "custom", message })
          }
        })
      }
    } else if (index === 2 && requiredBillingFields.some(x => !watch(x))) {
      const errorMessages = {
        country: t("country_required"),
        city: t("city_required"),
        zipCode: t("zip_code_required"),
        addressLine1: t("address_1_required"),
      }
      Object.entries(errorMessages).forEach(([field, message]) => {
        if (!watch(field)) {
          setError(field, { type: "custom", message })
        }
      })
    } else if (index !== activeTab) {
      setActiveTab((prevIndex) => (prevIndex === index ? -1 : index))
    }
  }

  const stopClose = (e) => {
    e.stopPropagation()
  }

  // const handleTabClickGuest = (index, e) => {
  //   stopClose(e)
  //   const requiredFields = ["firstName", "lastName", "phoneNumber", "email", "confirmEmail"]
  //   if (requiredFields.some(field => !watch(field))) {
  //     const errorMessages = {
  //       firstName: t("first_name_required"),
  //       lastName: t("last_name_required"),
  //       phoneNumber: t("phone_number_required"),
  //       email: t("email_required"),
  //       confirmEmail: t("confirm_email_required")
  //     }
  //     Object.entries(errorMessages).forEach(([field, message]) => {
  //       if (!watch(field)) {
  //         setError(field, { type: "custom", message })
  //       }
  //     })
  //   } else {
  //     setActiveTabGuest((prevIndex) => (prevIndex === index ? -1 : index))
  //   }
  // }

  useEffect(() => {
    if (checkoutData !== null && siteConfig.google_tag_head_code != 0 && siteConfig.google_tag_head_code != '') {
      let checkindate = sessionStorage.getItem("checkInDate")
      let checkoutdate = sessionStorage.getItem("checkoutDate")
      let checkin = moment(checkindate, "YYYY-MM-DD");
      let checkout = moment(checkoutdate, "YYYY-MM-DD");
      let todydate = moment(new Date(), "YYYY-MM-DD");
      let totalNights = checkout.diff(checkin, "days");
      let totalbookingwindow = checkin.diff(todydate, "days");
      let itemsArray = [];
      let itemIndex = 0;
      let totalPrice = 0;
      let totalGuest = 0;
      checkoutData.rooms?.map(item => {
        totalGuest = totalGuest + parseInt(item.adult) + parseInt(item.child)
        totalPrice = totalPrice + item.room_rate
        let roomItem = {
          "item_id": item.roomcode,
          "item_name": item.room_type_name,
          "item_ratecode": item.ratecode,
          "affiliation": checkoutData.hotel.hotel_name,
          "coupon": SessionStorage.get(KEYS.BOOKING_CODE) || "",
          "currency": checkoutData.hotel.abbrevation,
          "index": itemIndex,
          "item_brand": siteConfig.site_title,
          "item_numberofadults": item.adult,
          "item_numberofchildren": item.child,
          "item_variant": item.rate_name,
          "price": item.room_rate,
          "quantity": item.room_count
        }
        itemsArray.push(roomItem);
        itemIndex++;
      });

      window.dataLayer = window.dataLayer || [];
      //TagManager.dataLayer({ ecommerce: null });
      const tagManagerArgs = {
        "event": "begin_checkout",
        "ecommerce": {
          "coupon": SessionStorage.get(KEYS.BOOKING_CODE) || "",
          "affiliation": checkoutData.hotel.hotel_name,
          "currency": checkoutData.hotel.abbrevation,
          "value": totalPrice,
          "item_check_in": checkindate,
          "item_check_out": checkoutdate,
          "item_hotel_ID": checkoutData.hotel.hotel_id,
          "item_numberofguests": totalGuest,
          "language": LocalStorage.get(KEYS.I18_NEXT_LNG),
          "item_totalnumberofrooms": itemIndex,
          "item_bookingwindow": totalbookingwindow,
          "items": itemsArray
        },
      };

      //TagManager.dataLayer(tagManagerArgs);
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(tagManagerArgs);
    }
  }, [checkoutData])

  useEffect(() => {
    const marginTop = 150;
    if (activeTab === 1) {
      const elementTop = ref1.current.getBoundingClientRect().top;
      window.scrollBy({
        top: elementTop - marginTop,
        behavior: "smooth",
      });
    }
    if (activeTab === 2) {
      const elementTop = ref2.current.getBoundingClientRect().top;
      window.scrollBy({
        top: elementTop - marginTop,
        behavior: "smooth",
      });
    }
  }, [activeTab])

  return (
    <>
      {(loading || loading2 || loading4 || loading5) && <Loader />}
      <main className="mainContent checkoutPage">
        <div className="columnContainerFull">
          <div className="columnWrap">
            <div className="column70 paddingTB50">
              <h1 className="pageTitle m0 paddingB30 checkoutPageTitle">{t("your_stay")}</h1>
              <div className="checkoutTabsWrap">
                <ContactDetails
                  activeTab={activeTab}
                  // activeTabGuest={activeTabGuest}
                  errors={errors}
                  control={control}
                  handleTabClick={handleTabClick}
                  stopClose={stopClose}
                  // handleTabClickGuest={handleTabClickGuest}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                  clearErrors={clearErrors}
                  trigger={trigger}
                  resetField={resetField}
                  addonData={addonData}
                  bookingData={bookingData}
                  checkoutData={checkoutData}
                />
                <BillingDetails
                  activeTab={activeTab}
                  errors={errors}
                  watch={watch}
                  register={register}
                  handleTabClick={handleTabClick}
                  stopClose={stopClose}
                  setError={setError}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  bookingData={bookingData}
                  ref1={ref1}
                />
                <PaymentDetails
                  activeTab={activeTab}
                  errors={errors}
                  handleTabClick={handleTabClick}
                  stopClose={stopClose}
                  watch={watch}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setError={setError}
                  checkoutData={checkoutData}
                  siteConfig={siteConfig}
                  ref2={ref2}
                />
              </div>

              <div className='cartSidebarBtn cartCheckoutBtnMobile'>
                <button
                  className='customBtn customBtnDark w100'
                  onClick={handleSubmit(doBooking)}
                >
                  {t("complete_booking")}{" "}
                  <img
                    className='vAlignTextBaseline'
                    src={LockIcon}
                    alt='Lock Icon'
                    width='14'
                    style={{ marginBottom: "-1px" }}
                  />
                </button>
              </div>

            </div>
            <Cart
              onSubmitCheckout={handleSubmit(doBooking)}
              hotelData={hotelData}
              checkoutData={checkoutData}
              loading={loading3}
              getRoomList={getRoomList}
              siteConfig={siteConfig}
            />
          </div>
        </div>
      </main>
    </>
  )
}

export default Checkout