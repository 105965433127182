import React from "react";
import { DEFAULT_LANG, KEYS } from "../../../../utils/constant";
import { LocalStorage, SessionStorage } from "../../../../utils/storage";
import arrowLeftIcon from "../../../../styles/images/arrow-left.png";
import { useLocation, Link,
  Routes,
  Route,
  useNavigate } from "react-router-dom";
import { route } from "../../../../utils/route";
import { useTranslation } from "react-i18next"

const BookingSteps = () => {
  const location = useLocation();
  const { t } = useTranslation()
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
  let roomNo = 0;
  const navigate = useNavigate();
  
  return (
    <div className="column70 bookingSteps textCenter posRelative">

      <div className="bookingStepBack posAbsolute" onClick={() => navigate(-1)}>
        <img src={arrowLeftIcon} alt="Back Step" width="16" height="13" />
      </div>
      <div className="bookingStepNumber posAbsolute">
        {location.pathname === route.checkout ? cartItems.length+1 : location.pathname === route.checkout ? cartItems.length+2 : currentRoom} / {cartItems.length+2}
      </div>

      <ul className="dFlex justifyContentCenter">
        {cartItems.length > 0 &&
          cartItems.map((item, index) => {
            roomNo = index + 1;
            return (
              <li key={index} className={`${(currentRoom >= roomNo || location.pathname == route.checkout || location.pathname == route.confirmation) ? "activeStep" : ""}${(currentRoom == roomNo && location.pathname !== route.checkout && location.pathname !== route.confirmation) ? " currentActiveStep" : ""}`}>
                <span className="dBlock mLRAuto">{roomNo}</span>
                  {t("room")} {roomNo}
              </li>
            );
          })}
        <li className={`${(location.pathname === route.checkout || location.pathname === route.confirmation) ? "activeStep" : ""}${(location.pathname === route.checkout) ? " currentActiveStep" : ""}`} >
        <span className="dBlock mLRAuto">{roomNo + 1}</span>{t("personal_details")}
        </li>
        <li className={`${(location.pathname === route.confirmation) ? "activeStep" : ""}${(location.pathname === route.confirmation) ? " currentActiveStep" : ""}`}>
          <span className="dBlock mLRAuto">{roomNo + 2}</span>{t("confirmation")}
        </li>
      </ul>
    </div>
  );
};

export default BookingSteps;
